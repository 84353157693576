interface MyButtonProperties {
  label: string;
  disableHoverEffect?: boolean;
  buttonType: 'submit' | 'back' | 'error' | 'primary-outline' | 'danger-outline';
  svgIcon?: JSX.Element;
  svgBackIcon?: JSX.Element;
  onClickFunc: () => void;
  disabled?: boolean;
  loading?: boolean;
}

/**
 * My Button function component
 */
function MyButton({
  label,
  buttonType,
  svgIcon,
  onClickFunc,
  disableHoverEffect,
  svgBackIcon,
  disabled,
  loading,
}: MyButtonProperties) {
  return (
    <div className="button-wrap">
      <button
        type="button"
        className={`${buttonType}-button ${disableHoverEffect ? '' : 'on-hover'}`}
        onClick={onClickFunc}
        disabled={disabled}>
        {svgIcon && <span className="icon">{svgIcon}</span>}
        {label && <span>{label}</span>}
        {svgBackIcon && <span className="icon">{svgBackIcon}</span>}
      </button>
      {loading && (
        <div className="button-loader-wrap">
          <div className="button-loader-container">
            <div className="button-loader" />
          </div>
        </div>
      )}
    </div>
  );
}

MyButton.defaultProps = {
  svgIcon: null,
  svgBackIcon: null,
  disabled: false,
  loading: false,
  disableHoverEffect: false,
};

export default MyButton;
