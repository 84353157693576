import { useState } from 'react';

import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg';

type InputType = 'text' | 'number' | 'email' | 'password';

interface CustomInputProperties {
  inputLabel?: string;
  type: InputType;
  placeholder: string;
  value: string | number;
  name: string;
  onChangeValue?: (name: string, value: string | number) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
  svgIcon?: JSX.Element;
  frontSvgIcon?: JSX.Element;
  svgIconClickFunc?: () => void;
  error?: boolean;
  errorMessage?: string;
}

/**
 * Custom Input box function component
 */
function CustomInput({
  inputLabel,
  type,
  name,
  placeholder,
  value,
  onChangeValue = () => {},
  valueRequired,
  readOnly,
  svgIcon,
  frontSvgIcon,
  svgIconClickFunc,
  error,
  errorMessage,
}: CustomInputProperties) {
  const [inputType, setInputType] = useState<InputType>(type);
  /**
   * Input box change handle function
   */
  const handleValueChange = (changeValue: string | number) => {
    onChangeValue(name, changeValue);
  };

  return (
    <div className="custom-input-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className={`custom-input-container ${error && 'error'}`}>
        {frontSvgIcon && (
          <span>
            {frontSvgIcon}
          </span>
        )}
        <input
          type={type === 'password' ? inputType : type}
          placeholder={placeholder}
          value={value}
          onChange={(event) => handleValueChange(event.target.value)}
          required={valueRequired}
          readOnly={readOnly}
        />
        {svgIcon && type !== 'password' && (
          <span role="presentation" onClick={svgIconClickFunc}>
            {svgIcon}
          </span>
        )}
        {(() => {
          let passwordIcon: JSX.Element | null = null; // Initialize the type as either a JSX element or null
          if (type === 'password' && inputType === 'password') {
            passwordIcon = (
              <span role="presentation" onClick={() => setInputType('text')}>
                <EyeIcon />
              </span>
            );
          } else if (type === 'password' && inputType === 'text') {
            passwordIcon = (
              <span role="presentation" onClick={() => setInputType('password')}>
                <EyeIcon />
              </span>
            );
          }
          return passwordIcon; // Return the icon or null
        })()}
      </div>
      {error && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
}

CustomInput.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
  svgIcon: null,
  frontSvgIcon: null,
  error: false,
  errorMessage: '',
  /**
   * Icon click function
   */
  svgIconClickFunc: () => {},
  /**
   * Input change function
   */
  onChangeValue: () => {},
};

export default CustomInput;
