import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ReactComponent as ActiveManagementIcon } from '../../assets/icons/active-management.svg';
import { ReactComponent as ActiveSharingIcon } from '../../assets/icons/active-sharing.svg';
import { ReactComponent as ManagementIcon } from '../../assets/icons/management.svg';
import { ReactComponent as SharingIcon } from '../../assets/icons/sharing.svg';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchAccountList,
  fetchAllStations,
} from '../../redux/slices/RevenueManagementStationSlice';
import AutoCompleteSelectField from '../../shared-components/auto-complete-select-field/AutoCompleteSelectField';
import CustomToggleButton from '../../shared-components/custom-toggle-button/CustomToggleButton';
import PageTitle from '../../shared-components/page-title/PageTitle';

import ManagementAccountRevenue from './components/ManageAccountRevenue';
import ManagementStationRevenue from './components/ManageStationRevenue';
import SharingList from './components/sharing-list';

/**
 * User Management page function component
 */
function RevenueManagement() {
  const { page } = useParams();
  const dispatch = useAppDispatch();
  const [openTab, setOpenTab] = useState(page === 'sharing' ? 0 : 1);
  const [currentPage, setCurrentPage] = useState<string>('account');
  const [selectedAccountId, setSelectedAccountId] = useState<string | number>('');

  const {
    accountList,
    page: revenueStationPage,
    limit,
  } = useAppSelector((state) => state.revenueStation);

  /**
   * Change the page function
   * @param data
   */
  const changePageHandle = (data: number) => {
    setOpenTab(data);
    window.history.pushState({}, '', `${data === 0 ? 'sharing' : 'management'}`);
  };

  /**
   * Function to change account Id filter
   * @param name
   * @param onChangeValue
   */
  const handleChangeAccountId = (name: string, onChangeValue: string | number) => {
    setSelectedAccountId(onChangeValue);
    dispatch(
      fetchAllStations({
        page: revenueStationPage,
        limit,
        accountCustomId: onChangeValue.toString(),
      }),
    );
  };

  useEffect(() => {
    if (accountList.length === 0 && currentPage === 'station') {
      dispatch(fetchAccountList());
    }
  }, [currentPage]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="container revenue-body">
        <PageTitle title="Revenue" />
        <div className="revenue-toggle-wrap">
          <CustomToggleButton
            labels={['Sharing', 'Management']}
            onToggle={changePageHandle}
            defaultActiveIndex={openTab}
            icons={[<SharingIcon />, <ManagementIcon />]}
            activeIcons={[<ActiveSharingIcon />, <ActiveManagementIcon />]}
          />
          {openTab === 1 && currentPage === 'station' && (
            <AutoCompleteSelectField
              name=""
              inputLabel=""
              selectedValue={selectedAccountId}
              placeholder="Account ID"
              onChangeValue={handleChangeAccountId}
              options={accountList}
            />
          )}
        </div>
        <div className="table-content">
          {openTab === 0 && <SharingList />}
          {openTab === 1 && (
            <div className="table-content">
              {currentPage === 'account' && (
                <ManagementAccountRevenue
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              )}
              {currentPage === 'station' && (
                <ManagementStationRevenue
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  selectedAccountId={selectedAccountId}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RevenueManagement;
