import React, { useRef } from 'react';

// Declare type for the props
type InputProperties = {
  length?: number;
  OTP: string[];
  setOTP: React.Dispatch<React.SetStateAction<string[]>>;
  type: string;
  inputLabel: string;
  error?: boolean;
};

/**
 * Custom OTP input component
 */
function CustomOtpInput({ length = 6, OTP, setOTP, type, inputLabel, error }: InputProperties) {
  const inputReference = useRef<HTMLInputElement[]>(Array(length).fill(null));

  /**
   * Handle OTP change
   * @param input - The value entered in the input
   * @param index - Index of the input field
   */
  const handleTextChange = (input: string, index: number) => {
    if (input.length > 1) return; // Prevent more than one character

    const newPin = [...OTP];
    newPin[index] = input;
    setOTP(newPin);

    if (input.length === 1 && index < length - 1) {
      inputReference.current[index + 1]?.focus();
    }
  };

  /**
   * Handle key events like Backspace
   * @param event - The keyboard event
   * @param index - Index of the input field
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (event.key === 'Backspace' && OTP[index] === '' && index > 0) {
      inputReference.current[index - 1]?.focus();
    }
  };

  /**
   * Handle pasting an OTP
   * @param event - The paste event
   */
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData.getData('Text');
    if (!clipboardData) return;

    const validOtp = clipboardData.slice(0, length).split('');
    const newPin = [...OTP];
    validOtp.forEach((char, index) => {
      if (index < length) {
        newPin[index] = char;
      }
    });

    setOTP(newPin);

    // Focus the last filled input
    const lastFilledIndex = validOtp.length - 1 < length ? validOtp.length : length - 1;
    inputReference.current[lastFilledIndex]?.focus();
  };

  return (
    <div className="custom-otp-wrap">
      {inputLabel && <p className="input-label">{inputLabel}</p>}
      <div className="custom-otp-container">
        {Array.from({ length }, (_, index) => (
          <input
            key={index}
            type={type}
            maxLength={1}
            value={OTP[index]}
            onChange={(event) => handleTextChange(event.target.value, index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            onPaste={handlePaste}
            ref={(reference) => {
              inputReference.current[index] = reference as HTMLInputElement;
              return null;
            }}
            className={`otp-digit-container ${error && 'error'}`}
          />
        ))}
      </div>
    </div>
  );
}

CustomOtpInput.defaultProps = {
  length: 0,
  error: false,
};

export default CustomOtpInput;
