import React, { Dispatch, SetStateAction } from 'react';

interface ThemeState {
  theme: string;
  setTheme: Dispatch<SetStateAction<string>>;
}

export const initialThemeState: ThemeState = {
  theme: localStorage.getItem('globalTheme') || 'light',
  /**
   * Theme set function
   */
  setTheme: () => null,
};

const ThemeContext = React.createContext<ThemeState>(initialThemeState);
export default ThemeContext;
