/**
 * Function used to convert UTC string to 12 hour time format
 * @param time24 - UTC Date string
 * @returns
 */
function FormatUtcString(time24: string) {
  // Destructure hours and minutes directly from time24
  /* eslint-disable react/destructuring-assignment */
  const [hours24, minutes] = time24.split(':').map(Number);

  // Determine the period (AM/PM)
  const period = hours24 >= 12 ? 'PM' : 'AM';

  // Convert hours to 12-hour format
  const hours12 = hours24 % 12 || 12;

  // Return the formatted time
  return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
}

export default FormatUtcString;
