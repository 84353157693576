import React from 'react';

interface CustomRadioButtonProperties {
  selected: boolean;
  label: string;
  value: string;
  name: string;
  onChangeFunc: (name: string, value: string) => void;
}

/**
 * Custom radio button component
 */
function CustomRadioButton(properties: CustomRadioButtonProperties) {
  const { selected, label, value, name, onChangeFunc } = properties;
  return (
    <div
      className="custom-radio-button-wrap"
      role="presentation"
      onClick={() => onChangeFunc(name, value)}>
      <div className={`radio-button-status ${selected && 'active'}`}>
        {selected && <div className="radio-button-selected" />}
      </div>
      <p className="radio-button-label">{label}</p>
    </div>
  );
}

export default CustomRadioButton;
