import axios, { AxiosResponse } from 'axios';

import defaultHeaders from './default-headers';

/**
 * Patch request function
 */
const patchRequest = async (
  url: string,
  data: any,
  isFileUpload = false,
): Promise<AxiosResponse<any>> => {
  try {
    const customHeaders: Record<string, string> = isFileUpload
    ? { 'Content-Type': 'multipart/form-data' }
    : {};
    
    const authToken = defaultHeaders();
    const headers = {
      ...(authToken && authToken), // Add Authorization header if token exists
      ...customHeaders,
    };

    return await axios.patch(url, data, { headers });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default patchRequest;
