import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CloseCircle } from '../../assets/icons/close-circle.svg';
import MyButton from '../button/Button';

/**
 * Confirm Box function component
 */
function ConfirmBox({
  title,
  subTitle,
  closeModal,
  open,
  conformSubmit = () => {},
  submitButton,
}: {
  title: String;
  subTitle: String;
  closeModal: () => void;
  conformSubmit?: () => void;
  open: boolean;
  submitButton?: JSX.Element;
}) {
  const reference = useRef<HTMLDivElement>(null);
  /**
   * Click the outside handle function
   */
  const handleClickOutside = (event: React.MouseEvent) => {
    if (reference.current === event.target) {
      closeModal();
    }
  };
  return open
    ? ReactDOM.createPortal(
        <div
          className={`modal-box-wrap ${open && 'open'}`}
          onClick={handleClickOutside}
          role="presentation"
          ref={reference}>
          <div className="modal-box-container">
            <div className="modal-box-body">
              <div className="modal-box-icon" onClick={closeModal} role="presentation">
                <CloseCircle />
              </div>
              <div className="conform-box-body">
                <div className="title">{title}</div>
                <div className="sub-title">{subTitle}</div>
              </div>
              <div className="form-field table-bottom-button-list form-bottom-button">
                <div className="table-button-item">
                  <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
                </div>
                <div className="table-button-item">
                  {submitButton || (
                    <MyButton label="Ok" buttonType="submit" onClickFunc={() => conformSubmit()} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>,
        document.body,
      )
    : null;
}

ConfirmBox.defaultProps = {
  submitButton: null,
  /**
   * Conform submit function
   */
  conformSubmit: () => {},
};

export default ConfirmBox;
