import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ChargingPoint } from '../../assets/icons/dashboard/charging-point.svg';
import { ReactComponent as MapArrowIcon } from '../../assets/icons/dashboard/map-arrow-icon.svg';
import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { brandIcons } from '../../config/branding';
import { useAppDispatch } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomToggleButton from '../../shared-components/custom-toggle-button/CustomToggleButton';
import dashboardApi from '../../utils/api/dashboard';

import DashboardMap from './components/DashboardMap';
import ReportGraphChart from './components/report-graph';

interface GraphDataInterface {
  label: string;
  data: (string | number)[];
  borderColor: string;
  bgColor: string;
  currency: string;
}
interface CardDetailsInterface {
  title: string;
  dataToShow: string | number;
  unit: string;
  activeDevices: string;
  icon: JSX.Element;
  onClickFunc: (url: string) => void;
  url: string;
  lastMonthDifference: number;
}

/**
 * Dashboard function Component
 */
function Dashboard() {
  const dispatch = useAppDispatch();
  const printReference = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const [graphPage, setGraphPage] = useState<number>(0);
  const [exportButton, setExportButton] = useState<boolean>(true);
  const [graphNames, setGraphNames] = useState<string[]>([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]);

  const [graphData, setGraphData] = useState<GraphDataInterface[]>([
    {
      label: 'Electricity',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: '#0399e0',
      bgColor: 'rgba(104, 192, 228, 1)',
      currency: 'KWh',
    },
    {
      label: 'Money',
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      borderColor: '#F79009',
      bgColor: 'rgba(297 189, 110, 1)',
      currency: 'SAR',
    },
  ]);
  /**
   * Function  to handle navigation
   * @param url
   */
  const handleIconClick = (url: string) => {
    navigate(url);
  };

  const [mapCardData, setMapCardData] = useState<CardDetailsInterface[]>([
    {
      title: 'Total Electricity Consumed',
      dataToShow: '0',
      unit: '(KWh)',
      activeDevices: '0 Active Devices',
      onClickFunc: handleIconClick,
      icon: <brandIcons.dashboardElectricity />,
      url: '../reports',
      lastMonthDifference: 0,
    },
    {
      title: 'Total Revenue',
      dataToShow: '0',
      unit: '(SAR)',
      activeDevices: '0 Active Stations',
      onClickFunc: handleIconClick,
      icon: <brandIcons.dashboardRevenue />,
      url: '../revenue/sharing',
      lastMonthDifference: 0,
    },
    {
      title: 'Total Users',
      dataToShow: '0',
      unit: '',
      activeDevices: '0 Active Sessions',
      onClickFunc: handleIconClick,
      icon: <brandIcons.dashboardMultiUser />,
      url: '../user-management/user',
      lastMonthDifference: 0,
    },
  ]);

  /**
   * Function to fetch data
   */
  const fetchData = (stationId: string) => {
    dashboardApi
      .fetchDashboardCardDetails(stationId)
      .then((response) => {
        setMapCardData([
          {
            title: 'Total Electricity Consumed',
            dataToShow: (response.data.electricity.total / 1000).toFixed(2),
            unit: '(KWh)',
            activeDevices: `${response?.data?.electricity?.activeDevices}  Active Devices`,
            onClickFunc: handleIconClick,
            icon: <brandIcons.dashboardElectricity />,
            url: '../reports',
            lastMonthDifference: response?.data?.electricity?.lastMonthDifference,
          },
          {
            title: 'Total Revenue',
            dataToShow: response?.data?.revenue?.total.toFixed(2),
            unit: `(${response?.data?.revenue?.currency.toUpperCase()})`,
            activeDevices: `${response?.data?.revenue?.activeStation}  Active Stations`,
            onClickFunc: handleIconClick,
            icon: <brandIcons.dashboardRevenue />,
            url: '../revenue/sharing',
            lastMonthDifference: response?.data?.revenue?.lastMonthDifference,
          },
          {
            title: 'Total Users',
            dataToShow: response?.data?.users?.total,
            unit: '',
            activeDevices: `${response?.data?.users?.activeUsers}  Active Sessions`,
            onClickFunc: handleIconClick,
            icon: <brandIcons.dashboardMultiUser />,
            url: '../user-management/user',
            lastMonthDifference: response?.data?.users?.lastMonthDifference,
          },
        ]);
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * set Missing dates
   * @param data
   * @param type
   */
  const setMissingValue = (data: any) => {
    setGraphNames(data.map((value: { month: string }) => value.month));
    setGraphData([
      {
        label: 'Electricity',
        data: data.map((value: { totalMeterDifference: string }) =>
          (parseInt(value.totalMeterDifference, 10) / 1000).toFixed(2),
        ),
        borderColor: '#0399e0',
        bgColor: 'rgba(104, 192, 228, 1)',
        currency: 'KWh',
      },
      {
        label: 'Money',
        data: data.map((value: { totalRevenue: string }) =>
          parseFloat(value.totalRevenue).toFixed(2),
        ),
        borderColor: '#F79009',
        bgColor: 'rgba(297 189, 110, 1)',
        currency: data[0].currency.toUpperCase(),
      },
    ]);
  };

  /**
   * Handle the fetch revenue and power consumed details
   * @param type
   */
  const fetchRevenueAndPowerDetails = (stationId: string = '', type: string = 'year') => {
    dashboardApi
      .getRevenueAndPowerConsumedDetails(type, stationId)
      .then((response) => {
        setMissingValue(response.data);
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Export the PDF file
   */
  const generatePdf = () => {
    setExportButton(false);
    if (printReference.current) {
      const example = printReference.current;
      setTimeout(async () => {
        const pdf = new JsPDF('l', 'mm', 'a4'); // A4 size: 210mm x 297mm
        const canvas = await html2canvas(example, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');

        const imgWidth = 270; // Width of A4 in mm
        const pageHeight = 257; // Height of A4 in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 15;

        // Add the first page
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Add additional pages if content overflows
        while (heightLeft > 0) {
          position = heightLeft - imgHeight; // Adjust the position
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save('paginated.pdf');
        setExportButton(true);
      }, 1000);
    }
  };

  /**
   * Handle the page toggle button
   * @param number
   */
  const changeGraphType = (number: number, stationId: string = '') => {
    let type = 'year';
    setGraphPage(number);
    switch (number) {
      case 0:
        type = 'year';
        break;
      case 1:
        type = 'month';
        break;
      case 2:
        type = 'week';
        break;
      case 3:
        type = 'day';
        break;

      default:
        break;
    }
    fetchRevenueAndPowerDetails(stationId, type);
  };

  /**
   * Fetch the all details for select station
   * @param stationId
   */
  const fetchAllDetails = (stationId: string) => {
    fetchData(stationId);
    changeGraphType(graphPage, stationId);
  };

  useEffect(() => {
    fetchData('');
    changeGraphType(graphPage);
  }, []);

  return (
    <div className="dashboard-wrap">
      <div className="container">
        <div className="header-title">
          <h2>Dashboard</h2>
        </div>
        <DashboardMap fetchAllDetails={fetchAllDetails} />
        <div className="cards-wrap">
          {mapCardData?.map((cardData, index) => (
            <div
              className="each-card-container"
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)}>
              <h3>{cardData.title}</h3>
              <h1>
                {cardData.dataToShow} <span>{cardData.unit}</span>
              </h1>
              <div className="device-count-container">
                <div className="footer-card">
                  <span>
                    <ChargingPoint />
                  </span>
                  <p>
                    <span>{cardData.activeDevices}</span>
                  </p>
                </div>
                <div className="footer-card">
                  {cardData.lastMonthDifference >= 0 ? (
                    <span className="tag-success-label">{cardData.lastMonthDifference}%</span>
                  ) : (
                    <span className="tag-danger-label">{cardData.lastMonthDifference * -1}%</span>
                  )}
                  <span>Than last month</span>
                </div>
              </div>
              <div className="card-arrow-wrap">
                <div className={`card-arrow-container ${hoverIndex === index && 'hovered'}`}>
                  <span className="in-active-icon">{cardData.icon}</span>
                  <span
                    className="active-icon"
                    onClick={() => navigate(cardData.url)}
                    role="presentation">
                    <MapArrowIcon />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="reports-graph-content" ref={printReference}>
          <div className="reports-graph-header-container">
            {exportButton && <div className="reports-title">Application Status</div>}
            <div className="reports-graph-header">
              <div className="reports-toggle-btn">
                {exportButton && (
                  <CustomToggleButton
                    labels={['12 Months', 'Last Month', '7 Days', '24 hours']}
                    onToggle={changeGraphType}
                    defaultActiveIndex={0}
                  />
                )}
              </div>
              <div className="reports-export-btn">
                <div className="chart-label-wrap">
                  <div className="each-chart-label">
                    <div className="each-label-color" />
                    <p>Money</p>
                  </div>
                  <div className="each-chart-label">
                    <div className="each-label-color" />
                    <p>Electricity</p>
                  </div>
                </div>
                {exportButton && (
                  <MyButton
                    label="Export Data"
                    buttonType="back"
                    disableHoverEffect
                    onClickFunc={generatePdf}
                    svgIcon={<Export />}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="reports-graph">
            <ReportGraphChart labels={graphNames} data={graphData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
