/**
 * Set cookie function
 * @param name
 * @param value
 * @param days
 * @param expiresUTC
 */
export const setCookie = (
  name: string,
  value: any,
  days: number | null,
  expiresUTC: string | null,
) => {
  const encodedValue = encodeURIComponent(value); // Encode value to handle special characters

  if (expiresUTC) {
    const expires = `expires=${new Date(expiresUTC).toUTCString()}`; // Convert UTC string to correct format
    document.cookie = `${name}=${encodedValue};${expires};path=/`;
  } else if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000); // Convert days to milliseconds
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${encodedValue};${expires};path=/`;
  } else {
    document.cookie = `${name}=${encodedValue};path=/`;
  }
};

/**
 * Get cookie data function
 * @param name
 * @returns
 */
export const getCookie = (name: string): string | null => {
  const decodedCookies = decodeURIComponent(document.cookie);
  const cookies = decodedCookies.split('; '); // Split cookies by semicolon and space
  const cookie = cookies.find((c) => c.startsWith(`${name}=`));

  if (cookie) {
    return cookie.split('=')[1]; // Return the cookie's value
  }

  return null; // Return null if cookie is not found
};

/**
 * Remove a cookie
 * @param name - The name of the cookie to remove
 */
export const removeCookie = (name: string): void => {
  // Set the cookie's expiration date to a past date
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};