import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as RemoveIcon } from '../../../assets/icons/remove-icon.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg';

interface SelectedFileProperties {
  url: string;
  signedUrl: string;
  fileName: string;
  fileType: string;
  originalIndex: number;
  type: 'newFile' | 'existingFile';
}

interface GalleryViewProperties {
  selectedFiles: SelectedFileProperties[] | null;
  handleRemoveSelectedFile: (
    originalIndex: number,
    url: string,
    type: 'newFile' | 'existingFile',
  ) => void;
  acceptedFileType: string;
  handleSelectFile: (selectedFiles: File[]) => void;
  readOnly?: boolean;
}

/**
 * View the file based on its type
 * @param param0
 * @returns
 */
function FilePreview({ file, onRemove, readOnly = false }: { file: SelectedFileProperties; onRemove: () => void; readOnly?: boolean }) {
  let fileTypeView;

  if (file.fileType.includes('image')) {
    fileTypeView = <img src={file.signedUrl} alt="" className="selected-image" />;
  } else if (file.fileType.includes('video')) {
    fileTypeView = (
      <video controls className="selected-video">
        <source src={file.signedUrl} type={file.type} />
        <track src="path/to/captions.vtt" kind="captions" label="English captions" default />
        Your browser does not support the video tag.
      </video>
    );
  }

  return (
    <div className="single-selected-wrap">
      {fileTypeView}
      {!readOnly && (
        <div className="remove-icon" role="presentation" onClick={onRemove}>
          <RemoveIcon />
        </div>
      )}
    </div>
  );
}
FilePreview.defaultProps = {
  readOnly: false,
};

/**
 * Gallery view for all selected files
 */
function GalleryView(properties: GalleryViewProperties) {
  const { selectedFiles, handleRemoveSelectedFile, acceptedFileType, handleSelectFile, readOnly } =
    properties;

  const inputReference = useRef<HTMLInputElement | null>(null);
  const [partLength, setPartLength] = useState<number>(1);

  useEffect(() => {
    const length = selectedFiles?.length || 3;
    setPartLength(Math.ceil(length / 3)); // Use Math.ceil to handle uneven division
  }, [selectedFiles]);

  return (
    <div className="gallery-view-wrap">
      <div className="gallery-view-container">
        <div className="gallery-view-column">
          {selectedFiles?.slice(0, partLength)?.map((selectedFile) => (
            <FilePreview
              key={selectedFile.originalIndex} // Ensure a unique key
              file={selectedFile}
              readOnly={readOnly}
              onRemove={() =>
                handleRemoveSelectedFile(
                  selectedFile.originalIndex,
                  selectedFile.url,
                  selectedFile.type,
                )
              }
            />
          ))}
        </div>
        <div className="gallery-view-column">
          {selectedFiles?.slice(partLength, partLength * 2)?.map((selectedFile) => (
            <FilePreview
              key={selectedFile.originalIndex} // Ensure a unique key
              file={selectedFile}
              readOnly={readOnly}
              onRemove={() =>
                handleRemoveSelectedFile(
                  selectedFile.originalIndex,
                  selectedFile.url,
                  selectedFile.type,
                )
              }
            />
          ))}
        </div>
        <div className="gallery-view-column">
          {selectedFiles?.slice(partLength * 2)?.map((selectedFile) => (
            <FilePreview
              key={selectedFile.originalIndex} // Ensure a unique key
              file={selectedFile}
              readOnly={readOnly}
              onRemove={() =>
                handleRemoveSelectedFile(
                  selectedFile.originalIndex,
                  selectedFile.url,
                  selectedFile.type,
                )
              }
            />
          ))}
        </div>
      </div>
      {!readOnly && (
        <div className="upload-more-files-wrap">
          <div
            role="presentation"
            onClick={() => inputReference.current?.click()}
            className="upload-more-files">
            <span>
              <UploadIcon />
            </span>
            <span>Upload</span>
          </div>
        </div>
      )}

      <input
        ref={inputReference}
        type="file"
        accept={acceptedFileType}
        multiple
        style={{ display: 'none' } as React.CSSProperties}
        onChange={(event) => {
          const files = event?.target?.files;
          if (files) {
            handleSelectFile(Array.from(files));
          }
        }}
      />
    </div>
  );
}

export default GalleryView;
