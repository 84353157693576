/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  DynamicPricingInitialProperties,
  DynamicPricingProperties,
  FetchPricingProperties,
  RequestPricingInterface,
} from '../../interface/dynamic-pricing.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import dynamicPricing from '../../utils/api/dynamic-pricing';

// a thunk to fetch users
export const fetchPendingPricing = createAsyncThunk<
  FetchPricingProperties,
  RequestPricingInterface
>('pending-pricing/fetchPendingPricing', async (data: RequestPricingInterface, { dispatch }) => {
  try {
    const response = await dynamicPricing.fetchDynamicPricing(
      removeEmptyKeys({ ...data, status: 'pending' }),
    );
    return response.data;
  } catch (error: any) {
    dispatch({
      type: 'notification/setNotificationDetails',
      payload: {
        type: 'error',
        notificationDetails: {
          statusCode: error?.response?.data?.code || 200,
          message: error?.response?.data?.message || 'Something went wrong!',
        },
      },
    });
    throw error;
  }
});

// Initial state
const initialState: DynamicPricingInitialProperties = {
  pricing: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const pendingPricingSlice = createSlice({
  name: 'pending-pricing',
  initialState,
  reducers: {},
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchPendingPricing.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPendingPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.pricing = action.payload?.results?.length
          ? action.payload.results?.map((eachPricing: DynamicPricingProperties) => ({
              ...eachPricing,
              createdAt: eachPricing.createdAt
              ? ConvertUTCtoDate(eachPricing?.createdAt)
              : '',
            }))
          : [];
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchPendingPricing.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Export the reducer
export default pendingPricingSlice.reducer;
