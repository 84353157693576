import React, { useEffect, useState } from 'react';

import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import {
  FilterRevenueInterFace,
  RevenueSharingInterFace,
} from '../../../interface/revenue-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import {
  fetchRevenueSharingList,
  setRevenueSharingFilterData,
} from '../../../redux/slices/RevenueSharingSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import ConvertUTCtoDate from '../../../shared-functions/ConvertUTCtoDate';
import ConvertUTCtoHHMM from '../../../shared-functions/ConvertUTCtoHHMM';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import FormatUtcString from '../../../shared-functions/FormatUtcString';
import revenueSharingApi from '../../../utils/api/revenue';

import FilterRevenue from './filter-sharing';

/**
 * User Management page function component
 */
function SharingList() {
  const dispatch = useAppDispatch();
  const { revenueList, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.revenueSharing,
  );

  const [modalView, setModalView] = useState({
    status: false,
    title: 'Add Account',
    header: true,
    content: <div />,
    rightCorner: '',
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Fetch Users list function
   */
  const fetchRevenueSharing = (data: any) => {
    if (!loading) {
      dispatch(fetchRevenueSharingList(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: false,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    if (value.sortField) {
      dispatch(
        setRevenueSharingFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchRevenueSharing({ ...filter, ...value });
  };
  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: FilterRevenueInterFace) => {
    dispatch(setRevenueSharingFilterData(filterData));
    fetchRevenueSharing({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <FilterRevenue
          closeModal={closeModal}
          handleSubmitFilter={handleSubmitFilter}
          oldValue={filter}
        />
      ),
      rightCorner: '',
    });
  };

  /**
   * User filter clear function
   */
  const filterClearFunction = () => {
    dispatch(setRevenueSharingFilterData({}));
    fetchRevenueSharing({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Time',
      accessorKey: 'time' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Station Name',
      accessorKey: 'chargingStationName' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Account Name',
      accessorKey: 'accountName' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Transaction Id',
      accessorKey: 'transactionId' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Total Amount (USD)',
      accessorKey: 'debitAmount' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Total Power Consumed (Kwh)',
      accessorKey: 'powerConsumed' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    {
      label: 'Facility Owner',
      accessorKey: 'foRevenue' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
    // {
    //   label: 'Distributor',
    //   accessorKey: 'distributor' as keyof RevenueSharingInterFace,
    //   sortAllow: true,
    // },
    {
      label: 'EMSP',
      accessorKey: 'adminRevenue' as keyof RevenueSharingInterFace,
      sortAllow: true,
    },
  ];
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    revenueSharingApi
      .fetchRevenueSharingExport({ ...filter })
      .then((response) => {
        // Convert data to CSV format
        const headers = [
          'Date',
          'Time',
          'Station Name',
          'Account Name',
          'Transaction Id',
          'Total Amount (USD)',
          'Total Power Consumed (Kwh)',
          'Facility Owner',
          'EMSP',
        ];
        const rows = response.data.map((row: RevenueSharingInterFace) => [
          row.createdAt ? ConvertUTCtoDate(row.createdAt) : '',
          row.createdAt ? FormatUtcString(ConvertUTCtoHHMM(row.createdAt)) : '',
          row.chargingStationName,
          row.accountName,
          row.transactionId,
          row.debitAmount,
          row.powerConsumed,
          row.foRevenue,
          row.adminRevenue,
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'revenue-sharing-list.csv');
          } else {
            exportPDF(headers, rows, 'revenue-sharing-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (revenueList.length === 0) {
      fetchRevenueSharing({
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={revenueList}
        serialNo
        filter={filter}
        filterFunction={filterFunction}
        filterClearFunction={filterClearFunction}
        sizeChangeButtonRequired
        page={page}
        limit={limit}
        loading={loading}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={revenueList.length === 0}
                loader={exportLoader}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default SharingList;
