import React from 'react';

import { ReactComponent as BatteryIcon } from '../../assets/icons/battery-icon.svg';

/**
 * Shared loader component
 */
function BatteryLoader() {
  return (
    <div className="battery-loader-wrap">
      <div className="battery-loader-bg" />
      <div className="battery-wrap">
        <div className="battery-icon">
          <BatteryIcon />
        </div>
        <div className="electricity-bolt-black">
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fill="black"
              d="M21.9373 13.4985H18.3106V5.04776C18.3106 3.07593 17.2425 2.67687 15.9397 4.15574L15.0007 5.22382L7.05471 14.2614C5.96316 15.4938 6.4209 16.5032 8.0641 16.5032H11.6909V24.9539C11.6909 26.9257 12.7589 27.3248 14.0617 25.8459L15.0007 24.7778L22.9467 15.7402C24.0383 14.5079 23.5805 13.4985 21.9373 13.4985Z"
            />
          </svg>
        </div>
        <div className="electricity-bolt-green">
          <svg
            className="icon"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              className="icon-to-fill"
              d="M21.9373 13.4985H18.3106V5.04776C18.3106 3.07593 17.2425 2.67687 15.9397 4.15574L15.0007 5.22382L7.05471 14.2614C5.96316 15.4938 6.4209 16.5032 8.0641 16.5032H11.6909V24.9539C11.6909 26.9257 12.7589 27.3248 14.0617 25.8459L15.0007 24.7778L22.9467 15.7402C24.0383 14.5079 23.5805 13.4985 21.9373 13.4985Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default BatteryLoader;
