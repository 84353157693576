import React, { useEffect, useState } from 'react';

import { ReactComponent as CurrencyIcon } from '../../../assets/icons/currency-icon.svg';
import {
  DynamicPricingProperties,
  RequestPricingInterface,
} from '../../../interface/dynamic-pricing.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { fetchApprovedPricing } from '../../../redux/slices/ApprovedPricingSlice';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ConvertUTCtoDate from '../../../shared-functions/ConvertUTCtoDate';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import dynamicPricingApi from '../../../utils/api/dynamic-pricing';

/**
 * Approved dynamic pricing table
 */
function ApprovedPricing() {
  const dispatch = useAppDispatch();
  const { pricing, loading, page, limit, totalPages, totalResults } = useAppSelector(
    (state) => state.approvedPricing,
  );
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Handle the fetch the data in api function
   * @param data
   */
  const fetchPricing = (data: RequestPricingInterface) => {
    if (!loading) {
      dispatch(fetchApprovedPricing(data));
    }
  };

  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof DynamicPricingProperties,
      sortAllow: true,
    },
    {
      label: 'Station Name',
      accessorKey: 'name' as keyof DynamicPricingProperties,
      sortAllow: true,
    },
    {
      label: 'Electricity Price',
      accessorKey: 'electricityCostByUtilityProvider' as keyof DynamicPricingProperties,
      sortAllow: true,
    },
    {
      label: '',
      customLabel: (
        <div className="dynamic-pricing-custom-label">
          <span>
            <CurrencyIcon />
          </span>
          <span>Base Rate</span>
        </div>
      ),
      accessorKey: 'baseRate' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (rowData: DynamicPricingProperties) => <p>{rowData.base}/-</p>,
      sortAllow: true,
    },
    {
      label: '',
      customLabel: (
        <div className="dynamic-pricing-custom-label">
          <span>
            <CurrencyIcon />
          </span>
          <span>Peak Rate</span>
        </div>
      ),
      accessorKey: 'peakRate' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (rowData: DynamicPricingProperties) => <p>{rowData.peak}/-</p>,
      sortAllow: true,
    },
    {
      label: '',
      customLabel: (
        <div className="dynamic-pricing-custom-label">
          <span>
            <CurrencyIcon />
          </span>
          <span>Off Peak Rate</span>
        </div>
      ),
      accessorKey: 'offPeakRate' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (rowData: DynamicPricingProperties) => <p>{rowData.offpeak}/-</p>,
      sortAllow: true,
    },
  ];

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestPricingInterface) => {
    fetchPricing({ ...value });
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    dynamicPricingApi
      .fetchDynamicPricingExport({ status: 'approved' })
      .then((response) => {
        // Convert data to CSV format
        const headers = [
          'Date',
          'Station Name',
          'Electricity Price',
          'Peak Rate',
          'Peak Rate',
          'Off Peak Rate',
        ];
        const rows = response.data.map((row: DynamicPricingProperties) => [
          ConvertUTCtoDate(row.createdAt),
          row.name,
          row.electricityCostByUtilityProvider,
          row.base,
          row.peak,
          row.offpeak,
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'approved-dynamic-pricing-list.csv');
          } else {
            exportPDF(headers, rows, 'approved-dynamic-pricing-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (pricing.length === 0) {
      fetchPricing({ page, limit });
    }
  }, []);

  return (
    <div className="dynamic-pricing-table">
      <CustomTable
        header={column}
        data={pricing}
        serialNo
        filterAllow={false}
        filter={{}}
        filterClearFunction={() => {}}
        filterFunction={() => {}}
        limit={limit}
        page={page}
        loading={loading}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        sizeChangeButtonRequired
        height={window.innerHeight >= 700 ? 390 : 375}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={pricing.length === 0}
                loader={exportLoader}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default ApprovedPricing;
