import React, { useEffect, useState } from 'react';

import { ReactComponent as GasIcon } from '../../assets/icons/gas-icon.svg';
import { ReactComponent as Location } from '../../assets/icons/location.svg';
import {
  ReportFilterInterFace,
  ReportsInterFace,
  ReportDetailsInterFace,
  RequestReportInterFace,
} from '../../interface/report-management.interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import { fetchAllReports, setReportFilterData } from '../../redux/slices/ReportManagementSlice';
import CustomTable from '../../shared-components/custom-table/CustomTable';
import ExportButton from '../../shared-components/export-button/ExportButton';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import { exportCSV, exportPDF } from '../../shared-functions/ExportFile';
import reportApi from '../../utils/api/reports';

import ChartSection from './components/ChartSection';
import FilterAccount from './components/filter-report';

/**
 * Account Management function Component
 */
function ReportManagement() {
  const dispatch = useAppDispatch();
  const { reports, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.reports,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'transactionStartsAt' as keyof ReportsInterFace,
      sortAllow: true,
    },
    {
      label: 'Station Name',
      accessorKey: 'chargingStationName' as keyof ReportsInterFace,
      sortAllow: true,
    },
    // {
    //   label: 'Company Name',
    //   accessorKey: 'companyName' as keyof ReportsInterFace,
    //   sortAllow: true,
    // },
    {
      label: 'Location',
      accessorKey: 'chargingStationAddress' as keyof ReportsInterFace,
      sortAllow: true,
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: ReportsInterFace) =>
        currentRow.chargingStationAddress ? (
          <div className="tag-default-label">
            <Location /> <span>{currentRow.chargingStationAddress}</span>
          </div>
        ) : (
          <div />
        ),
    },
    {
      label: 'Power Consumer',
      accessorKey: 'totalPowerConsumed' as keyof ReportsInterFace,
      sortAllow: true,
    },
    {
      label: 'Revenue',
      accessorKey: 'totalRevenue' as keyof ReportsInterFace,
      sortAllow: true,
    },
  ];

  const [openStatus, setOpenStatus] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const [reportDetails, setReportDetails] = useState<ReportDetailsInterFace>({
    totalPowerConsumed: '0 Kwh',
    totalRevenue: '0 (SAR)',
    lastMonthPowerConsumed: 0,
    lastMonthRevenue: 0,
    powerData: [
      { day: 'Mon', value: 0 },
      { day: 'Tue', value: 0 },
      { day: 'Wed', value: 0 },
      { day: 'Thu', value: 0 },
      { day: 'Fri', value: 0 },
      { day: 'Sat', value: 0 },
      { day: 'Sun', value: 0 },
    ],
    revenueData: [
      { day: 'Mon', value: 0 },
      { day: 'Tue', value: 0 },
      { day: 'Wed', value: 0 },
      { day: 'Thu', value: 0 },
      { day: 'Fri', value: 0 },
      { day: 'Sat', value: 0 },
      { day: 'Sun', value: 0 },
    ],
    totalDevice: '0',
  });

  /**
   * Fetch the account details in redus
   */
  const fetchData = (data: RequestReportInterFace) => {
    if (!loading) {
      dispatch(fetchAllReports(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setOpenStatus(false);
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestReportInterFace) => {
    dispatch(setReportFilterData({ ...filter, ...value }));
    fetchData({ ...filter, ...value });
  };
  /**
   * Handle filter clear function
   */
  const handleSubmitFunction = (filterData: ReportFilterInterFace) => {
    dispatch(setReportFilterData({ ...filterData }));
    fetchData({
      ...filterData,
      limit,
      page,
    });
    setOpenStatus(false);
  };

  /**
   * Account filter view function
   */
  const reportFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: (
        <FilterAccount
          closeModal={closeModal}
          handleSubmitFunction={handleSubmitFunction}
          oldFilterValue={filter}
        />
      ),
      rightCorner: 'filter-modal',
    });
  };
  /**
   * Handle filter clear function
   */
  const handleClearFilterFunction = () => {
    dispatch(setReportFilterData({}));
    fetchData({
      limit,
      page,
    });
  };

  /**
   * Fetch report details
   */
  const fetchReportDetails = () => {
    reportApi
      .fetchReportDetails()
      .then((response) => {
        const {
          totalPower,
          totalDevice,
          totalRevenue,
          totalPowerConsumedThisWeek,
          totalRevenueThisWeek,
          lastMonthPowerConsumed,
          lastMonthRevenue,
        } = response.data;
        setReportDetails({
          totalPowerConsumed: `${(totalPower / 1000).toFixed(2)} Kwh`,
          totalRevenue: `${totalRevenue.toFixed(2) || 0} (SAR)`,
          lastMonthPowerConsumed,
          lastMonthRevenue,
          totalDevice,
          powerData: totalPowerConsumedThisWeek,
          revenueData: totalRevenueThisWeek,
        });
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    reportApi
      .exportReports({ ...filter })
      .then((response) => {
        // Convert data to CSV format
        const headers = ['Date', 'Station Name', 'Location', 'Power Consumer', 'Revenue'];
        const rows = response.data.map((row: ReportsInterFace) => [
          row.transactionStartsAt,
          row.chargingStationName,
          row.chargingStationAddress,
          (parseFloat(row.totalPowerConsumed) / 1000).toFixed(3),
          parseFloat(row.totalRevenue).toFixed(2),
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'reports-list.csv');
          } else {
            exportPDF(headers, rows, 'reports-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (reports.length === 0) {
      fetchData({
        ...filter,
        limit,
        page,
      });
    }

    fetchReportDetails();
  }, []);
  return (
    <div className="report-warp">
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <div className="container">
        <div className="header-title">
          <h2>Reports</h2>
        </div>
        <div className="body-container">
          <div className="charts-container">
            <ChartSection
              title="Total Power Consumed"
              subtitle="More from"
              submenuTitle="last month"
              value={reportDetails.totalPowerConsumed}
              percentage={reportDetails.lastMonthPowerConsumed}
              data={reportDetails.powerData}
            />
            <span className="dotted-line">
              <GasIcon /> {reportDetails.totalDevice} Device
            </span>
            <ChartSection
              title="Total Revenue"
              subtitle="More from"
              submenuTitle="last month"
              value={reportDetails.totalRevenue}
              percentage={reportDetails.lastMonthRevenue}
              data={reportDetails.revenueData}
            />
          </div>
          <div className="table-content">
            <CustomTable
              header={column}
              data={reports}
              limit={limit}
              page={page}
              totalPages={totalPages}
              totalResults={totalResults}
              loading={loading}
              serialNo
              pageLimitChange={pageNateLimitChange}
              height={319}
              filter={filter}
              filterFunction={reportFilterFunction}
              filterClearFunction={handleClearFilterFunction}
              sizeChangeButtonRequired
              footerLeft={
                <div className="table-bottom-button-list">
                  <div className="table-button-item">
                    <ExportButton
                      label="Export Data"
                      buttonList={[
                        {
                          key: 'pdf',
                          allow: true,
                          /**
                           * Export pdf function
                           * @returns
                           */
                          onClickFunction: () => exportDataPdfOrCSV('pdf'),
                        },
                        {
                          key: 'csv',
                          allow: true,
                          /**
                           * Export csv function
                           * @returns
                           */
                          onClickFunction: () => exportDataPdfOrCSV('csv'),
                        },
                      ]}
                      disabled={reports.length === 0}
                      loader={exportLoader}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportManagement;
