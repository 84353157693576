import currentTimeZone from '../enum/timeZone';

/**
 * Function to convert UTC time to the system's local time zone in 24hrs format
 * @param utcString - The UTC time string in ISO 8601 format
 * @returns Local time in "HH:mm" format or a fallback value if conversion fails
 */
function ConvertUTCtoHHMM(utcString: string): string {
  if (!utcString) {
    console.error('Invalid UTC string provided:', utcString);
    return '';
  }

  // Create a Date object from the UTC string
  const date = new Date(utcString);

  if (Number.isNaN(date.getTime())) {
    console.error('Invalid Date object created from UTC string:', utcString);
    return '';
  }

  // Use the system's time zone to convert the time to local time
  const options: Intl.DateTimeFormatOptions = {
    timeZone: currentTimeZone,
    hour: '2-digit',
    minute: '2-digit',
    hour12: false, // Ensure it's 24-hour format
  };

  try {
    // Format the date to local time in 24-hour format
    const timeString = new Intl.DateTimeFormat('en-US', options).format(date);
    return timeString;
  } catch (error) {
    console.error('Error formatting date:', error);
    return '';
  }
}

export default ConvertUTCtoHHMM;
