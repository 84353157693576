// Only add common function that required for API

import { getCookie } from '../shared-functions/Cookies';

/**
 * Get EV control key function
 */
const getElectricVehicleControlKey = () => {
  const accessToken = getCookie('ev-access-token');
  if (accessToken) {
    return accessToken;
  }
  return null;
};

/**
 * Get token function
 */
const getToken = () => {
  const accessToken = getElectricVehicleControlKey();
  if (accessToken) {
    return accessToken;
  }
  return null;
};

// Change when adding new functions
export default getToken;
