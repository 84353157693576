/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  ManagementAccountInterFace,
  ManagementAccountRequestInterface,
  ManagementAccountResponseInterface,
} from '../../interface/revenue-management.interface';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import revenueApi from '../../utils/api/revenue';

import { setNotificationMessage } from './NotificationManagementSlice';

interface ManageAccountInitialProperties {
  accounts: ManagementAccountInterFace[];
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  loading: boolean;
  filter: {};
}

// a thunk to fetch accounts
export const fetchAllAccounts = createAsyncThunk<
  ManagementAccountResponseInterface,
  ManagementAccountRequestInterface
>(
  'revenue-management-account/fetchAllAccounts',
  async (data: ManagementAccountRequestInterface, { dispatch }) => {
    try {
      const response = await revenueApi.fetchAccounts(
        removeEmptyKeys({ ...data, submissionStatus: 'approved' }),
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        setNotificationMessage({
          type: 'error',
          message: error?.response?.data?.message,
          code: error?.response?.data?.code,
          status: true,
        }),
      );
      throw error;
    }
  },
);

// Initial state
const initialState: ManageAccountInitialProperties = {
  accounts: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const revenueSlice = createSlice({
  name: 'revenue-management-account',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setRevenueAccountFilterData: (state, action) => {
      state.filter = action.payload;
    },
    /**
     * Function to update single station in redux
     * @param state
     * @param action
     */
    updateSingleAccount: (
      state: ManageAccountInitialProperties,
      action: PayloadAction<ManagementAccountInterFace>,
    ) => {
      const updatedAccount = action.payload; // station to be added at the top
      const accountList = state.accounts; // Assign the existing station to another variable

      // Find the index of the updated station by ID
      const index = accountList.findIndex((eachStation) => eachStation.id === updatedAccount.id);

      // If the index found, replace the entire data
      if (index !== -1) {
        accountList[index] = {
          ...updatedAccount,
          revenuePercentage: action.payload.revenuePercentage,
        };
      }
      state.accounts = accountList; // Finally update the station list
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllAccounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllAccounts.fulfilled, (state, action) => {
        state.loading = false;
        state.accounts = action.payload?.results?.length
          ? action.payload?.results?.map((result) => ({
              ...result,
              id: result._id,
              revenuePercentage: result.revenuePercentage ? Number(result.revenuePercentage) : 0,
            }))
          : [];
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchAllAccounts.rejected, (state) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setRevenueAccountFilterData, updateSingleAccount } = revenueSlice.actions;

// Export the reducer
export default revenueSlice.reducer;
