import { getCookie } from './Cookies';

/**
 * Handle the jwt token to decode the data function
 * @param token
 * @returns
 */
export const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split('.')[1]; // Get the payload part
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Adjust for Base64 decoding
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        // .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join(''),
    );
    return JSON.parse(jsonPayload); // Parse JSON
  } catch (error) {
    console.error('Invalid token', error);
    return null;
  }
};

/**
 * Handle the permission list view
 * @returns
 */
export const permissionList: () => string[] = () => {
  const token = getCookie('ev-access-token');
  if(token) {
    return decodeJWT(token).permissions;
  }
  return [];
};
