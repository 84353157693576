import { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface MenuList {
  function: (value: String) => void;
  content: JSX.Element;
  allow?: boolean;
  id: string;
}

interface PositionInterface {
  top: string;
  left: string;
}

/**
 * Drop Down function component
 */
function DropDownMenu({ list, icon }: { list: MenuList[]; icon: JSX.Element }) {
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [position, setPosition] = useState<PositionInterface>({
    top: '0px',
    left: '0px',
  });

  /**
   * Click the drop down function
   * @param event
   */
  const openDropdown = (event: React.MouseEvent) => {
    setPosition({
      left: `${event.clientX - 100}px`,
      top: `${event.clientY}px`,
    });
    setOpen(!open);
  };
  /**
   * Handle the close th drop down function
   * @param event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="drop-down">
      <div className="drop-down-title" onClick={openDropdown} role="presentation">
        {icon}
      </div>
      {open &&
        ReactDOM.createPortal(
          <div
            className="drop-down-content"
            ref={reference}
            style={{
              top: position.top,
              left: position.left,
              position: 'absolute',
            }}>
            {list.map(
              (value, index) =>
                value.allow && (
                  <>
                    {index !== 0 && <hr />}
                    <div
                      className="drop-down-content-list-item"
                      onClick={() => value.function(value.id)}
                      role="presentation">
                      {value.content}
                    </div>
                  </>
                ),
            )}
          </div>,
          document.body,
        )}
    </div>
  );
}

export default DropDownMenu;
