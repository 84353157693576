import { jsPDF as JsPDF } from 'jspdf';

/**
 * change the , value to the csv value
 * @param value
 * @returns
 */
const escapeCsvValue = (value: any) => {
  // Convert the value to a string, wrap it in quotes if it contains commas or quotes
  if (typeof value === 'string' && (value.includes(',') || value.includes('"'))) {
    return `"${value.replace(/"/g, '""')}"`; // Escape quotes by doubling them
  }
  return value;
};
/**
 * Export the CSV file
 * @param headers
 * @param rows
 */
export const exportCSV = (headers: string[], rows: string[][], fileName: string = 'data.csv') => {
  const data = rows.map((row) => row.map((value) => escapeCsvValue(value)));
  const csvContent = [headers, ...data].map((event_: any) => event_.join(',')).join('\n');

  // Create a Blob from the CSV content
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  // Create a link and trigger the download
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.setAttribute('download', fileName); // Set the filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // Cleanup
};
/**
 * Export the PDF file
 * @param headers
 * @param rows
 */
export const exportPDF = (
  headers: string[],
  rows: string[][],
  fileName: string = 'generated.pdf',
) => {
  const documentFile = new JsPDF();
  // Add content to the PDF
  let htmlContent = '<table style="border: solid 0.2px #000; border-collapse: collapse;"><tr>';
  htmlContent += '<th style="font-size: 4px; border: solid 0.2px #000; padding: 2px">S.No</th>';
  headers.forEach((element) => {
    htmlContent += `<th style="font-size: 4px; border: solid 0.2px #000; padding: 2px">${element}</th>`;
  });
  htmlContent += '</tr>';
  rows.forEach((header, index) => {
    htmlContent += `<tr><td style="font-size: 3px; border: solid 0.2px #000; padding: 2px">${index+1}</td>`;
    header.forEach((element) => {
      htmlContent += `<td style="font-size: 3px; border: solid 0.2px #000; padding: 2px">${element || ''}</td>`;
    });
    htmlContent += '</tr>';
  });
  documentFile.html(htmlContent, {
    /**
     * call back function
     * @param docment
     */
    callback: (docment) => {
      // Save the PDF
      docment.save(fileName); // Filename
    },
    x: 7,
    y: 10,
  });
};
