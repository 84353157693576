import React, { useEffect, useState } from 'react';

interface CustomToggleButtonProperties {
  labels: string[] | undefined;
  icons?: JSX.Element[];
  activeIcons?: JSX.Element[];
  onToggle?: (index: number) => void;
  defaultActiveIndex?: number;
}
/**
 * custom
 */
function CustomToggleButton({
  labels,
  icons,
  activeIcons,
  onToggle,
  defaultActiveIndex,
}: CustomToggleButtonProperties) {
  const [activeIndex, setActiveIndex] = useState(defaultActiveIndex);
  /**
   * toggle
   */
  const handleToggle = (index: number) => {
    setActiveIndex(index);
    if (onToggle) {
      onToggle(index);
    }
  };

  useEffect(() => {
    if (defaultActiveIndex) {
      setActiveIndex(defaultActiveIndex);
    }
  }, [defaultActiveIndex]);

  return (
    <div className="toggle-button">
      {labels?.map((label, index) => (
        <div
          className={`btn ${activeIndex === index ? 'active' : ''}`}
          onClick={() => handleToggle(index)}
          role="presentation">
          {activeIndex === index && activeIcons ? (
            <span className="icon active-icon">{activeIcons[index]}</span>
          ) : (
            icons && icons[index] && <span className="icon">{icons[index]}</span>
          )}
          {label}
        </div>
      ))}
    </div>
  );
}
CustomToggleButton.defaultProps = {
  defaultActiveIndex: 0,
  icons: undefined,
  activeIcons: undefined,
  onToggle: undefined,
};
export default CustomToggleButton;
