import getRequest from './base/get';
import patchRequest from './base/patch';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Dynamic pricing list based on filters
   */
  fetchDynamicPricing: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/dynamic-pricing/`,
      {
        ...parameters,
      },
    );
  },
  /**
   * Fetch the Dynamic pricing list for export
   */
  fetchDynamicPricingExport: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/dynamic-pricing/export-data`,
      {
        ...parameters,
      },
    );
  },
 
  /**
   * Update the status of dynamic pricing
   */
  updateDynamicPricing: (id: string, data: any): Promise<any> => {
    return patchRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/dynamic-pricing/${id}`,
      data,
    );
  },
};
