import React from 'react';
import { BarChart, Bar, XAxis, ResponsiveContainer, Cell } from 'recharts';

import { ReactComponent as PriceIcon } from '../../../assets/icons/price-increase-icon.svg';

interface ChartData {
  day: string;
  value: number;
  fill?: string; // Optional property for fill color
}

interface ChartSectionProperties {
  title: string;
  subtitle: string;
  submenuTitle: string;
  value: string;
  percentage: number;
  data: ChartData[];
}
/**
 * Chart component renders a bar chart to display power consumption data.
 * @returns {JSX.Element} - The rendered bar chart component.
 */
function ChartSection({
  title,
  subtitle,
  submenuTitle,
  value,
  percentage,
  data,
}: ChartSectionProperties): JSX.Element {
  const highestValue = Math.max(...data.map((item) => item.value));

  const processedData: ChartData[] = data.map((item) => ({
    ...item,
    fill: item.value === highestValue ? '#00A79D' : '#00A79D1A',
  }));

  return (
    <div className="chart-card">
      <div className="content-section">
        <h2 className="title">{value}</h2>
        <p className="subtitle">{title}</p>
        <div className="percentage-row">
          {percentage >= 0 ? (
            <span className="percentage tag-success-label">
              {percentage}% <PriceIcon />
            </span>
          ) : (
            <span className="percentage tag-danger-label">
              {percentage * -1}% <PriceIcon />
            </span>
          )}

          <span className="more-text">{subtitle}</span>
        </div>
        <div className="submenu-container">
          <span className="more-text">{submenuTitle}</span>
        </div>
      </div>
      <div className="chart-section">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={processedData}>
            {/* <CartesianGrid strokeDasharray="3 3" vertical={false} /> */}
            <XAxis
              dataKey="day"
              axisLine={false}
              tickLine={false}
              tick={{ fill: '#666', fontSize: 12 }}
            />
            <Bar dataKey="value" radius={[10, 10, 0, 0]} barSize={12}>
              {processedData.map((entry) => (
                <Cell key={entry.day} fill={entry.fill} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default ChartSection;
