import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as AlertIcon } from '../../assets/icons/alert-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/toaster-close-icon.svg';

interface ToastMessageProperties {
  open: Boolean;
  type: 'error' | 'success'; // Define more types if required
  message: string;
  onClose: () => void;
  onCloseTimer?: number;
}

/**
 * Customized toast message
 */
function ToastMessage(properties: ToastMessageProperties) {
  const { open, type, message, onClose, onCloseTimer } = properties;
  const containerReference = useRef<HTMLDivElement>(null);

  /**
   * Handle click outside the toast container
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (containerReference.current && !containerReference.current.contains(event.target as Node)) {
      onClose(); // Call the onClose function when clicked outside
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener('mousedown', handleClickOutside);
    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (onCloseTimer && open && onClose) {
      const timer = setTimeout(() => {
        onClose();
      }, onCloseTimer || 3000);

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }

    return undefined; // Explicitly return undefined when notificationCloseTimer is false
  }, [open, onCloseTimer]);

  return open
    ? ReactDOM.createPortal(
        <div className="toast-message-wrap">
          {(() => {
            let toastMessageContainer;
            if (type === 'error') {
              toastMessageContainer = (
                <div className={`toast-message-container ${type}`} ref={containerReference}>
                  <span>
                    <AlertIcon />
                  </span>
                  <p>{message}</p>
                  <span role="presentation" onClick={onClose}>
                    <CloseIcon />
                  </span>
                </div>
              );
            } else if (type === 'success') {
              toastMessageContainer = <div className={`toast-message-container ${type}`} />;
            }
            return toastMessageContainer;
          })()}
        </div>,
        document.body,
      )
    : null;
}

ToastMessage.defaultProps = {
  onCloseTimer: 6000,
};

export default ToastMessage;
