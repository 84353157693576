import React, { useEffect, useState } from 'react';

import { RoleAddInterFace, RoleInterFace } from '../../../interface/role-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import { fetchAllPermissions } from '../../../redux/slices/RolesManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CheckBoxButtons from '../../../shared-components/check-box-buttons/CheckBoxButtons';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import { stringValidation } from '../../../shared-functions/Validation';
import roleApi from '../../../utils/api/roles';

interface AddRoleInterface {
  closeModal: () => void;
  roleDetail?: RoleInterFace;
  completedTask: (message: string, type: string) => void;
}
/**
 * Role Add page function component
 */
function AddRole({ closeModal, roleDetail, completedTask }: AddRoleInterface) {
  const dispatch = useAppDispatch();
  const { permissionsList, loading } = useAppSelector((state) => state.roles);

  const [disabled, setDisabled] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [roleDetails, setRoleDetails] = useState<RoleAddInterFace>({
    name: roleDetail?.name || '',
    responsibility: roleDetail?.responsibility || '',
    permissions: roleDetail?.permissions || [],
  });

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setRoleDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    setShowFormErrors(true);
    if (
      stringValidation(1, 100, roleDetails.name) &&
      stringValidation(1, 100, roleDetails.responsibility) &&
      roleDetails.permissions.length > 0
    ) {
      setDisabled(true);
      const request = {
        name: roleDetails.name,
        responsibility: roleDetails.responsibility,
        permissions: roleDetails.permissions,
      };
      if (roleDetail?.id) {
        roleApi
          .editRoles(roleDetail?.id, request)
          .then(() => {
            setDisabled(false);
            completedTask('Role Updated Successfully', 'role');
          })
          .catch((error) => {
            setDisabled(false);
            dispatch(
              setNotificationMessage({
                message: error?.response?.data?.message,
                status: true,
                type: 'error',
                code: error?.response?.data?.code,
              }),
            );
          });
      } else {
        roleApi
          .createRoles(request)
          .then(() => {
            setDisabled(false);
            completedTask('Role Added Successfully', 'role');
          })
          .catch((error) => {
            setDisabled(false);
            dispatch(
              setNotificationMessage({
                message: error?.response?.data?.message,
                status: true,
                type: 'error',
                code: error?.response?.data?.code,
              }),
            );
          });
      }
    }
  };

  useEffect(() => {
    if (!loading && permissionsList.length === 0) {
      dispatch(fetchAllPermissions());
    }
  }, []);

  return (
    <div className="add-account-form">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Role Name"
            type="text"
            placeholder="Enter the name"
            value={roleDetails.name}
            onChangeValue={handleInputChange}
            valueRequired
            error={showFormErrors && !stringValidation(1, 100, roleDetails.name)}
            errorMessage="Role name is Required"
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="responsibility"
            inputLabel="Responsibility"
            type="text"
            placeholder="Enter the Details"
            value={roleDetails.responsibility}
            onChangeValue={handleInputChange}
            error={showFormErrors && !stringValidation(1, 100, roleDetails.responsibility)}
            valueRequired
            errorMessage="Responsibility is Required"
          />
        </div>
        <div className="form-field">
          <CheckBoxButtons
            name="permissions"
            list={permissionsList}
            inputLabel="Permissions"
            value={roleDetails.permissions}
            onChangeValue={handleInputChange}
            valueRequired
            error={showFormErrors && roleDetails.permissions.length === 0}
            errorMessage="Permissions is Required"
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Submit"
              buttonType="submit"
              onClickFunc={handleSubmit}
              disabled={disabled}
              loading={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

AddRole.defaultProps = {
  roleDetail: {},
};

export default AddRole;
