import MyButton from '../button/Button';
import CustomToggleButton from '../custom-toggle-button/CustomToggleButton';


interface PageTitleProperties {
  title: string;
  // Props required for button
  buttonLabel?: string;
  buttonSvgIcon?: JSX.Element;
  buttonType?: 'submit' | 'back' | 'error';
  onClickButton?: () => void;
  handleToggle?: (value: number | string) => void;
  icons?: JSX.Element[];
  activeIcons?: JSX.Element[];
  labels?: string[];
  // Props required for toggle button
  enableToggleButton?: boolean;
  disableHover?: boolean;
  defaultIndex?: number;
  toggleList?: string[];
}

/**
 * Shared page title component
 */
function PageTitle(properties: PageTitleProperties) {
  const { title, buttonLabel, buttonSvgIcon, icons, activeIcons, buttonType, toggleList, defaultIndex, onClickButton, handleToggle, enableToggleButton } =
    properties;
  return (
    <div className={enableToggleButton ? 'page-title-wrap-toggle' : "page-title-wrap"}>
      <div className="page-title-left">
        <h1>{title}</h1>
        {enableToggleButton && (
          <div className="page-title-toggle-button">
            <CustomToggleButton
              labels={toggleList}
              icons={icons}
              activeIcons={activeIcons}
              onToggle={handleToggle}
              defaultActiveIndex={defaultIndex}
            />
          </div>
        )}
      </div>
      <div className="page-title-right">
        {buttonLabel && buttonType && onClickButton && (
          <MyButton
            label={buttonLabel}
            buttonType={buttonType}
            svgIcon={buttonSvgIcon}
            // disableHoverEffect={disableHover}
            onClickFunc={onClickButton}
          />
        )}
      </div>
    </div>
  );
}

PageTitle.defaultProps = {
  buttonLabel: '',
  buttonSvgIcon: null,
  buttonType: 'submit',
  icons: '',
  activeIcons: '',
  disableHover: '',
  defaultIndex: '',
  /**
   *onclick event
   */
  onClickButton: () => { },
  /**
   * toggleButton event
   */
  handleToggle: () => { },
  // onClickButton: null,
  enableToggleButton: false,
  toggleList: [],
};

export default PageTitle;
