import React from 'react';
import { Navigate } from 'react-router-dom';

import { permissionList } from '../../../shared-functions/JWTDocodeFunction';

/**
 * Handle the auth guard for check permission function
 * @param {*} param0 
 * @returns 
 */
function AuthGuard({ permission, Component }: { permission: string[]; Component: JSX.Element }) {
  if (permission.length === 0) return Component;

  const permissionLists = permissionList();

  const permissionAssign = permission.find((per) => permissionLists.includes(per));

  return permissionAssign ? Component : <Navigate to="/login" />; // <Navigate to="/login" />
}

export default AuthGuard;
