import getRequest from './base/get';
import patchRequest from './base/patch';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchAccounts: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query, limit: query?.limit || 5 };
    return getRequest(
      `${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/list-accounts`,
      { ...parameters },
    );
  },
  /**
   * Fetch the Accounts list for export
   */
  fetchAccountsExport: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/export-accounts`,
      { ...parameters },
    );
  },
  /**
   * Edit the account function
   * @param id
   * @param data
   */
  editAccountPercentage: (data: any): Promise<any> => {
    return patchRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/revenue-sharing`,
      data,
    );
  },
  /**
   * Fetch the charging station list with pagination
   */
  fetchChargingStations: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/charging-station`,
      {
        ...parameters,
      },
    );
  },
  /**
   * Fetch the charging station list for export
   */
  fetchChargingStationsExport: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/charging-station/export-data`,
      {
        ...parameters,
      },
    );
  },
  /**
   * Fetch the charging station list with pagination
   */
  fetchAccountList: () => {
    return getRequest(
      `${process.env.REACT_APP_API_URL}api/super-admin/v1/facility-account/list-accounts`,
    );
  },
  /**
   * Fetch the charging station list with pagination
   */
  fetchRevenueSharing: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/charging-transaction-revenue/list-transaction-sharing`,
      {
        ...parameters,
      },
    );
  },
  /**
   * Fetch the revenue list for export
   */
  fetchRevenueSharingExport: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(
      `${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/charging-transaction-revenue/list-transaction-sharing/export`,
      {
        ...parameters,
      },
    );
  },
};
