import React, { useEffect, useState } from 'react';

import { ReactComponent as EditPencil } from '../../../assets/icons/edit-pencil.svg';
import { ReactComponent as MultiUsers } from '../../../assets/icons/multi-users.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { ApiRequest } from '../../../interface/common.interface';
import {
  FilterRoleInterFace,
  RequestRoleInterFace,
  RoleInterFace,
} from '../../../interface/role-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import { fetchAllRoles, setRoleFilterData } from '../../../redux/slices/RolesManagementSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';
import ConvertUTCtoDate from '../../../shared-functions/ConvertUTCtoDate';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import rolesApi from '../../../utils/api/roles';

import AddRole from './add-role';
import FilterRole from './filter-role';

interface ModalState {
  status: boolean;
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
  notification: boolean;
}

/**
 * User Management page function component
 */
function RolesList() {
  const dispatch = useAppDispatch();
  const { roles, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.roles,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof RoleInterFace,
      sortAllow: true,
    },
    {
      label: 'Role Name',
      accessorKey: 'name' as keyof RoleInterFace,
      sortAllow: true,
    },
    {
      label: 'Responsibility',
      accessorKey: 'responsibility' as keyof RoleInterFace,
      sortAllow: true,
    },
  ];
  const [modalView, setModalView] = useState<ModalState>({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
    notification: false,
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Fetch Users list function
   */
  const fetchData = (data: ApiRequest) => {
    if (!loading) {
      dispatch(fetchAllRoles(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Completed the update task
   * @param message
   */
  const completedTask = (message: string) => {
    fetchData({
      limit: DefaultApiParameters.limit,
      page: DefaultApiParameters.page,
    });
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <SuccessNotification
          title={message}
          description=""
          notificationCloseTimer={3000}
          onNotificationClose={closeModal}
        />
      ),
      rightCorner: '',
      notification: true,
    });
  };

  /**
   * User details view function
   */
  const viewFunction = (currentData: String) => {
    const roleDetail: RoleInterFace | undefined = roles.find(
      (value: RoleInterFace) => value.id === currentData,
    );
    setModalView({
      status: true,
      title: 'Edit Role',
      header: true,
      content: (
        <AddRole closeModal={closeModal} roleDetail={roleDetail} completedTask={completedTask} />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: FilterRoleInterFace) => {
    dispatch(setRoleFilterData(filterData));
    fetchData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };
  /**
   * Handle clear filter data function
   * @param value
   */
  const handleClearFilter = () => {
    dispatch(setRoleFilterData({}));
    fetchData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Roles Filter',
      header: false,
      content: (
        <FilterRole
          closeModal={closeModal}
          handleSubmitFilter={handleSubmitFilter}
          oldValue={filter}
        />
      ),
      rightCorner: '',
      notification: false,
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestRoleInterFace) => {
    if (value.sortField) {
      dispatch(
        setRoleFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchData({ ...filter, ...value });
  };

  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    rolesApi
      .exportRoles({ ...filter })
      .then((response) => {
        // Convert data to CSV format
        const headers = ['Date', 'Role Name', 'Responsibility'];
        const rows = response.data.map((row: RoleInterFace) => [
          row.createdAt ? ConvertUTCtoDate(row.createdAt) : '',
          row.name,
          row.responsibility,
        ]);
        if (type === 'csv') {
          exportCSV(headers, rows, 'roles-list.csv');
        } else {
          exportPDF(headers, rows, 'roles-list.pdf');
        }
        setExportLoader(false);
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (roles.length === 0) {
      fetchData({
        ...filter,
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
        notification={modalView.notification}
      />
      <CustomTable
        header={column}
        data={roles}
        serialNo
        actionButton
        height={window.innerHeight >= 700 ? 432 : 390}
        loading={loading}
        page={page}
        limit={limit}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        sizeChangeButtonRequired
        filter={filter}
        filterClearFunction={handleClearFilter}
        filterFunction={filterFunction}
        actionButtonList={[
          {
            icon: <EditPencil />,
            function: viewFunction,
            color: 'view-color',
            allow: true,
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={roles.length === 0}
                loader={exportLoader}
              />
            </div>
            <div className="table-button-item">
              <div className="user-count">
                <div className="icon">
                  <MultiUsers />
                </div>
                <div className="value">{totalResults} Users</div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
}

export default RolesList;
