import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as Calender } from '../../assets/icons/calender.svg';
import { ReactComponent as LeftArrow } from '../../assets/icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/icons/right-arrow.svg';
import { changeDateFormat } from '../../shared-functions/DateTime';

interface CustomDateRangeProperties {
  inputLabel?: string;
  value: string;
  name: string;
  placeholder?: string;
  onChangeValue: (name: string, value: string) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
}

/**
 * Custom the Date Picker function
 */
function CustomDatePicker({
  inputLabel,
  name,
  value,
  placeholder,
  onChangeValue,
  valueRequired,
  readOnly,
}: CustomDateRangeProperties) {
  const reference = useRef<HTMLDivElement>(null);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const [currentMonth, setCurrentMonth] = useState<number>(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [allDates, setAllDates] = useState<any[]>([]);
  const [style, setStyle] = useState<any>({});

  const [open, setOpen] = useState<boolean>(false);
  /**
   * Handle the Value change
   */
  const handleValueChange = (changeValue: string) => {
    onChangeValue(name, changeValue);
    setOpen(false);
  }

  /**
   * Handle the Input click
   */
  const openCalenderView = (event: any) => {
    if (!readOnly) {
      setOpen(true);
      let rect;
      if (event.target.className === 'custom-input-container') {
        rect = event.target.getBoundingClientRect();
      } else {
        rect = event.target.closest(".custom-input-container").getBoundingClientRect();
      }
      setStyle({
        top: rect.top + rect.height,
        left: rect.left,
      });
    }
  };

  /**
   * Get the all days use month function
   * @param month
   * @param year
   */
  const getAllDays: (month: number, year: number) => void = (month: number, year: number) => {
    const date = new Date(year, month, 1);
    let days = [];
    while (date.getMonth() === month) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    days = days.map((valueChange) => ({
      date: changeDateFormat(valueChange.toString()),
      number: valueChange.getDate(),
      day: valueChange.getDay(),
    }));
    if (days[0].day > 0) {
      const firstMonth = days[0].day;
      for (let index = 0; index < firstMonth; index += 1) {
        days = [{} , ...days];
      }
    }
    setAllDates(days);
  };

  /**
   * Handle the month change function
   * @param type
   */
  const changeMonth = (type: string) => {
    let changeValue = 0;
    let changeYear = currentYear;
    if (type === 'add') {
      changeValue = currentMonth + 1;
      if (changeValue === months.length) {
        changeValue = 0;
        changeYear = currentYear + 1;
        setCurrentYear(currentYear + 1);
      }
      getAllDays(changeValue, changeYear);

      setCurrentMonth(changeValue);
    } else {
      changeValue = currentMonth - 1;
      if (changeValue === -1) {
        changeValue = 11;
        changeYear = currentYear - 1;
        setCurrentYear(currentYear - 1);
      }
      getAllDays(changeValue, changeYear);
      setCurrentMonth(changeValue);
    }
  };

  /**
   * Handle close the calender over flow chlick function
   * @param event 
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };
  useEffect((): void => {
    getAllDays(currentMonth, currentYear)
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="custom-input-date-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-date-input">
        <div className="custom-input-container" onClick={openCalenderView} role="presentation">
          {/* <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(event) => handleValueChange(event.target.value)}
            required={valueRequired}
            readOnly
          /> */}
          <div className="value-view">{value || <p>{placeholder}</p>}</div>
          
          <span>
            <Calender />
          </span>
        </div>
        {open && ReactDOM.createPortal(
          <div className="calender-box" ref={reference} style={style}>
            <div className="calender-header">
              <div
                className="calender-header-left"
                onClick={() => changeMonth('sub')}
                role="presentation">
                <LeftArrow />
              </div>
              <div className="calender-header-center">{months[currentMonth]} - {currentYear}</div>
              <div
                className="calender-header-right"
                onClick={() => changeMonth('add')}
                role="presentation">
                <RightArrow />
              </div>
            </div>
            <div className="calender-body">
              <div className="calender-header-items">
                <div className="header-item">SUN</div>
                <div className="header-item">MON</div>
                <div className="header-item">TUS</div>
                <div className="header-item">WEN</div>
                <div className="header-item">THU</div>
                <div className="header-item">FRI</div>
                <div className="header-item">SAT</div>
              </div>
              <div className="calender-body-items">
                {allDates.map((dateDetail: any) =>
                  Object.keys(dateDetail).length === 0 ? (
                    <div className="header-item" />
                  ) : (
                    <div className="header-item" onClick={() => handleValueChange(dateDetail.date)} role="presentation">{dateDetail.number}</div>
                  ),
                )}
              </div>
            </div>
          </div>,
            document.body,
        )}
      </div>
    </div>
  );
}

CustomDatePicker.defaultProps = {
  inputLabel: '',
  placeholder: '',
  valueRequired: false,
  readOnly: false,
};

export default CustomDatePicker;
