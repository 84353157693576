/* eslint-disable no-param-reassign */
import {
  createSlice,
} from '@reduxjs/toolkit';

interface NotificationInitialProperties {
  notificationStatus: boolean;
  notificationMessage: string;
  notificationType: 'success' | 'error';
  notificationCode: number;
}

// Initial state
const initialState: NotificationInitialProperties = {
  notificationStatus: false,
  notificationMessage: '',
  notificationType: 'error',
  notificationCode: 0,
};

// Create the slice
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    /**
     * Set notification details function
     * @param state
     * @param action
     */
    setNotificationMessage: (state, action) => {
      state.notificationStatus = action.payload.status;
      state.notificationMessage = action.payload.message;
      state.notificationType = action.payload.type;
      state.notificationCode = action.payload.code;
    },
  },
});
export const { setNotificationMessage } = notificationSlice.actions;
// Export the reducer
export default notificationSlice.reducer;
