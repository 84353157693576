/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  AccountFilterInterFace,
  AccountInitialProperties,
  APIAccountResponseInterface,
  RequestAccountInterface,
} from '../../interface/account-management.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import accountApi from '../../utils/api/account';

import { setNotificationMessage } from './NotificationManagementSlice';

// a thunk to fetch accounts
export const fetchAllAccounts = createAsyncThunk<
  APIAccountResponseInterface,
  RequestAccountInterface
>('account-management/fetchAllAccounts', async (data: RequestAccountInterface, { dispatch }) => {
  const response = await accountApi
    .fetchAccounts(removeEmptyKeys(data))
    .then((response1) => response1)
    .catch((error) =>
      dispatch(
        setNotificationMessage({
          type: 'error',
          message: error?.response?.data?.message,
          code: error?.response?.data?.code,
          status: true,
        }),
      ),
    );
  return {
    ...response.data,
    results: response.data.results.map((roleData: any) => ({
      ...roleData,
      createdAt: ConvertUTCtoDate(roleData.createdAt),
      accountType: 'Facility Owner',
      status: roleData.submission.status,
      id: roleData._id,
    })),
  };
});

// Initial state
const initialState: AccountInitialProperties = {
  accounts: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const accountSlice = createSlice({
  name: 'account-management',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setAccountFilterData: (
      state: AccountInitialProperties,
      action: PayloadAction<AccountFilterInterFace>,
    ) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<AccountInitialProperties>) => {
    builder
      .addCase(fetchAllAccounts.pending, (state: AccountInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllAccounts.fulfilled,
        (state: AccountInitialProperties, action: PayloadAction<APIAccountResponseInterface>) => {
          state.loading = false;
          state.accounts = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllAccounts.rejected, (state: AccountInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setAccountFilterData } = accountSlice.actions;

// Export the reducer
export default accountSlice.reducer;
