/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  APIRoleResponseInterface,
  FilterRoleInterFace,
  RequestRoleInterFace,
  RoleInitialProperties,
  RoleInterFace,
} from '../../interface/role-management.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import roleApi from '../../utils/api/roles';

import { setNotificationMessage } from './NotificationManagementSlice';

// a thunk to fetch users
export const fetchAllRoles = createAsyncThunk<APIRoleResponseInterface, RequestRoleInterFace>(
  'role-management/fetchAllRoles',
  async (data: RequestRoleInterFace, { dispatch }) => {
    const response = await roleApi
      .fetchRoles(removeEmptyKeys(data))
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return {
      ...response.data,
      results: response.data.results.map((roleData: RoleInterFace) => ({
        ...roleData,
        createdAt: ConvertUTCtoDate(roleData.createdAt || ''),
        id: roleData._id,
      })),
    };
  },
);

export const fetchAllListRoles = createAsyncThunk<{ label: string, value: string }[]>(
  'role-management/fetchAllListRoles',
  async (data, { dispatch }) => {
    try {
      const response = await roleApi.fetchAllRoles();
      return response.data.map((roleData: RoleInterFace) => ({
        ...roleData,
        id: roleData._id,
        label: roleData.name,
        value: roleData._id,
      }));
    } catch (error: any) {
      dispatch(
        setNotificationMessage({
          type: 'error',
          message: error?.response?.data?.message || 'Something went wrong!',
          code: error?.response?.data?.code || 200,
          status: true,
        }),
      );
      // Explicitly rethrow or return a rejected promise
      throw error; // Option 1: Rethrow the error for further handling
      // or return Promise.reject(error); // Option 2: Return a rejected promise
    }
  },
);

export const fetchAllPermissions = createAsyncThunk<{ label: string; value: string }[]>(
  'role-management/fetchAllPermissions',
  async (data, { dispatch }) => {
    const response = await roleApi
      .fetchAllPermissions()
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return response.data.map((permission: string) => ({
      label: permission,
      value: permission,
    }));
  },
);

// Initial state
const initialState: RoleInitialProperties = {
  roles: [],
  rolesList: [],
  permissionsList: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const roleSlice = createSlice({
  name: 'roles-management',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setRoleFilterData: (
      state: RoleInitialProperties,
      action: PayloadAction<FilterRoleInterFace>,
    ) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<RoleInitialProperties>) => {
    builder
      .addCase(fetchAllRoles.pending, (state: RoleInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllRoles.fulfilled,
        (state: RoleInitialProperties, action: PayloadAction<APIRoleResponseInterface>) => {
          state.loading = false;
          state.roles = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllRoles.rejected, (state: RoleInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
    builder
      .addCase(fetchAllListRoles.pending, (state: RoleInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllListRoles.fulfilled,
        (state: RoleInitialProperties, action: PayloadAction<{ label: string, value: string }[]>) => {
          state.loading = false;
          state.rolesList = [...action.payload];
        },
      )
      .addCase(fetchAllListRoles.rejected, (state: RoleInitialProperties) => {
        state.loading = false;
      },
    );
    builder
      .addCase(fetchAllPermissions.pending, (state: RoleInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllPermissions.fulfilled,
        (
          state: RoleInitialProperties,
          action: PayloadAction<{ label: string; value: string }[]>,
        ) => {
          state.loading = false;
          state.permissionsList = [...action.payload];
        },
      )
      .addCase(fetchAllPermissions.rejected, (state: RoleInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setRoleFilterData } = roleSlice.actions;
// Export the reducer
export default roleSlice.reducer;
