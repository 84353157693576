import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import loginBanner from '../../assets/images/login/login-banner.png';
// import sample from '../../assets/videos/background.mp4';

import { ReactComponent as TimerIcon } from '../../../assets/icons/clock-icon.svg';
import { ReactComponent as LoginBanner } from '../../../assets/icons/login-banner.svg';
import { useAppDispatch } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomOtpInput from '../../../shared-components/custom-otp-input/CustomOtpInput';
import { getLocalStorageItem, setLocalStorageItem } from '../../../shared-functions/LocalStorage';
import authApi from '../../../utils/api/auth';

/**
 * Login page function component
 */
function OTPVerification() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const passwordResetDetails = getLocalStorageItem('passwordReset');
  const [OTP, setOTP] = useState<string[]>(Array(6).fill(''));
  const [disabled, setDisabled] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);
  const [resendTimer, setResendTimer] = useState<number>(30);
  const intervalReference = useRef<NodeJS.Timeout | null>(null);

  /**
   * handle submit function
   */
  const submitFunction = () => {
    setSubmitOpen(true);
    if (!OTP.some((digit) => digit === '')) {
      setDisabled(true);
      const otp = OTP.join('');
      setLocalStorageItem('passwordReset', {
        ...passwordResetDetails,
        otp,
      });
      authApi
        .resetPasswordOTPVerify({
          emailOrPhoneNumber: passwordResetDetails.emailOrPhoneNumber,
          otp,
        })
        .then(() => {
          setDisabled(false);
          navigate('../new-password');
        })
        .catch((error) => {
          setDisabled(false);
          dispatch(
            setNotificationMessage({
              message: error?.response?.data?.message,
              status: true,
              type: 'error',
              code: error?.response?.data?.code,
            }),
          );
        });
    }
  };

  /**
   * Function to handle Resend OTP timer
   */
  const startResendTimer = () => {
    // Clear any previous interval before starting a new one
    if (intervalReference.current) clearInterval(intervalReference.current);

    setResendTimer(30); // Reset to 60 seconds
    intervalReference.current = setInterval(() => {
      setResendTimer((previous) => {
        if (previous <= 1) {
          if (intervalReference.current) clearInterval(intervalReference.current); // Clear interval at the end
          return 0;
        }
        return previous - 1;
      });
    }, 1000);
  };

  /**
   * Function to resend OTP
   */
  const handleResendOTP = () => {
    if (resendTimer === 0) {
      startResendTimer();
      authApi
        .forgotPassword({
          countryCode: passwordResetDetails.countryCode,
          emailOrPhoneNumber: passwordResetDetails.emailOrPhoneNumber,
        })
        .then(() => {})
        .catch((error) => {
          dispatch(
            setNotificationMessage({
              message: error?.response?.data?.message,
              status: true,
              type: 'error',
              code: error?.response?.data?.code,
            }),
          );
        });
    }
  };

  useEffect(() => {
    if (!passwordResetDetails) {
      navigate('../forgot-password');
    }

    startResendTimer(); // Start the timer when the component mounts
    return () => {
      if (intervalReference.current) clearInterval(intervalReference.current); // Clear interval on unmount
    };
  }, []);

  return (
    <div className="login-wrap">
      {/* <video className='videoTag' autoPlay loop muted>
        <source src={sample} type='video/mp4' />
      </video> */}
      <div className="login-banner-container">
        {/* <img src={loginBanner} alt="banner" className="login-banner" /> */}
        <LoginBanner />
      </div>
      <div className="login-form-container">
        <div className="login-form-with-border">
          <div className="forget-password-form">
            <h1>OTP </h1>
            <CustomOtpInput
              length={6}
              OTP={OTP}
              setOTP={setOTP}
              type="number"
              inputLabel="Enter OTP"
              error={submitOpen && OTP.some((digit) => digit === '')}
            />
            <div className="resend-otp-wrap">
              <div className="resend-timer-wrap">
                <span className="resend-icon">
                  <TimerIcon />
                </span>
                <span>00:{resendTimer < 10 ? `0${resendTimer}` : resendTimer}</span>
              </div>
              <p
                role="presentation"
                onClick={handleResendOTP}
                className={`resendOtp ${resendTimer > 0 ? 'disabled' : ''}`}>
                Resend
              </p>
            </div>
            <MyButton
              label="Get OTP"
              buttonType="submit"
              onClickFunc={submitFunction}
              disabled={disabled}
            />
          </div>
          {/* <div className="gradient-border">
            <div className="white-bg-color" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default OTPVerification;
