import React from 'react';

import CustomRadioButton from '../../../shared-components/custom-radio-button/CustomRadioButton';

interface RevenueManagementProperties {
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}

/**
 * Revenue management toggle component
 * @returns
 */
function RevenueManagementToggle(properties: RevenueManagementProperties) {
  const { currentPage, setCurrentPage } = properties;
  /**
   * handle change radio button function
   * @param name
   * @param value
   */
  const handleAvailabilityChange = (name: string, value: string) => {
    setCurrentPage(value);
  };
  return (
    <div className="revenue-management-toggle-wrap">
      <CustomRadioButton
        name=""
        value="account"
        label="Account"
        selected={currentPage === 'account'}
        onChangeFunc={handleAvailabilityChange}
      />
      <CustomRadioButton
        name=""
        value="station"
        label="Station"
        selected={currentPage === 'station'}
        onChangeFunc={handleAvailabilityChange}
      />
    </div>
  );
}

export default RevenueManagementToggle;
