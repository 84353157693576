/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  RevenueSharingInterFace,
  FilterRevenueInterFace,
  RevenueSharingRequestInterface,
  RevenueSharingResponseInterface,
} from '../../interface/revenue-management.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import ConvertUTCtoHHMM from '../../shared-functions/ConvertUTCtoHHMM';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import FormatUtcString from '../../shared-functions/FormatUtcString';
import revenueApi from '../../utils/api/revenue';

import { setNotificationMessage } from './NotificationManagementSlice';

interface RevenueSharingInitialProperties {
  revenueList: RevenueSharingInterFace[];
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  loading: boolean;
  filter: FilterRevenueInterFace;
}

// a thunk to fetch accounts
export const fetchRevenueSharingList = createAsyncThunk<
  RevenueSharingResponseInterface,
  RevenueSharingRequestInterface
>(
  'revenue-sharing-account/fetchRevenueSharingList',
  async (data: RevenueSharingRequestInterface, { dispatch }) => {
    try {
      const response = await revenueApi.fetchRevenueSharing(removeEmptyKeys({ ...data }));
      return response.data;
    } catch (error: any) {
      dispatch(
        setNotificationMessage({
          type: 'error',
          message: error?.response?.data?.message,
          code: error?.response?.data?.code,
          status: true,
        }),
      );
      throw error;
    }
  },
);

// Initial state
const initialState: RevenueSharingInitialProperties = {
  revenueList: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const revenueSharingSlice = createSlice({
  name: 'revenue-sharing-account',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setRevenueSharingFilterData: (state, action) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueSharingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRevenueSharingList.fulfilled, (state, action) => {
        state.loading = false;
        state.revenueList = action.payload?.results?.length
          ? action.payload?.results?.map((result) => ({
              ...result,
              id: result._id,
              createdAt: result.createdAt ? ConvertUTCtoDate(result.createdAt) : '',
              time: result.createdAt ? FormatUtcString(ConvertUTCtoHHMM(result?.createdAt)) : '',
            }))
          : [];
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchRevenueSharingList.rejected, (state) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setRevenueSharingFilterData } = revenueSharingSlice.actions;

// Export the reducer
export default revenueSharingSlice.reducer;
