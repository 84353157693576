/**
 * Card box function component
 */
function CardBox({ children, className }: { children: JSX.Element; className?: string }) {

  return (
    <div className={`card-box-warp ${className}`}>
      <div className="card-content">
        {children}
      </div>
    </div>
  );
}

CardBox.defaultProps = {
  className: '',
};

export default CardBox;
