import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit-pencil.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import {
  FilterRevenueInterFace,
  ManagementAccountInterFace,
} from '../../../interface/revenue-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import {
  fetchAllAccounts,
  updateSingleAccount,
  setRevenueAccountFilterData,
} from '../../../redux/slices/RevenueManagementAccountSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import revenueApi from '../../../utils/api/revenue';

import FilterRevenue from './filter-management';
import RevenueManagementToggle from './RevenueManageToggle';

interface ModalState {
  status: boolean;
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
}

interface ManagementAccountRevenueProperties {
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}

interface EditPercentageProperties {
  id: string;
  value: number;
  accountCustomId: string;
}

/**
 * ManagementAccountRevenue page function component
 */
function ManagementAccountRevenue(properties: ManagementAccountRevenueProperties) {
  const { currentPage, setCurrentPage } = properties;

  const dispatch = useAppDispatch();
  const inputReference = useRef<HTMLInputElement>(null);
  const { accounts, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.revenueAccount,
  );
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  const [editPercent, setEditPercent] = useState<EditPercentageProperties>({
    id: '',
    value: 0,
    accountCustomId: '',
  });

  /**
   * Function to handle edit percentage
   */
  const handleEditPercentage = (rowData: ManagementAccountInterFace) => {
    setEditPercent({
      id: rowData.id,
      value: rowData.revenuePercentage ? rowData.revenuePercentage : 0,
      accountCustomId: rowData.accountCustomId,
    });
  };

  /**
   * Function to handle percentage edit
   * @param name
   * @param value
   */
  const handleChangePercentage = (value: string | number) => {
    setEditPercent({
      ...editPercent,
      value: Number(value),
    });
  };

  /**
   * API call to update percentage
   */
  const handleUpdateRevenuePercentage = async () => {
    await revenueApi
      .editAccountPercentage({
        revenuePercentage: editPercent.value.toString(),
        accountCustomId: editPercent.accountCustomId,
      })
      .then(() => {
        const updatedAccount: ManagementAccountInterFace | undefined = accounts.find(
          (account) => account.id === editPercent.id,
        );
        if (updatedAccount) {
          dispatch(
            updateSingleAccount({
              ...updatedAccount,
              revenuePercentage: editPercent.value,
            }),
          );
        }
        setEditPercent({
          id: '',
          value: 0,
          accountCustomId: '',
        });
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Function to handle click outside of input field
   * @param event
   */
  const handleOutsideClick = async (event: MouseEvent) => {
    if (editPercent.id !== '') {
      handleUpdateRevenuePercentage();
    }

    if (inputReference.current && !inputReference.current.contains(event.target as Node)) {
      setEditPercent({
        id: '',
        value: 0,
        accountCustomId: '',
      });
    }
  };

  const column = [
    {
      label: 'Account Id',
      accessorKey: 'accountCustomId' as keyof ManagementAccountInterFace,
      sortAllow: true,
    },
    {
      label: 'Account Name',
      accessorKey: 'accountName' as keyof ManagementAccountInterFace,
      sortAllow: true,
    },
    {
      label: 'Percentage',
      accessorKey: 'percentage' as keyof ManagementAccountInterFace,
      sortAllow: true,
      /**
       * Function to handle input for edit percentage
       * @param rowData
       */
      customData: (rowData: ManagementAccountInterFace) => (
        <div className="table-row-data">
          {editPercent.id === rowData.id ? (
            <span>
              <input
                ref={inputReference}
                value={editPercent.value}
                onChange={(event) => handleChangePercentage(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleUpdateRevenuePercentage();
                  }
                }}
                className="table-row-input-field"
              />
            </span>
          ) : (
            <span>{rowData.revenuePercentage}%</span>
          )}
        </div>
      ),
      columnWidth: 40,
    },
    {
      label: 'Action',
      accessorKey: '' as keyof ManagementAccountInterFace,
      sortAllow: false,
      /**
       * Function to handle input for edit percentage
       * @param rowData
       */
      customData: (rowData: ManagementAccountInterFace) => (
        <div className="action-button-group">
          <div
            className="action-button-item view-color"
            onClick={() => handleEditPercentage(rowData)}
            role="presentation">
            <EditIcon />
          </div>
        </div>
      ),
      columnWidth: 20,
    },
  ];

  const [modalView, setModalView] = useState<ModalState>({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchAccountsData = (data: any) => {
    if (!loading) {
      dispatch(fetchAllAccounts(data));
    }
  };
  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: FilterRevenueInterFace) => {
    dispatch(setRevenueAccountFilterData(filterData));
    fetchAccountsData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * Filter clear
   */
  const clearFilter = () => {
    dispatch(setRevenueAccountFilterData({}));
    fetchAccountsData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Roles Filter',
      header: false,
      content: (
        <FilterRevenue
          closeModal={closeModal}
          handleSubmitFilter={handleSubmitFilter}
          oldValue={filter}
        />
      ),
      rightCorner: '',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    if (value.sortField) {
      dispatch(
        setRevenueAccountFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchAccountsData({ ...filter, ...value });
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    revenueApi
      .fetchAccountsExport({ ...filter, submissionStatus: 'approved' })
      .then((response) => {
        // Convert data to CSV format
        const headers = ['Account Id', 'Account Name', 'Percentage'];
        const rows = response.data.map((row: ManagementAccountInterFace) => [
          row.accountCustomId,
          row.accountName,
          `${row.revenuePercentage ? row.revenuePercentage : 0}%`,
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'revenue-management-account-list.csv');
          } else {
            exportPDF(headers, rows, 'revenue-management-account-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [editPercent]);

  useEffect(() => {
    if (accounts.length === 0) {
      fetchAccountsData({
        limit,
        page,
      });
    }
  }, []);

  useEffect(() => {
    if (editPercent.id && inputReference.current) {
      inputReference.current.focus(); // Ensure the input is focused after state update
    }
  }, [editPercent.id]);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={accounts}
        serialNo
        loading={loading}
        page={page}
        limit={limit}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        filterClearFunction={clearFilter}
        filterFunction={filterFunction}
        sizeChangeButtonRequired
        rightTopContent={
          <RevenueManagementToggle currentPage={currentPage} setCurrentPage={setCurrentPage} />
        }
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={accounts.length === 0}
                loader={exportLoader}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default ManagementAccountRevenue;
