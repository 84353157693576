/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  APIReportResponseInterface,
  ReportsInitialProperties,
  ReportsInterFace,
  ReportFilterInterFace,
  RequestReportInterFace,
} from '../../interface/report-management.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import reportApi from '../../utils/api/reports';

import { setNotificationMessage } from './NotificationManagementSlice';

// a thunk to fetch users
export const fetchAllReports = createAsyncThunk<APIReportResponseInterface, RequestReportInterFace>(
  'reports-management/fetchAllReports',
  async (data: RequestReportInterFace, { dispatch }) => {
    const response = await reportApi
      .fetchReports(removeEmptyKeys(data))
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return {
      ...response.data,
      results: response.data.results.map((consumerData: ReportsInterFace) => ({
        ...consumerData,
        totalPowerConsumed: parseInt(consumerData.totalPowerConsumed, 10) / 1000,
        totalRevenue: parseFloat(consumerData.totalRevenue).toFixed(2),
        transactionStartsAt: ConvertUTCtoDate(consumerData.transactionStartsAt),
        id: consumerData._id,
      })),
    };
  },
);

// Initial state
const initialState: ReportsInitialProperties = {
  reports: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const reportsSlice = createSlice({
  name: 'report-management',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setReportFilterData: (
      state: ReportsInitialProperties,
      action: PayloadAction<ReportFilterInterFace>,
    ) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<ReportsInitialProperties>) => {
    builder
      .addCase(fetchAllReports.pending, (state: ReportsInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllReports.fulfilled,
        (state: ReportsInitialProperties, action: PayloadAction<APIReportResponseInterface>) => {
          state.loading = false;
          state.reports = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllReports.rejected, (state: ReportsInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setReportFilterData } = reportsSlice.actions;
// Export the reducer
export default reportsSlice.reducer;
