/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  ManagementAccountListInterface,
  ManagementStationInterFace,
  ManagementStationRequestInterface,
  ManagementStationResponseInterface,
} from '../../interface/revenue-management.interface';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import revenueApi from '../../utils/api/revenue';

import { setNotificationMessage } from './NotificationManagementSlice';

interface ManageStationInitialProperties {
  stations: ManagementStationInterFace[];
  accountList: { label: string; value: string }[];
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  loading: boolean;
  filter: {};
}

// a thunk to fetch accounts
export const fetchAllStations = createAsyncThunk<
  ManagementStationResponseInterface,
  ManagementStationRequestInterface
>(
  'revenue-management-station/fetchAllStations',
  async (data: ManagementStationRequestInterface, { dispatch }) => {
    try {
      const response = await revenueApi.fetchChargingStations(
        removeEmptyKeys({ ...data, submissionStatus: 'approved' }),
      );
      return response.data;
    } catch (error: any) {
      dispatch(
        setNotificationMessage({
          type: 'error',
          message: error?.response?.data?.message,
          code: error?.response?.data?.code,
          status: true,
        }),
      );
      throw error;
    }
  },
);

export const fetchAccountList = createAsyncThunk<ManagementAccountListInterface>(
  'revenue-management-station/fetchAccountList',
  async (_, { dispatch }) => {
    try {
      const response = await revenueApi.fetchAccountList();
      return response.data;
    } catch (error: any) {
      dispatch(
        setNotificationMessage({
          type: 'error',
          message: error?.response?.data?.message,
          code: error?.response?.data?.code,
          status: true,
        }),
      );
      throw error;
    }
  },
);

// Initial state
const initialState: ManageStationInitialProperties = {
  stations: [],
  accountList: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const revenueSlice = createSlice({
  name: 'revenue-management-station',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setRevenueStationFilterData: (state, action) => {
      state.filter = action.payload;
    },
    /**
     * Function to update single station in redux
     * @param state
     * @param action
     */
    updateSingleStation: (
      state: ManageStationInitialProperties,
      action: PayloadAction<ManagementStationInterFace>,
    ) => {
      const updatedAccount = action.payload; // station to be added at the top
      const accountList = state.stations; // Assign the existing station to another variable

      // Find the index of the updated station by ID
      const index = accountList.findIndex((eachStation) => eachStation.id === updatedAccount.id);

      // If the index found, replace the entire data
      if (index !== -1) {
        accountList[index] = {
          ...updatedAccount,
          revenuePercentage: action.payload.revenuePercentage,
        };
      }
      state.stations = accountList; // Finally update the station list
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllStations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllStations.fulfilled, (state, action) => {
        state.loading = false;
        state.stations = action.payload?.results?.length
          ? action.payload?.results?.map((result) => ({
              ...result,
              id: result._id,
              revenuePercentage: result.revenuePercentage ? Number(result.revenuePercentage) : 0,
            }))
          : [];
        state.limit = action.payload.limit;
        state.page = action.payload.page;
        state.totalPages = action.payload.totalPages;
        state.totalResults = action.payload.totalResults;
      })
      .addCase(fetchAllStations.rejected, (state) => {
        state.loading = false;
        // Handle error if needed
      });
    builder
      .addCase(fetchAccountList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAccountList.fulfilled, (state, action) => {
        state.accountList = action.payload?.results?.length
          ? action.payload?.results?.map((account) => ({
              label: account.accountCustomId,
              value: account.accountCustomId,
            }))
          : [];
      })
      .addCase(fetchAccountList.rejected, (state) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setRevenueStationFilterData, updateSingleStation } = revenueSlice.actions;

// Export the reducer
export default revenueSlice.reducer;
