import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as ExportCSV } from '../../assets/icons/export-csv.svg';
import { ReactComponent as ExportPdf } from '../../assets/icons/export-pdf.svg';
import { ReactComponent as Export } from '../../assets/icons/export.svg';

interface ExportButtonProperties {
  label: string;
  disableHoverEffect?: boolean;
  buttonList: { key: string; onClickFunction: () => void; allow: boolean }[];
  disabled?: boolean;
  loader?: boolean;
}

/**
 * My Button function component
 */
function ExportButton({
  label,
  buttonList,
  disableHoverEffect,
  disabled,
  loader,
}: ExportButtonProperties) {
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [style, setStyle] = useState<any>({});
  /**
   * Click the outside page function
   */
  const handleClickOutside = (event: React.MouseEvent) => {
    if (reference.current === event.target) {
      setOpen(false);
    }
  };

  /**
   * View the export buttons
   * @param event
   */
  const viewButtons = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    let rect;
    const target = event.target as HTMLButtonElement;
    if (target.className === 'back-button') {
      rect = target.getBoundingClientRect();
    } else {
      const closestButton = target.closest('.back-button') as HTMLButtonElement;
      rect = closestButton.getBoundingClientRect();
    }
    setStyle({
      top: rect.top,
      left: rect.left + rect.width + 15,
    });
  };
  return (
    <div className="export-button-wrap">
      <button
        type="button"
        className={`back-button ${disableHoverEffect ? '' : 'on-hover'}`}
        onClick={viewButtons}
        disabled={disabled}>
        <span className="icon">
          <Export />
        </span>
        {label && <span>{label}</span>}
      </button>
      {open &&
        ReactDOM.createPortal(
          <div
            className="export-buttons-container"
            onClick={handleClickOutside}
            role="presentation"
            ref={reference}>
            <div className="export-buttons-list" style={style}>
              {!loader &&
                buttonList.map(
                  (button) =>
                    button.allow && (
                      <div
                        className="export-button-item"
                        onClick={button.onClickFunction}
                        key={button.key}
                        role="presentation">
                        {button.key === 'csv' ? <ExportCSV /> : <ExportPdf />}
                      </div>
                    ),
                )}
              {loader && (
                <div className="progress-bar">
                  <span className="bar">
                    <span className="progress" />
                  </span>
                </div>
              )}
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
}

ExportButton.defaultProps = {
  disabled: false,
  disableHoverEffect: false,
  loader: false,
};

export default ExportButton;
