import getRequest from './base/get';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the report list with pagination
   */
  fetchReports: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/charging-transaction`, {
      ...parameters,
    });
  },
  /**
   * Fetch the report details
   */
  fetchReportDetails: (): Promise<any> => {
    return getRequest(`${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/charging-transaction/charging-transaction-revenue-details`, {});
  },
  /**
   * export the report list
   */
  exportReports: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/charging-transaction/export`, {
      ...parameters,
    });
  },
};
