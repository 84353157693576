import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as EditIcon } from '../../../assets/icons/edit-pencil.svg';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import { FilterRevenueInterFace, ManagementStationInterFace } from '../../../interface/revenue-management.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import {
  fetchAllStations,
  updateSingleStation,
  setRevenueStationFilterData,
} from '../../../redux/slices/RevenueManagementStationSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import revenueApi from '../../../utils/api/revenue';

import FilterRevenue from './filter-station-management';
import RevenueManagementToggle from './RevenueManageToggle';

interface ModalState {
  status: boolean;
  title: string;
  header: boolean;
  content: JSX.Element;
  rightCorner: string;
}

interface ManagementStationRevenueProperties {
  currentPage: string;
  selectedAccountId: string | number;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}

interface EditPercentageProperties {
  id: string;
  value: number;
}

/**
 * ManagementStationRevenue page function component
 */
function ManagementStationRevenue(properties: ManagementStationRevenueProperties) {
  const { currentPage, setCurrentPage, selectedAccountId } = properties;

  const dispatch = useAppDispatch();
  const inputReference = useRef<HTMLInputElement>(null);
  const { stations, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.revenueStation,
  );

  const [editPercent, setEditPercent] = useState<EditPercentageProperties>({
    id: '',
    value: 0,
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Function to handle edit percentage
   */
  const handleEditPercentage = (rowData: ManagementStationInterFace) => {
    setEditPercent({
      id: rowData.id,
      value: rowData.revenuePercentage ? rowData.revenuePercentage : 0,
    });
  };

  /**
   * Function to handle percentage edit
   * @param name
   * @param value
   */
  const handleChangePercentage = (value: string | number) => {
    setEditPercent({
      ...editPercent,
      value: Number(value),
    });
  };

  /**
   * API call to update percentage
   */
  const handleUpdateRevenuePercentage = async () => {
    await revenueApi
      .editAccountPercentage({
        revenuePercentage: editPercent.value.toString(),
        stationId: editPercent.id,
      })
      .then(() => {
        const updatedAccount: ManagementStationInterFace | undefined = stations.find(
          (station) => station.id === editPercent.id,
        );
        if (updatedAccount) {
          dispatch(
            updateSingleStation({
              ...updatedAccount,
              revenuePercentage: editPercent.value,
            }),
          );
        }
        setEditPercent({
          id: '',
          value: 0,
        });
      })
      .catch((error: any) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Function to handle click outside of input field
   * @param event
   */
  const handleOutsideClick = async (event: MouseEvent) => {
    if (editPercent.id !== '') {
      handleUpdateRevenuePercentage();
    }

    if (inputReference.current && !inputReference.current.contains(event.target as Node)) {
      setEditPercent({
        id: '',
        value: 0,
      });
    }
  };

  const column = [
    {
      label: 'Station Name',
      accessorKey: 'name' as keyof ManagementStationInterFace,
      sortAllow: true,
    },
    {
      label: 'Address',
      accessorKey: 'address' as keyof ManagementStationInterFace,
      sortAllow: true,
    },
    {
      label: 'Percentage',
      accessorKey: 'percentage' as keyof ManagementStationInterFace,
      sortAllow: true,
      /**
       * Function to handle input for edit percentage
       * @param rowData
       */
      customData: (rowData: ManagementStationInterFace) => (
        <div className="table-row-data">
          {editPercent.id === rowData.id ? (
            <span>
              <input
                ref={inputReference}
                value={editPercent.value}
                onChange={(event) => handleChangePercentage(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handleUpdateRevenuePercentage();
                  }
                }}
                className="table-row-input-field"
              />
            </span>
          ) : (
            <span>{rowData.revenuePercentage}%</span>
          )}
        </div>
      ),
      columnWidth: 40,
    },
    {
      label: 'Action',
      accessorKey: '' as keyof ManagementStationInterFace,
      sortAllow: false,
      /**
       * Function to handle input for edit percentage
       * @param rowData
       */
      customData: (rowData: ManagementStationInterFace) => (
        <div className="action-button-group">
          <div
            className="action-button-item view-color"
            onClick={() => handleEditPercentage(rowData)}
            role="presentation">
            <EditIcon />
          </div>
        </div>
      ),
      columnWidth: 20,
    },
  ];

  const [modalView, setModalView] = useState<ModalState>({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
  });

  /**
   * Fetch Users list function
   */
  const fetchStationsData = (data: any) => {
    if (!loading) {
      dispatch(fetchAllStations(data));
    }
  };
  /**
   * Handle submit filter function
   * @param value
   */
  const handleSubmitFilter = (filterData: FilterRevenueInterFace) => {
    dispatch(setRevenueStationFilterData(filterData));
    fetchStationsData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };

  /**
   * Filter clear
   */
  const clearFilter = () => {
    dispatch(setRevenueStationFilterData({}));
    fetchStationsData({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * Modal box close function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: true,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * User filter function
   */
  const filterFunction = () => {
    setModalView({
      status: true,
      title: 'Roles Filter',
      header: false,
      content: <FilterRevenue closeModal={closeModal} handleSubmitFilter={handleSubmitFilter} oldValue={filter} />,
      rightCorner: '',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    fetchStationsData(value);
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    const request: any = { ...filter, submissionStatus: 'approved' };
    if (selectedAccountId) {
      request.selectedAccountId = selectedAccountId;
    }
    revenueApi
      .fetchChargingStationsExport(request)
      .then((response) => {
        // Convert data to CSV format
        const headers = ['Station Name', 'Address', 'Percentage'];
        const rows = response.data.map((row: ManagementStationInterFace) => [
          row.name,
          row.address,
          `${row.revenuePercentage ? row.revenuePercentage : 0}%`,
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'revenue-management-station-list.csv');
          } else {
            exportPDF(headers, rows, 'revenue-management-station-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [editPercent]);

  useEffect(() => {
    if (stations.length === 0) {
      fetchStationsData({
        limit,
        page,
      });
    }
  }, []);

  useEffect(() => {
    if (editPercent.id && inputReference.current) {
      inputReference.current.focus(); // Ensure the input is focused after state update
    }
  }, [editPercent.id]);

  return (
    <div className="table-content">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={stations}
        serialNo
        loading={loading}
        page={page}
        limit={limit}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        filterClearFunction={clearFilter}
        filterFunction={filterFunction}
        sizeChangeButtonRequired
        rightTopContent={
          <RevenueManagementToggle currentPage={currentPage} setCurrentPage={setCurrentPage} />
        }
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={stations.length === 0}
                loader={exportLoader}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default ManagementStationRevenue;
