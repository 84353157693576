import React, { useState } from 'react';

import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { AccountFilterInterFace } from '../../../interface/account-management.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
// import roleApi from '../../../utils/api/roles';
import SelectField from '../../../shared-components/select-field/SelectField';

interface AddRoleInterface {
  closeModal: (message: string) => void;
  oldValue: AccountFilterInterFace;
  handleSubmitFilter: (filterData: AccountFilterInterFace) => void;
}
/**
 * Role Add page function component
 */
function FilterAccount({ closeModal, oldValue, handleSubmitFilter }: AddRoleInterface) {
  const [filterDetails, setFilterDetails] = useState<AccountFilterInterFace>(oldValue || {});

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    handleSubmitFilter(filterDetails);
  };

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="accountCustomId"
            inputLabel="Account Id"
            type="text"
            placeholder="Search here..."
            value={filterDetails.accountCustomId || ''}
            onChangeValue={handleInputChange}
            svgIcon={<Search />}
          />
        </div>
        {/* <div className="form-field">
          <SelectField
            name="accountType"
            inputLabel="Account Type"
            placeholder="Select Type"
            onChangeValue={handleInputChange}
            options={[
              {
                label: 'Facility Owner',
                value: 'facility-owner',
              },
            ]}
            selectedValue={filterDetails.accountType || ''}
          />
        </div> */}
        <div className="form-field">
          <SelectField
            name="submissionStatus"
            inputLabel="Verification Status"
            onChangeValue={handleInputChange}
            placeholder="Select Status"
            options={[
              {
                label: 'Verified',
                value: 'approved',
              },
              {
                label: 'Un Verified',
                value: 'rejected',
              },
            ]}
            selectedValue={filterDetails.submissionStatus || ''}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={() => closeModal('')} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterAccount;
