import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAppSelector } from '../../redux/hooks';
import PageTitle from '../../shared-components/page-title/PageTitle';
import TabMenus from '../../shared-components/tab-menus/TabMenus';

import ApprovedPricing from './components/ApprovedPricing';
import PendingPricing from './components/PendingPricing';
import RejectedPricing from './components/RejectedPricing';

/**
 * Dynamic pricing compo
 */
function DynamicPricing() {
  const { totalResults } = useAppSelector(
    (state) => state.pendingPricing,
  );
  const { currentPage } = useParams<{ currentPage: string }>();
  const [openTab, setOpenTab] = useState<number>(0);

  /**
   * Handle the tab change function
   * @param number
   */
  const changePageHandle = (number: number) => {
    setOpenTab(number);
    let pageName = '';
    switch (number) {
      case 0:
        pageName = 'approved';
        break;
      case 1:
        pageName = 'unapproved';
        break;
      case 2:
        pageName = 'rejected';
        break;

      default:
        break;
    }
    window.history.pushState({}, '', `${pageName}`);
  };

  useEffect(() => {
    if (currentPage) {
      let number = 0;
      switch (currentPage) {
        case 'approved':
          number = 0;
          break;
        case 'unapproved':
          number = 1;
          break;
        case 'rejected':
          number = 2;
          break;

        default:
          break;
      }
      setOpenTab(number);
    }
  }, []);

  return (
    <div className="dynamic-pricing-wrap">
      <PageTitle title="Pricing Management" />
      <div className="dynamic-pricing-toggle">
        <TabMenus
          openTab={openTab}
          setOpenTab={changePageHandle}
          menus={[
            {
              name: 'Approved',
            },
            {
              name: 'Pending',
              count: totalResults,
            },
            {
              name: 'Rejected',
            },
          ]}
        />
      </div>
      <div className="table-container">
        {(() => {
          let dynamicPricingTable;
          if (openTab === 0) {
            dynamicPricingTable = <ApprovedPricing />;
          } else if (openTab === 1) {
            dynamicPricingTable = <PendingPricing />;
          } else if (openTab === 2) {
            dynamicPricingTable = <RejectedPricing />;
          }
          return dynamicPricingTable;
        })()}
      </div>
    </div>
  );
}

export default DynamicPricing;
