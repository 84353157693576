import getRequest from './base/get';
import patchRequest from './base/patch';
import postRequest from './base/post';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

interface RoleParameters {
    name: string;
    responsibility: string;
    permissions: (string | number)[];
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchRoles: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/roles`, { ...parameters });
  },
  /**
   * Fetch the Accounts list with pagination
   */
  exportRoles: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/roles/export-roles`, { ...parameters });
  },
  /**
   * Fetch the Accounts list with pagination
   */
  fetchAllRoles: (): Promise<any> => {
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/roles/role-names`, {});
  },
  /**
   * Fetch the permissions list
   */
  fetchAllPermissions: (): Promise<any> => {
    return getRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/roles/permissions-list`, {});
  },
  /**
   * Create the role api call function
   * @param data 
   * @returns 
   */
  createRoles: (data: RoleParameters = {
      name: '',
      responsibility: '',
      permissions: []
  }): Promise<any> => {
    const parameters = { ...data };
    return postRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/roles`, { ...parameters });
  },
  /**
   * Update the role api call function
   * @param data 
   * @returns 
   */
  editRoles: (id: string, data: RoleParameters = {
      name: '',
      responsibility: '',
      permissions: []
  }): Promise<any> => {
    const parameters = { ...data };
    return patchRequest(`${process.env.REACT_APP_API_URL}api/super-admin/v1/roles/${id}`, { ...parameters });
  },
};
