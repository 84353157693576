/**
 * Set the local storage item function
 * @param key 
 * @param value 
 */
export const setLocalStorageItem = (key: string, value: any): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

/**
 * Get the local storage item function
 * @param key 
 * @returns 
 */
export const getLocalStorageItem = (key: string): any | null => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

/**
 * Remove the local storage item function
 * @param key 
 */
export const removeLocalStorageItem = (key: string): void => {
  localStorage.removeItem(key);
};
