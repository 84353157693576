import getRequest from './base/get';

interface QueryParameters {
  limit?: number;
  [key: string]: any;
}

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchPayments: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query, limit: query?.limit || 5 };
    return getRequest(`${process.env.REACT_APP_TRANSACTION_API_URL}api/super-admin/v1/wallet-history`, { ...parameters });
  },
  /**
   * Fetch the Accounts list export
   */
  fetchPaymentsExport: (query: QueryParameters = {}): Promise<any> => {
    const parameters = { ...query };
    return getRequest(`${process.env.REACT_APP_TRANSACTION_API_URL}api/super-admin/v1/wallet-history/export-data`, { ...parameters });
  },
};
