import React, { useState } from 'react';

import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { FilterChargingStationInterFace } from '../../../interface/charging-station.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
// import roleApi from '../../../utils/api/roles';

interface FilterChargingInterface {
  closeModal: () => void;
  handleSubmitFilter: (filterData: FilterChargingStationInterFace) => void;
  oldValue: FilterChargingStationInterFace;
}
/**
 * Role Add page function component
 */
function FilterChargingStation({ closeModal, handleSubmitFilter, oldValue }: FilterChargingInterface) {
  const [filterDetails, setFilterDetails] = useState<FilterChargingStationInterFace>(oldValue || {});

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    handleSubmitFilter(filterDetails);
  };

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="Station Name / Location"
            type="text"
            placeholder="Search here..."
            value={filterDetails?.name || ''}
            onChangeValue={handleInputChange}
            svgIcon={<Search />}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterChargingStation;
