import { ReactComponent as CheckBoxActiveIcon } from '../../assets/icons/radio-active-icon.svg';
import { ReactComponent as CheckBoxIcon } from '../../assets/icons/radio-button-icon.svg';

interface CustomCheckInputProperties {
  inputLabel?: string;
  value: string | number;
  name: string;
  list: { label: string; value: string | number }[];
  onChangeValue: (name: string, value: string | number) => void;
  valueRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
}

// To-do -> This should be re-designed as single checkbox component, instead of mapping over list of items
/**
 * Check box buttons function component
 */
function RadioButton({
  inputLabel,
  name,
  value,
  list,
  onChangeValue,
  valueRequired,
  error,
  errorMessage,
}: CustomCheckInputProperties) {
  /**
   * check box button handle function
   */
  const handleValueChange = (changeValue: string | number) => {
    onChangeValue(name, changeValue);
  };

  return (
    <div className="check-box-button-wrap">
      {inputLabel && (
        <p className={`input-label ${error && 'error'}`}>
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-check-box">
        <div className="check-box-list">
          {list.map((checkbox) => (
            <div
              className="check-box-item"
              onClick={() => handleValueChange(checkbox.value)}
              role="presentation">
              <div className="check-box-icon">
                {value === checkbox.value ? <CheckBoxActiveIcon /> : <CheckBoxIcon />}
              </div>
              <div className="check-box-label">{checkbox.label}</div>
            </div>
          ))}
        </div>
      </div>
      {error && <p className="custom-input-error-message">{errorMessage}</p>}
    </div>
  );
}

RadioButton.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  error: false,
  errorMessage: '',
};

export default RadioButton;
