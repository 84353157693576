import { ReactComponent as ActivityIcon } from '../../../assets/icons/activety-icon.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-icon.svg';
import profile from '../../../assets/images/profiles/profile.jpg';
import { UserCardDetails } from '../../../interface/users-management.interface';
import DropDownMenu from '../../../shared-components/drop-down/DropDownMenu';

/**
 * User Card Body page function component
 */
function UserCardBody({ userDetails, activeLog, menuList, activeLogActive }: UserCardDetails) {
  return (
    <div className="card-body">
      <div className="card-header">
        <div className="card-header-item-image">
          <img src={profile} alt="profile" />
        </div>
        <div className="card-header-item-title">
          <div className="title">{userDetails.name}</div>
          <div className="sub-title">{userDetails.accountId}</div>
        </div>
        <div className="card-header-item-tag">
          {userDetails.status ? (
            <div className="tag-success-label">
              <DotCircle /> Active
            </div>
          ) : (
            <div className="tag-danger-label">
              <DotCircle /> Inactive
            </div>
          )}
        </div>
        <div className="card-header-item-icon">
          {menuList.filter((value) => value.allow).length > 0 && (
            <DropDownMenu
              list={menuList.map((value) => ({
                ...value,
                id: userDetails.id,
              }))}
              icon={<MoreIcon />}
            />
          )}
        </div>
      </div>
      <div className="card-body-content">
        <div className="user-details">
          <div className="title">Role</div>
          <div className="value">{userDetails.roleName}</div>
        </div>
        <div className="user-details">
          <div className="title">Date</div>
          <div className="value">{userDetails.createdAt}</div>
        </div>
        <div className="user-details">
          <div className="title">Content</div>
          <div className="value">{userDetails.email}</div>
        </div>
      </div>
      {activeLogActive && (
        <div className="card-body-footer">
          <div className="button-wrap">
            <button
              type="button"
              className="back-button on-hover"
              onClick={(event: React.MouseEvent) => activeLog(userDetails.id, event)}>
              <span>
                <ActivityIcon />
              </span>
              <span>Activity Log</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserCardBody;
