import { Loader } from '@googlemaps/js-api-loader';
import React, { createContext, useContext, useState, useMemo, useEffect, ReactNode } from 'react';

interface GoogleMapsContextProperties {
  googleMaps: typeof google | null;
  isLoaded: boolean;
}

const GoogleMapsContext = createContext<GoogleMapsContextProperties | undefined>(undefined);

interface GoogleMapsProviderProperties {
  children: ReactNode;
  apiKey: string;
}

/**
 * Google map's API loader context
 * @param param0
 */
export function GoogleMapsProvider({ children, apiKey }: GoogleMapsProviderProperties) {
  const [googleMaps, setGoogleMaps] = useState<typeof google | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const loader = new Loader({
      apiKey,
      libraries: ['places', 'geometry'], // Include other libraries if needed
    });

    loader
      .load()
      .then((google) => {
        setGoogleMaps(google);
        setIsLoaded(true);
      })
      .catch((error) => {
        console.error('Error loading Google Maps:', error);
      });
  }, [apiKey]);

  const contextValue = useMemo(() => ({ googleMaps, isLoaded }), [googleMaps, isLoaded]);

  return <GoogleMapsContext.Provider value={contextValue}>{children}</GoogleMapsContext.Provider>;
}

/**
 * useGoogleMaps - context
 */
export const useGoogleMaps = (): GoogleMapsContextProperties => {
  const context = useContext(GoogleMapsContext);
  if (!context) {
    throw new Error('useGoogleMaps must be used within a GoogleMapsProvider');
  }
  return context;
};
