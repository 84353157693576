// import L from 'leaflet';
import { GoogleMap, InfoWindow, Marker, MarkerClusterer } from '@react-google-maps/api';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-icon.svg';
import AlertStationIcon from '../../../assets/icons/dashboard/alert-station-locator.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/dashboard/right-arrow.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/dashboard/user-icon.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location-icon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg';
import { brandIcons, brandImage, metaValues } from '../../../config/branding';
import { useGoogleMaps } from '../../../contexts/google-map-context/GoogleMapContent';
import { useAppDispatch } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import AutoCompleteSelectField from '../../../shared-components/auto-complete-select-field/AutoCompleteSelectField';
import BatteryLoader from '../../../shared-components/battery-loader/BatteryLoader';
import MyButton from '../../../shared-components/button/Button';
import ConvertUTCtoHHMM from '../../../shared-functions/ConvertUTCtoHHMM';
import dashboardApi from '../../../utils/api/dashboard';

interface StationProperties {
  id: string;
  position: { lat: number; lng: number }; // Ensures two elements: [latitude, longitude]
  alert: boolean;
  name: string;
  address: string;
}

/**
 * check the lat and lng is valid or not
 * @param position
 * @returns
 */
const validatePosition = (position: { lat: any; lng: any }) => {
  const lat = parseFloat(position.lat);
  const lng = parseFloat(position.lng);
  return !Number.isNaN(lat) && !Number.isNaN(lng) ? { lat, lng } : null;
};

/**
 * Map component to station location
 */
function DashboardMap({ fetchAllDetails }: { fetchAllDetails: (stationId: string) => void }) {
  const { isLoaded } = useGoogleMaps();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const mapReference = useRef<google.maps.Map | null>(null);
  const [showCards, setShowCards] = useState<boolean>(true);
  const [searchStation, setSearchStation] = useState<string | number>('');

  const [singleStationDetails, setSingleStationDetails] = useState<any>({
    _id: '',
    name: '',
    address: '',
    latitude: '',
    longitude: '',
    opensAt: '', // ISO 8601 string format
    closesAt: '', // ISO 8601 string format
    isAvailable: false,
    connectors: [], // Array of connector types
    totalChargingPoints: 0,
    clientsOnStation: 0,
  });

  const [selectedMarker, setSelectedMarker] = useState<StationProperties | null>(null);
  const [stationLocation, setStationLocation] = useState<StationProperties[]>([]);
  const [center, setCenter] = useState({
    lat: metaValues.latLong.lat, // Example: San Francisco
    lng: metaValues.latLong.lng, // Example: San Francisco
  });

  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  /**
   * Function to fetch single station details
   */
  const handleFetchSingleStation = async (marker: StationProperties) => {
    const validPosition = validatePosition(marker.position);
    if (validPosition) {
      setCenter(validPosition);
      setSelectedMarker({ ...marker, position: validPosition });
      // setShowCards(false);
      fetchAllDetails(marker.id);
      dashboardApi
        .getChargingStationDetails(marker.id)
        .then((response) => {
          if (response.data.length) {
            setSingleStationDetails(response.data?.[0]);
          }
        })
        .catch((error) => {
          dispatch(
            setNotificationMessage({
              message: error?.response?.data?.message,
              status: true,
              type: 'error',
              code: error?.response?.data?.code,
            }),
          );
        });
    }
  };

  /**
   * handle the close modal box in map
   */
  const closeMapModal = () => {
    setSelectedMarker(null);
    fetchAllDetails('');
  };
  /**
   * Function to filter the station list by name or address
   * @param name
   * @param value
   */
  const handleFilterByStationDetails = (name: string, value: string | number) => {
    setSearchStation(value);

    const matchedStation: StationProperties | undefined = stationLocation.find(
      (station: StationProperties) => station.name === value,
    );

    if (matchedStation) {
      handleFetchSingleStation(matchedStation);
    }
  };

  /**
   * Fetch the all charging station list location in map
   */
  const fetchAllChargingStationList = () => {
    dashboardApi
      .fetchAllChargingStationList()
      .then((response) => {
        const stationList: StationProperties[] = response?.data?.map((station: any) => ({
          id: station._id,
          position: { lat: station.latitude, lng: station.longitude },
          alert: station.isAlert,
          name: station.name,
          address: station.address,
        }));
        setStationLocation(stationList.filter((station) => station.address !== undefined));
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    fetchAllChargingStationList();
  }, []);
  return (
    <div className="dashboard-map-wrap" onMouseLeave={() => setShowCards(true)}>
      {/* Absolute search box */}
      <div className="map-search-wrap" role="presentation" onClick={() => setShowCards(false)}>
        <AutoCompleteSelectField
          inputLabel=""
          placeholder="Search"
          name="search"
          selectedValue={searchStation}
          onChangeValue={handleFilterByStationDetails}
          svgIcon={<SearchIcon />}
          options={stationLocation.map((station) => ({
            label: `${station.name} (${station.address})`,
            value: station.name,
          }))}
        />
      </div>
      <div className="dashboard-map-container">
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={7}
            onLoad={(map) => {
              mapReference.current = map; // Assign the map instance to the reference
            }}>
            {/* Marker Clusterer for grouping nearby markers */}
            <MarkerClusterer>
              {(clusterer) => (
                <>
                  {stationLocation.map((marker) => {
                    const validPosition = validatePosition(marker.position);
                    return (
                      validPosition && (
                        <Marker
                          key={marker.id}
                          position={validPosition}
                          clusterer={clusterer}
                          icon={
                            marker.alert
                              ? {
                                  url: AlertStationIcon,
                                  scaledSize: new window.google.maps.Size(76, 47), // Width, Height
                                }
                              : {
                                  url: brandIcons.stationLocator,
                                  scaledSize: new window.google.maps.Size(44, 44), // Width, Height
                                }
                          }
                          onClick={() => handleFetchSingleStation(marker)} // Open popup on marker click
                        />
                      )
                    );
                  })}
                </>
              )}
            </MarkerClusterer>
            {/* Popup for marker */}
            {selectedMarker && (
              <InfoWindow position={selectedMarker.position} onCloseClick={closeMapModal}>
                {selectedMarker.alert ? (
                  <div className="map-station-details-wrap alert-wrap">
                    <div className="map-station-details-container">
                      <span>
                        <AlertIcon />
                      </span>
                      <p>Station Performance Alerts</p>
                    </div>
                  </div>
                ) : (
                  <div className="map-station-active-container">
                    <div className="map-station-details">
                      <div className="details-header">
                        <div className="details-header-image-wrap">
                          <img src={brandImage.chargingDevice} alt="" className="details-header-image" />
                        </div>
                        <div className="name-and-status">
                          <h2>{singleStationDetails.name}</h2>
                          {singleStationDetails.isAvailable ? (
                            <div className="status-wrap active">
                              <div className="status-point" />
                              <p>Active</p>
                            </div>
                          ) : (
                            <div className="status-wrap in-active">
                              <div className="status-point in-active" />
                              <p>Inactive</p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="address-details">
                        <div className="place-with-icon">
                          <span />
                          {/* To-do - need to show country here or need to confirm */}
                          <span>
                            <LocationIcon />
                          </span>
                        </div>
                        <p>{singleStationDetails.address}</p>
                      </div>
                      <div className="user-details">
                        <span>
                          <UserIcon />
                        </span>
                        <span className="count">{singleStationDetails.clientsOnStation}</span>
                        <span className="message">Client on station</span>
                      </div>
                    </div>
                    <div className="map-station-center-border" />
                    <div className="map-station-availability-wrap">
                      <div className="availability-status-wrap">
                        <div className="each-availability">
                          <span>
                            <brandIcons.dashboardClock />
                          </span>
                          <p>{`${ConvertUTCtoHHMM(singleStationDetails.opensAt)} - ${ConvertUTCtoHHMM(singleStationDetails.closesAt)}`}</p>
                        </div>
                        <div className="each-availability">
                          <span>
                            <brandIcons.dashboardGas />
                          </span>
                          <p>{singleStationDetails.totalChargingPoints} Charging Points</p>
                        </div>
                        <div className="each-availability">
                          <span>
                            <brandIcons.dashboardCharge />
                          </span>
                          <p>{singleStationDetails.connectors.join(', ')}</p>
                        </div>
                      </div>
                      <div className="view-more-button">
                        <MyButton
                          label="View more"
                          buttonType="submit"
                          disableHoverEffect
                          svgIcon={<RightArrow />}
                          onClickFunc={() => navigate('../charging-station/approved')}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </InfoWindow>
            )}
          </GoogleMap>
        ) : (
          <div className="dashboard-map-loader">
            <BatteryLoader />
          </div>
        )}
        {/* Absolute background color */}
        {showCards && <div className="absolute-background" />}
      </div>
    </div>
  );
}

export default DashboardMap;
