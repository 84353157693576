import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import loginBanner from '../../assets/images/login/login-banner.png';
// import sample from '../../assets/videos/background.mp4';

import { ReactComponent as LoginBanner } from '../../../assets/icons/login-banner.svg';
import { useAppDispatch } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import { getLocalStorageItem, removeLocalStorageItem } from '../../../shared-functions/LocalStorage';
import {
  passwordValidation,
} from '../../../shared-functions/Validation';
import authApi from '../../../utils/api/auth';

/**
 * Login page function component
 */
function NewPasswordChange() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const passwordResetDetails = getLocalStorageItem('passwordReset');
  const [credentials, setCredentials] = useState<{
    password: string;
    rePassword: string;
  }>({
    rePassword: '',
    password: '',
  });
  const [submitOpen, setSubmitOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  /**
   * Input field change function
   * @param name
   * @param changeValue
   */
  const handleCredentialChange = (name: string, changeValue: string | number) => {
    setCredentials({
      ...credentials,
      [name]: changeValue,
    });
  };

  /**
   * handle submit function
   */
  const submitFunction = () => {
    setSubmitOpen(true);
    if (
      passwordValidation(credentials.password) &&
      credentials.password === credentials.rePassword
    ) {
      setDisabled(true);
      const request = {
        countryCode: passwordResetDetails.countryCode,
        emailOrPhoneNumber: passwordResetDetails.emailOrPhoneNumber,
        otp: passwordResetDetails.otp,
        newPassword: credentials.password,
      };
      authApi
        .createPassword(request)
        .then(() => {
          removeLocalStorageItem('passwordReset')
          setDisabled(false);

          // navigate('../ev-admin/dashboard');
          navigate('../ev-admin/account');
        })
        .catch((error) => {
          setDisabled(false);
          dispatch(
            setNotificationMessage({
              message: error?.response?.data?.message,
              status: true,
              type: 'error',
              code: error?.response?.data?.code,
            }),
          );
        });
    }
  };
  return (
    <div className="login-wrap">
      {/* <video className='videoTag' autoPlay loop muted>
        <source src={sample} type='video/mp4' />
      </video> */}
      <div className="login-banner-container">
        {/* <img src={loginBanner} alt="banner" className="login-banner" /> */}
        <LoginBanner />
      </div>
      <div className="login-form-container">
        <div className="login-form-with-border">
          <div className="forget-password-form">
            <h1>Create Password</h1>
            <div className="password-container">
              <CustomInput
                inputLabel="Create New Password"
                type="password"
                placeholder="Enter password"
                name="password"
                value={credentials.password}
                onChangeValue={handleCredentialChange}
                error={submitOpen && !passwordValidation(credentials.password)}
                errorMessage="Password is Invalid"
              />
            </div>
            <div className="password-container">
              <CustomInput
                inputLabel="Confirm Password"
                type="password"
                placeholder="Enter password"
                name="rePassword"
                value={credentials.rePassword}
                onChangeValue={handleCredentialChange}
                error={submitOpen && credentials.rePassword !== credentials.password}
                errorMessage="Confirm Password is not same for Password"
              />
            </div>
            <MyButton
              label="Submit"
              buttonType="submit"
              onClickFunc={submitFunction}
              disabled={disabled}
            />
          </div>
          {/* <div className="gradient-border">
            <div className="white-bg-color" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default NewPasswordChange;
