import React, { useEffect, useState } from 'react';

import { ReactComponent as NextArrow } from '../../assets/icons/next-arrow.svg';
import { ReactComponent as PreviousArrow } from '../../assets/icons/prev-arrow.svg';
import DefaultApiParameters from '../../enum/DefaultApiParameters';
import { ApiRequest } from '../../interface/common.interface';
import SelectFiled from '../select-field/SelectField';

interface PaginationProperties {
  active: number;
  setActive: (value: ApiRequest) => void;
  size: number;
  limit?: number;
  totalResults?: number;
}

/**
 * Pagination function component
 */
function Pagination({ active, size, setActive, limit = 0, totalResults = 0 }: PaginationProperties) {
  const step: number = 1;
  const showingNumbers = 3; // step * 2 + 1
  const startNumber: number = 2;
  const [startArrayNumber, setStartArrayNumber] = useState<number>(step);
  const [needStartDots, setNeedStartDots] = useState<boolean>(false);
  const [needEndDots, setNeedEndDots] = useState<boolean>(false);

  /**
   * Handle the page change function
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setActive({
      page: pageNumber,
      limit,
    });
  };

  /**
   * Change the page limit function
   * @param val 
   */
  const handleChange = (name: string, value: number) => {
    setActive({
      page: DefaultApiParameters.page,
      limit: value,
    });
  }

  /**
   * Handle the arrow click the function
   * @param pageNumber
   */
  const handleArrowClick = (pageNumber: number, type: 'prev' | 'next') => {
    if (type === 'prev' && active > 1) {
      setActive({
        page: pageNumber,
        limit,
      });
    } else if (type === 'next' && active < size) {
      setActive({
        page: pageNumber,
        limit,
      });
    }
  };

  type DropdownOptionInfo = {
    label: string;
    value: string | number;
  };

  const dropDownOption: DropdownOptionInfo[] = [
    {
      label: '5',
      value: 5,
    },
    {
      label: '7',
      value: 7,
    },
    {
      label: '10',
      value: 10,
    },
  ];

  useEffect(() => {
    if (active > step) {
      setStartArrayNumber(active - step);
      setNeedStartDots(active > step + startNumber);
    } else {
      setStartArrayNumber(step);
      setNeedStartDots(false);
    }

    if (size > showingNumbers) {
      setNeedEndDots(size > active + step + 1);

      if (size < active + step + 1) {
        setStartArrayNumber(size - showingNumbers);
      }
    }
  }, [active, step, size, startNumber, showingNumbers]);

  return (
    <div className="pagination-wrap">
      <div className="limit-container">
        <span>Items Per Page</span>
        <div className="limit-select-box">
          <SelectFiled
            inputLabel=""
            name="dropDown"
            selectedValue={limit}
            onChangeValue={handleChange}
            options={dropDownOption}
            // valueRequired
            // readOnly
          />
        </div>
        <span>of {totalResults}</span>
      </div>
      <ul className="pagination-container">
        <li
          className={`pagination-arrow ${active > 1 ? 'active-arrow' : 'disable-arrow'}`}
          role="presentation"
          onClick={() => handleArrowClick(active - 1, 'prev')}>
          <PreviousArrow />
        </li>

        {size > showingNumbers + startNumber ? (
          <>
            <li
              role="presentation"
              onClick={(event) => handlePageChange(Number(event.currentTarget.textContent))}
              className={`pagination-item ${active === 1 ? 'active-item' : ''}`}>
              1
            </li>

            {needStartDots && (
              <div className="pagination-dots">
                <span>...</span>
              </div>
            )}

            {Array.from({ length: showingNumbers }, (_, index) => {
              const contentNumber = needStartDots ? startArrayNumber + index : startNumber + index;
              return (
                <li
                  key={index}
                  className={`pagination-item ${active === contentNumber ? 'active-item' : ''}`}
                  role="presentation"
                  onClick={() => handlePageChange(contentNumber)}>
                  {contentNumber}
                </li>
              );
            })}

            {needEndDots && (
              <div className="pagination-dots">
                <span>...</span>
              </div>
            )}

            <li
              className={`pagination-item ${active === size ? 'active-item' : ''}`}
              role="presentation"
              onClick={() => handlePageChange(size)}>
              {size}
            </li>
          </>
        ) : (
          Array.from({ length: size }, (_, index) => (
            <li
              key={index}
              className={`pagination-item ${active === index + 1 ? 'active-item' : ''}`}
              role="presentation"
              onClick={() => handlePageChange(index + 1)}>
              {index + 1}
            </li>
          ))
        )}

        <li
          className={`pagination-arrow ${active < size ? 'active-arrow' : 'disable-arrow'}`}
          role="presentation"
          onClick={() => handleArrowClick(active + 1, 'next')}>
          <NextArrow />
        </li>
      </ul>
    </div>
  );
}

Pagination.defaultProps = {
  limit: 0,
  totalResults: 0,
};

export default Pagination;
