import axios, { AxiosResponse } from 'axios';

import defaultHeaders from './default-headers';

/**
 * Patch request function
 */
const deleteRequest = async (
  url: string,
): Promise<AxiosResponse<any>> => {
  try {
    const authToken = defaultHeaders();
    const headers = {
      ...(authToken && authToken), // Add Authorization header if token exists
    };

    return await axios.delete(url, { headers });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default deleteRequest;
