import axios, { AxiosResponse } from 'axios';

import defaultHeaders from './default-headers';

interface Parameters {
  [key: string]: any;
}

/**
 * Get Request axios function
 */
const getRequest = async (url: string, parameters?: Parameters): Promise<AxiosResponse<any>> => {
  try {
    const authToken = defaultHeaders();
    const headers = {
      ...(authToken && authToken), // Add Authorization header if token exists
    };

    return await axios.get(url, { params: parameters, headers });
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default getRequest;
