import getRequest from './base/get';

export default {
  /**
   * Fetch the Accounts list with pagination
   */
  fetchDashboardCardDetails: (stationId: string = ''): Promise<any> => {
    return getRequest(
      `${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/dashboard/total-dashboard-cards`,
      stationId ? { stationId } : {},
    );
  },
  /**
   * Fetch the all charging station list location in map
   */
  fetchAllChargingStationList: (): Promise<any> => {
    return getRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/charging-station/charging-stations-map`,
      {},
    );
  },
  /**
   * Fetch the all charging station list location in map
   */
  getChargingStationDetails: (id: string): Promise<any> => {
    return getRequest(
      `${process.env.REACT_APP_COMMON_API_URL}api/super-admin/v1/charging-station/charging-stations-map/${id}`,
      {},
    );
  },
  /**
   * Fetch the all charging station list location in map
   */
  getRevenueAndPowerConsumedDetails: (type: string, stationId: string = ''): Promise<any> => {
    const request: any = { type };
    if (stationId) {
      request.stationId = stationId;
    }
    return getRequest(
      `${process.env.REACT_APP_REPORTING_API_URL}api/super-admin/v1/charging-transaction/revenue-and-power-consumed-details`,
      request,
    );
  },
};
