import React, { useState } from 'react';

import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { PaymentFilterInterFace } from '../../../interface/payment-management.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomDateRange from '../../../shared-components/custom-date-range/CustomDateRange';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import SelectFiled from '../../../shared-components/select-field/SelectField';

interface AddRoleInterface {
  closeModal: () => void;
  handleSubmitFilter: (value: PaymentFilterInterFace) => void;
  oldValue: PaymentFilterInterFace
}
/**
 * Role Add page function component
 */
function FilterAccount({ closeModal, handleSubmitFilter, oldValue }: AddRoleInterface) {
  const [filterDetails, setFilterDetails] = useState<PaymentFilterInterFace>(oldValue || {
    name: '',
    status: '',
    startDate: '',
    endDate: ''
  });

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    handleSubmitFilter(filterDetails)
  };

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="User Name"
            type="text"
            placeholder="Search here..."
            value={filterDetails.name || ''}
            onChangeValue={handleInputChange}
            svgIcon={<Search />}
          />
        </div>
        <div className="form-field">
          <CustomDateRange
            onChangeValue={handleInputChange}
            inputLabel="Date"
            value1={filterDetails.startDate || ''}
            value2={filterDetails.endDate || ''}
            name1="startDate"
            name2="endDate"
          />
        </div>
        <div className="form-field">
          <SelectFiled
            name="status"
            inputLabel="Payment Status"
            placeholder="All"
            onChangeValue={handleInputChange}
            options={[
              {
                label: 'Success',
                value: 'success',
              },
              {
                label: 'Failed',
                value: 'failed',
              },
              {
                label: 'Pending',
                value: 'pending',
              },
            ]}
            selectedValue={filterDetails.status || ''}
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterAccount;
