import React, { useEffect, useState } from 'react';

import { ReactComponent as CheckCircle } from '../../../assets/icons/check-circle.svg';
import { ReactComponent as CloseCircle } from '../../../assets/icons/close-circle.svg';
import { ReactComponent as DocumentFile } from '../../../assets/icons/document-file.svg';
import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-icon.svg';
import profile from '../../../assets/images/profiles/profile.jpg';
import {
  AccountAddState,
  AccountsInterFace,
  MultiMediaData,
} from '../../../interface/account-management.interface';
import { useAppDispatch } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import BatteryLoader from '../../../shared-components/battery-loader/BatteryLoader';
import MyButton from '../../../shared-components/button/Button';
import ConfirmBox from '../../../shared-components/modal-box/ConfirmBox';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import accountApi from '../../../utils/api/account';

import RejectAccount from './reject-account';

/**
 * Open the document in new tap function
 */
const openPdf = (pdfUrl: string) => {
  window.open(pdfUrl, '_blank');
};

/**
 * View Account function component
 */
function ViewAccount({
  accountDetails = null,
  refersData,
}: {
  accountDetails?: AccountsInterFace | null;
  refersData: (message: string) => void;
}) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [accountDetail, setAccountDetails] = useState<AccountAddState>({
    status: '',
    name: '',
    governmentId: [],
    governmentIdOld: [],
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    countryCode: '91',
  });
  const [modalView, setModalView] = useState({
    status: false,
    title: '',
    content: <div />,
    header: false,
    rightCorner: '',
  });
  const [confirmBox, setConfirmBox] = useState({
    status: false,
    title: '',
    subTitle: '',
    /**
     * Demo function
     */
    conformSubmit: () => {},
  });

  /**
   * Fetch the account details in api function
   * @param id
   */
  const fetchData = (id: string) => {
    accountApi
      .fetchOneAccounts(id)
      .then((response) => {
        setLoading(false);
        setAccountDetails({
          status: '',
          name: response.data?.name,
          governmentIdOld: [response.data?.governmentId],
          governmentId: [],
          email: response.data?.email,
          phone: response.data?.phoneNumber,
          password: '',
          rePassword: '',
          countryCode: '',
          profileImage: response.data?.profileImage,
        });
      })
      .catch((error) => {
        setLoading(false);
        refersData('');
        dispatch(setNotificationMessage({
          message: error?.response?.data?.message,
          status: true,
          type: 'error',
          code: error?.response?.data?.code,
        }));
      });
  };
  /**
   * Approved the account function
   */
  const confirmApprovedAccount = () => {
    if (accountDetails?.id) {
      accountApi
        .approvedAccounts(accountDetails.tenantId, { submissionStatus: 'approved' })
        .then(() => {
          refersData('Account Approved Successfully...');
          setConfirmBox((previous) => ({ ...previous, status: false }));
          setModalView((previous) => ({ ...previous, status: false }));
        })
        .catch((error) => {
          dispatch(setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }));
        });
    }
  };

  /**
   * Approved the account function
   */
  const approvedAccount = () => {
    setConfirmBox({
      status: true,
      title: 'Are you sure want to verify this account?',
      subTitle: 'This will allow facility owner account to add facilities & devices',
      conformSubmit: confirmApprovedAccount,
    });
  };
  /**
   * Approved the account function
   */
  const ConfirmRejectAccount = (value: any) => {
    if (accountDetails?.id) {
      accountApi
        .approvedAccounts(accountDetails.tenantId, {
          reason: value.reason,
          submissionStatus: 'rejected',
        })
        .then(() => {
          refersData('Account Rejected Successfully...');
          setConfirmBox((previous) => ({ ...previous, status: false }));
          setModalView((previous) => ({ ...previous, status: false }));
        })
        .catch((error) => {
          dispatch(setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }));
        });
    }
  };
  /**
   * Approved the account function
   */
  const rejectedAccount = () => {
    setModalView({
      status: true,
      title: '',
      content: (
        <RejectAccount
          accountDetails={accountDetail}
          closeModal={() => setModalView((previous) => ({ ...previous, status: false }))}
          conformSubmit={ConfirmRejectAccount}
        />
      ),
      header: false,
      rightCorner: '',
    });
  };

  useEffect(() => {
    if (accountDetails?.id) {
      fetchData(accountDetails?.tenantId);
    }
  }, []);

  return loading ? (
    <div className="add-account-form loader"><BatteryLoader /></div> 
  ) : (
    <div className="add-account-form">
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setModalView((previous) => ({ ...previous, status: false }))}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <ConfirmBox
        open={confirmBox.status}
        title={confirmBox.title}
        subTitle={confirmBox.subTitle}
        conformSubmit={confirmBox.conformSubmit}
        closeModal={() => setConfirmBox((previous) => ({ ...previous, status: false }))}
      />
      <div className="view-body">
        <div className="profile-details">
          <div className="profile-image">
            <img src={accountDetail.profileImage?.signedUrl || profile} alt={accountDetail.profileImage?.fileName} />
          </div>
          <div className="profile-user">
            <div className="profile-title-1">{accountDetail.name}</div>
            <div className="profile-title-2">{accountDetail.email}</div>
          </div>
        </div>
        <div className="tab-body">
          <div className="tab-contents">
            <div className="tab-item">
              <div className="item-content">
                <div className="documents-session">
                  <div className="title">Government ID</div>
                  <div className="document-list">
                    {accountDetail.governmentIdOld &&
                      accountDetail.governmentIdOld.map((document: MultiMediaData) => (
                        <div className="document-item">
                          <div className="icon">
                            <DocumentFile />
                          </div>
                          <div className="name">{document.fileName}</div>
                          <div
                            className="icon"
                            onClick={() => openPdf(document.signedUrl)}
                            role="presentation">
                            <EyeIcon />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {accountDetails?.status === 'pending' && (
          <div className="form-field table-bottom-button-list form-bottom-button">
            <div className="table-button-item">
              <MyButton
                label="Reject"
                svgIcon={<CloseCircle />}
                buttonType="error"
                onClickFunc={rejectedAccount}
              />
            </div>
            <div className="table-button-item">
              <MyButton
                label="Verify"
                buttonType="submit"
                svgIcon={<CheckCircle />}
                onClickFunc={approvedAccount}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

ViewAccount.defaultProps = {
  accountDetails: {},
};

export default ViewAccount;
