import currentTimeZone from "../enum/timeZone";

/**
 * Function to convert UTC time to date in the system's local time zone
 * @param utcString - The UTC string to convert
 * @returns - The date in the local time zone
 */
function ConvertUTCtoDate(utcString: string): string {  
  const date = new Date(utcString);

  const options: Intl.DateTimeFormatOptions = {
    timeZone: currentTimeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour12: false, // Not necessary for the date itself, but keeps it consistent
  };

  // Format the date to local time in the specified time zone
  const dateString = new Intl.DateTimeFormat('en-US', options).format(date);

  return dateString;
}

export default ConvertUTCtoDate;
