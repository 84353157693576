import postRequest from './base/post';

export default {
  /**
   * Super admin auth login function
   * @param data
   * @param onUploadProgress Optional callback for tracking upload progress
   */
  login: (data: any = {}): Promise<any> => {
    return postRequest(`${process.env.REACT_APP_AUTH_API_URL}api/super-admin/v1/auth/login`, data);
  },
  /**
   * Super admin forgot password function
   * @param data
   * @param onUploadProgress Optional callback for tracking upload progress
   */
  forgotPassword: (data: any = {}): Promise<any> => {
    return postRequest(`${process.env.REACT_APP_AUTH_API_URL}api/super-admin/v1/auth/forgot-password`, data);
  },
  /**
   * Verify reset password OTP API
   * @param data
   */
  resetPasswordOTPVerify: (data: any = {}): Promise<any> => {
    return postRequest(
      `${process.env.REACT_APP_AUTH_API_URL}api/super-admin/v1/auth/forgot-password-otp-verify`,
      data,
    );
  },
  /**
   * Verify reset password OTP API
   * @param data
   */
  createPassword: (data: any = {}): Promise<any> => {
    return postRequest(
      `${process.env.REACT_APP_AUTH_API_URL}api/super-admin/v1/auth/reset-password`,
      data,
    );
  },
  /**
   * Logout the auth function
   * @returns 
   */
  logout: (data: any = {}): Promise<any> => {
    return postRequest(`${process.env.REACT_APP_AUTH_API_URL}api/super-admin/v1/auth/logout`, { ...data });
  },
};
