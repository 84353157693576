import React, { useState } from 'react';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete.svg';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';

/**
 * Reject reason component
 * @param stationId
 * @returns
 */
function RejectReason({
  stationId,
  onSubmit,
  onClose,
}: {
  stationId: string;
  onSubmit: (id: string, reason: string | number) => void;
  onClose: () => void;
}) {
  const [rejectReason, setRejectReason] = useState<string | number>('');

  /**
   * Function to change the reason
   * @param name
   * @param value
   */
  const handleReasonChange = (name: string, value: string | number) => {
    setRejectReason(value);
  };

  return (
    <div className="reject-pricing-reason-wrap">
      <div className="reject-pricing-reason-form">
        <CustomInput
          type="text"
          placeholder="Enter the reason"
          value={rejectReason}
          name="reason"
          onChangeValue={handleReasonChange}
          inputLabel="Enter the valid reason"
        />
      </div>
      <div className="reject-reason-button-wrap">
        <MyButton label="Cancel" buttonType="back" onClickFunc={onClose} />
        <MyButton
          label="Reject"
          buttonType="error"
          svgBackIcon={<DeleteIcon />}
          onClickFunc={() => onSubmit(stationId, rejectReason)}
        />
      </div>
    </div>
  );
}

export default RejectReason;
