import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ChargingStationInterFace } from '../../interface/charging-station.interface';
import { useAppSelector } from '../../redux/hooks';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import TabMenus from '../../shared-components/tab-menus/TabMenus';

import ApprovedList from './components/approved-list';
import RejectedList from './components/rejected-list';
import UnApprovedList from './components/unapproved-list';
import ViewChargingStation from './components/view-charging-station';

/**
 * Charging Station Management function component
 */
function ChargingStationManagement() {
  const { currentPage } = useParams<{ currentPage: string }>();
  const { totalResults } = useAppSelector(
    (state) => state.unapprovedStation,
  );
  const [openStatus, setOpenStatus] = useState<boolean>(false);
  const [openTab, setOpenTab] = useState<number>(0);
  const [modalView, setModalView] = useState({
    title: 'Add Account',
    header: true,
    content: <>vds</>,
    rightCorner: '',
  })
  /**
    * Close modal box function
  */
  const closeModal = () => {
    setOpenStatus(false);
  }

  /**
    * View charging station modal box function
  */
  const viewFunction = (currentData: ChargingStationInterFace) => {
    setOpenStatus(true);
    setModalView({
      title: 'Add Account',
      header: false,
      content: (
        <ViewChargingStation details={currentData} closeModal={closeModal} />
      ),
      rightCorner: '',
    });
  }

  /**
   * Handle the tab change function
   * @param number 
   */
  const changeTabPages = (number: number) => {
    setOpenTab(number);
    let pageName = '';
    switch (number) {
      case 0:
        pageName = 'approved';
        break;
      case 1:
        pageName = 'unapproved';
        break;
      case 2:
        pageName = 'rejected';
        break;

      default:
        break;
    }
    window.history.pushState({}, '', `${pageName}`);
  }

  useEffect(() => {
    if (currentPage) {
      let number = 0;
      switch (currentPage) {
        case 'approved':
          number = 0;
          break;
        case 'unapproved':
          number = 1;
          break;
        case 'rejected':
          number = 2;
          break;
  
        default:
          break;
      }
      setOpenTab(number);
    }
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <div className="container station-container">
        <div className="header-title">
          <h2>Charging Station Management</h2>
        </div>
        <div className="body-container">
          <div className="table-tab">
            <TabMenus openTab={openTab} setOpenTab={changeTabPages} menus={[
              {
                name: 'Approved',
              },
              {
                name: 'Pending',
                count: totalResults,
              },
              {
                name: 'Rejected',
              },
            ]} />
          </div>
          <div className="table-content">
            {
              openTab === 0 && (
                <ApprovedList viewFunction={viewFunction} />
              )
            }
            {
              openTab === 1 && (
                <UnApprovedList viewFunction={viewFunction} />
              )
            }
            {
              openTab === 2 && (
                <RejectedList viewFunction={viewFunction} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChargingStationManagement;
