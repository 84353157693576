import { useEffect } from 'react';

import { metaValues } from '../../config/branding';

/**
 * Function to handle meta title and description
 */
function MetaManager() {
  useEffect(() => {
    // Set meta title
    if (metaValues.title) {
      document.title = metaValues.title;
    }

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', metaValues.description);
    } else {
      // Create meta description if it doesn't exist
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = metaValues.description;
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  return null;
}

export default MetaManager;
