import React, { useEffect, useState } from 'react';

import { ReactComponent as UserIcon } from '../../../assets/icons/user-icon-fill.svg';
import {
  AccountAddState,
  AccountParameters,
  AccountsInterFace,
} from '../../../interface/account-management.interface';
import { useAppDispatch } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import BatteryLoader from '../../../shared-components/battery-loader/BatteryLoader';
import MyButton from '../../../shared-components/button/Button';
import CustomFileInput from '../../../shared-components/custom-file-input/CustomFileInput';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import CustomPhoneInput from '../../../shared-components/custom-input/CustomPhoneInput';
import SelectFiled from '../../../shared-components/select-field/SelectField';
import {
  checkFileSizeValidation,
  emailValidation,
  passwordValidation,
  phoneNumberValidation,
  stringValidation,
} from '../../../shared-functions/Validation';
import accountApi from '../../../utils/api/account';

/**
 * Add Account function component
 */
function AddAccount({
  closeModal,
  currentData,
}: {
  closeModal: (value: string) => void;
  currentData?: AccountsInterFace;
}) {
  const dispatch = useAppDispatch();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [showFormErrors, setShowFormErrors] = useState<boolean>(false);
  const [accountDetails, setAccountDetails] = useState<AccountAddState>({
    status: 'facility-owner',
    name: '',
    governmentId: [],
    governmentIdOld: [],
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    countryCode: '1',
  });

  /**
   * Fetch the one account data function
   * @param id
   */
  const fetchData = (id: string) => {
    setLoader(true);
    accountApi
      .fetchOneAccounts(id)
      .then((response) => {
        setLoader(false);
        setShowFormErrors(true);
        setAccountDetails({
          status: 'facility-owner',
          name: response.data.name,
          governmentIdOld: [response.data.governmentId],
          governmentId: [],
          email: response.data.email,
          phone: response.data.phoneNumber,
          password: '',
          rePassword: '',
          countryCode: response.data.countryCode,
        });
      })
      .catch((error) => {
        setLoader(false);
        closeModal('');
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Handle the change function
   * @param name
   * @param value
   * @returns
   */
  const handleChangeFunction = (name: string, value: string[] | File[] | string | number) => {
    setAccountDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the change function
   * @param name
   * @param value
   * @returns
   */
  const handleRemoveFile = (
    name: string,
    url: string,
    indexToRemove: number,
    type: 'newFile' | 'existingFile',
  ) => {
    if (type === 'newFile') {
      const governmentId = accountDetails?.governmentId;
      governmentId.splice(indexToRemove, 1);
      setAccountDetails((previous) => ({
        ...previous,
        governmentId,
      }));
    } else if (type === 'existingFile') {
      const governmentIdOld = accountDetails?.governmentIdOld?.filter((value) => value.url !== url);
      setAccountDetails((previous) => ({
        ...previous,
        // governmentId: [],
        governmentIdOld,
      }));
    }
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    setShowFormErrors(true);
    let errorMessage = false;
    if (!currentData?.id) {
      if (
        !checkFileSizeValidation(accountDetails.governmentId, 5, 1) ||
        accountDetails.phone === '' ||
        !phoneNumberValidation(accountDetails.phone) ||
        !emailValidation(accountDetails.email) ||
        !stringValidation(1, 100, accountDetails.name) ||
        accountDetails.email === '' ||
        !passwordValidation(accountDetails.password) ||
        accountDetails.rePassword !== accountDetails.password
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: AccountParameters = {
          name: accountDetails.name,
          userName: accountDetails.name,
          email: accountDetails.email,
          password: accountDetails.password,
          phoneNumber: accountDetails.phone,
          governmentId: accountDetails.governmentId[0],
          countryCode: accountDetails.countryCode,
        };
        accountApi
          .createAccounts(request)
          .then(() => {
            closeModal('Account Added Successfully...');
          })
          .catch((error) => {
            setDisabled(false);
            dispatch(
              setNotificationMessage({
                message: error?.response?.data?.message,
                status: true,
                type: 'error',
                code: error?.response?.data?.code,
              }),
            );
          });
      }
    } else {
      if (
        accountDetails.phone === '' ||
        !phoneNumberValidation(accountDetails.phone) ||
        !emailValidation(accountDetails.email) ||
        accountDetails.governmentIdOld?.length === 0 ||
        !(
          accountDetails.governmentId.length === 0 ||
          checkFileSizeValidation(accountDetails.governmentId, 5, 1)
        ) ||
        !stringValidation(1, 100, accountDetails.name) ||
        accountDetails.email === ''
      ) {
        errorMessage = true;
      }
      if (!errorMessage) {
        setDisabled(true);
        const request: AccountParameters = {
          name: accountDetails.name,
          email: accountDetails.email,
          phoneNumber: accountDetails.phone,
        };
        if (accountDetails.governmentId.length > 0) {
          request.governmentId = accountDetails?.governmentId?.[0];
        }
        accountApi
          .editAccounts(currentData?.tenantId, request)
          .then(() => {
            closeModal('Account Updated Successfully...');
          })
          .catch((error) => {
            dispatch(
              setNotificationMessage({
                message: error?.response?.data?.message,
                status: true,
                type: 'error',
                code: error?.response?.data?.code,
              }),
            );
          });
      }
    }
  };

  useEffect(() => {
    if (currentData?.id) {
      fetchData(currentData?.tenantId);
    }
  }, []);

  return !loader ? (
    <div className="add-account-form">
      <div className="form-body">
        <div className="form-field">
          <SelectFiled
            name="status"
            inputLabel="User Type"
            selectedValue={accountDetails.status}
            placeholder="Select Role"
            svgIcon={<UserIcon />}
            onChangeValue={handleChangeFunction}
            valueRequired
            error={showFormErrors && accountDetails.status.length === 0}
            errorMessage="User Type is Required"
            options={[
              {
                label: 'Facility Owner',
                value: 'facility-owner',
              },
            ]}
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="name"
            inputLabel="User Name"
            type="text"
            placeholder="Enter User Name"
            value={accountDetails.name}
            onChangeValue={handleChangeFunction}
            valueRequired
            error={showFormErrors && !stringValidation(1, 100, accountDetails.name)}
            errorMessage="User Name is Required"
          />
        </div>
        <div className="form-field">
          <CustomFileInput
            name="governmentId"
            inputLabel="Government Id"
            placeholder="Upload here..."
            selectedFiles={accountDetails.governmentId}
            existingFiles={accountDetails.governmentIdOld}
            existingName="governmentIdOld"
            onChangeValue={handleChangeFunction}
            unselectHandler={handleRemoveFile}
            acceptedFileType=""
            valueRequired
            error={
              showFormErrors &&
              (accountDetails.governmentIdOld?.length || 0) + accountDetails.governmentId.length !==
                1 &&
              ((currentData?.id && accountDetails.governmentIdOld?.length === 0) ||
                !checkFileSizeValidation(accountDetails.governmentId, 5, 1))
            }
            errorMessage="Government Id file is Required"
          />
        </div>
        <div className="form-field">
          <CustomInput
            name="email"
            inputLabel="Email Id"
            type="text"
            placeholder="Enter Email"
            value={accountDetails.email}
            onChangeValue={handleChangeFunction}
            valueRequired
            error={
              showFormErrors &&
              (accountDetails.email === '' || !emailValidation(accountDetails.email))
            }
            errorMessage="Email is Required or Invalid"
          />
        </div>
        <div className="form-field">
          <CustomPhoneInput
            name="phone"
            inputLabel="Phone Number"
            type="number"
            placeholder="Enter Phone Number"
            value={accountDetails.phone}
            value1={accountDetails.countryCode}
            onChangeValue={handleChangeFunction}
            valueRequired
            error={
              showFormErrors &&
              (accountDetails.phone === '' || !phoneNumberValidation(accountDetails.phone))
            }
            errorMessage="Phone Number is Required or Invalid"
          />
        </div>
        {!currentData?.id && (
          <>
            <div className="form-field">
              <CustomInput
                name="password"
                inputLabel="Set Password"
                type="password"
                placeholder="Enter Password"
                value={accountDetails.password}
                onChangeValue={handleChangeFunction}
                valueRequired
                error={showFormErrors && !passwordValidation(accountDetails.password)}
                errorMessage="Password is Required or Invalid"
              />
            </div>
            <div className="form-field">
              <CustomInput
                name="rePassword"
                inputLabel="Re-Enter Password"
                type="password"
                placeholder="Enter Password"
                value={accountDetails.rePassword}
                onChangeValue={handleChangeFunction}
                valueRequired
                error={
                  showFormErrors &&
                  !passwordValidation(accountDetails.rePassword) &&
                  (accountDetails.password !== accountDetails.rePassword ||
                    accountDetails.rePassword === '')
                }
                errorMessage="Password and Re-enter Password is also same"
              />
            </div>
          </>
        )}
        <div className="form-field table-bottom-button-list form-bottom-button">
          <div className="table-button-item">
            <MyButton label="Back" buttonType="back" onClickFunc={() => closeModal('')} />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Submit"
              buttonType="submit"
              loading={disabled}
              disabled={disabled}
              onClickFunc={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="add-account-form loader">
      <BatteryLoader />
    </div>
  );
}

AddAccount.defaultProps = {
  currentData: {},
};

export default AddAccount;
