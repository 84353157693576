import html2canvas from 'html2canvas';
import { jsPDF as JsPDF } from 'jspdf';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as Export } from '../../assets/icons/export.svg';
import { useAppDispatch } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import MyButton from '../../shared-components/button/Button';
import CustomToggleButton from '../../shared-components/custom-toggle-button/CustomToggleButton';
import endConsumerApi from '../../utils/api/end-consumer';

import ConsumersList from './components/consumers-list';
import ReportGraphChart from './components/report-graph';

/**
 * User Management page function component
 */
function EndConsumerManagement() {
  const printReference = useRef<HTMLDivElement | null>(null);
  const dispatch = useAppDispatch();
  const chartView = true;
  const [data, setData] = useState([
    { month: 'Jan', users: 0 },
    { month: 'Feb', users: 0 },
    { month: 'Mar', users: 0 },
    { month: 'Apr', users: 0 },
    { month: 'May', users: 0 },
    { month: 'Jun', users: 0 },
    { month: 'Jul', users: 0 },
    { month: 'Aug', users: 0 },
    { month: 'Sep', users: 0 },
    { month: 'Oct', users: 0 },
    { month: 'Nov', users: 0 },
    { month: 'Dec', users: 0 },
  ]);

  /**
   * Fetch the end consumer count report
   * @param type
   */
  const fetchData = (type: string) => {
    endConsumerApi
      .endConsumerCountReport({ type })
      .then((response) => {
        setData([...response.data]);
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Handle the change graph type
   * @param number
   */
  const changeGraphType = (number: number) => {
    let type = 'year';
    switch (number) {
      case 0:
        type = 'year';
        break;
      case 1:
        type = 'month';
        break;
      case 2:
        type = 'week';
        break;
      case 3:
        type = 'hour';
        break;

      default:
        break;
    }
    fetchData(type);
  };

  /**
   * Export the PDF file
   */
  const generatePdf = () => {
    if (printReference.current) {
      const example = printReference.current;
      setTimeout(async () => {
        const pdf = new JsPDF('l', 'mm', 'a4'); // A4 size: 210mm x 297mm
        const canvas = await html2canvas(example, { scale: 2 });
        const imgData = canvas.toDataURL('image/png');

        const imgWidth = 270; // Width of A4 in mm
        const pageHeight = 257; // Height of A4 in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;

        let position = 15;

        // Add the first page
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        // Add additional pages if content overflows
        while (heightLeft > 0) {
          position = heightLeft - imgHeight; // Adjust the position
          pdf.addPage();
          pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }

        pdf.save('end-consumer-report.pdf');
      }, 1000);
    }
  };

  useEffect(() => {
    fetchData('year');
  }, []);
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <div className="container">
        <div className="header-title">
          <h2>End Consumer Management</h2>
        </div>
        <div className="body-container end-consumer-warp">
          <div className="table-content">
            {chartView && (
              <div className="reports-graph-content">
                <div className="reports-graph-header-container">
                  <div className="reports-title">Application Status</div>
                  <div className="reports-graph-header">
                    <div className="reports-toggle-btn">
                      <CustomToggleButton
                        labels={['12 Months', 'Last Month', '7 Days', '24 hours']}
                        onToggle={changeGraphType}
                        defaultActiveIndex={0}
                      />
                    </div>
                    <div className="reports-export-btn">
                      <MyButton
                        label="Export Data"
                        buttonType="back"
                        disableHoverEffect
                        onClickFunc={generatePdf}
                        svgIcon={<Export />}
                      />
                    </div>
                  </div>
                </div>
                <div className="reports-graph" ref={printReference}>
                  <ReportGraphChart data={data} />
                </div>
              </div>
            )}
            <ConsumersList />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EndConsumerManagement;
