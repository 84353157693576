import React, { useState } from 'react';

import { ReactComponent as Search } from '../../../assets/icons/search.svg';
import { FilterEndConsumerInterFace } from '../../../interface/end-consumer.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomDateRange from '../../../shared-components/custom-date-range/CustomDateRange';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
// import roleApi from '../../../utils/api/roles';
import SelectFiled from '../../../shared-components/select-field/SelectField';

interface AddRoleInterface {
  closeModal: () => void;
  handleSubmitFilter: (filterData: FilterEndConsumerInterFace) => void;
  oldFilter: FilterEndConsumerInterFace;
}
/**
 * Role Add page function component
 */
function FilterUser({ closeModal, handleSubmitFilter, oldFilter }: AddRoleInterface) {
  const [filterDetails, setFilterDetails] = useState<FilterEndConsumerInterFace>(oldFilter || {});

  /**
   * Handle the input change function
   * @param name
   * @param value
   */
  const handleInputChange = (name: string, value: string | number | (string | number)[]) => {
    setFilterDetails((previous) => ({
      ...previous,
      [name]: value,
    }));
  };

  /**
   * Handle the submit function
   */
  const handleSubmit = () => {
    handleSubmitFilter(filterDetails);
  };

  return (
    <div className="filter-user-warp">
      <div className="form-body">
        <div className="form-field">
          <CustomInput
            name="userCustomId"
            inputLabel="Consumer Id"
            type="text"
            placeholder="Search here..."
            value={filterDetails.userCustomId || ''}
            onChangeValue={handleInputChange}
            svgIcon={<Search />}
          />
        </div>
        <div className="form-field">
          <CustomDateRange
            name1="fromDate"
            name2="toDate"
            inputLabel="Date"
            valueRequired
            value1={filterDetails.fromDate || ''}
            value2={filterDetails.toDate || ''}
            onChangeValue={handleInputChange}
          />
        </div>
        <div className="form-field">
          <SelectFiled
            name="status"
            inputLabel="Status"
            placeholder="All"
            onChangeValue={handleInputChange}
            options={[
              {
                label: 'Active',
                value: 'active',
              },
              {
                label: 'Inactive',
                value: 'inActive',
              },
              // {
              //   label: 'Blocked',
              //   value: 'blocked',
              // },
            ]}
            selectedValue={filterDetails.status || ''}
          />
        </div>
        {false && (
          <div className="form-field">
            <SelectFiled
              name="type"
              inputLabel="Customer Type"
              placeholder="All"
              onChangeValue={handleInputChange}
              options={[
                {
                  label: 'Premium',
                  value: 'premium',
                },
                {
                  label: 'Regular',
                  value: 'regular',
                },
              ]}
              selectedValue={filterDetails.type || ''}
            />
          </div>
        )}
        <div className="form-field table-bottom-button-list form-bottom-button end">
          <div className="table-button-item">
            <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
          </div>
          <div className="table-button-item">
            <MyButton label="Submit" buttonType="submit" onClickFunc={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterUser;
