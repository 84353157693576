import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/down-arrow.svg';

type Option = {
  value: string | number;
  label: string;
};

interface SelectFieldProperties {
  inputLabel?: string;
  options: Option[]; // Array of objects with value and label
  placeholder?: string;
  selectedValue: string | number;
  name: string;
  onChangeValue: (name: string, value: any) => void;
  valueRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
  svgIcon?: JSX.Element;
  readOnly?: Boolean;
}

/**
 * Select Box filed function component
 */
function AutoCompleteSelectField({
  inputLabel,
  options,
  name,
  placeholder,
  selectedValue,
  onChangeValue,
  error,
  errorMessage,
  valueRequired,
  svgIcon,
  readOnly,
}: SelectFieldProperties) {
  const selectBoxReference = useRef<HTMLDivElement>(null);
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<string>('close');
  const [style, setStyle] = useState<any>({});
  const [valueToShow, setValueToShow] = useState<string | number>('');
  const [optionToShow, setOptionToShow] = useState<Option[]>([]);
  /**
   * Handle the value change function
   */
  // const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const handleValueChange = (changeValue: any) => {
    if (readOnly) {
      return;
    }

    onChangeValue(name, changeValue);
    setOpen('close');
  };

  /**
   * Handle the close th drop down function
   * @param event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setOpen('close');
    }
  };

  /**
   * Get the dropdown position
   */
  const openDropDown = () => {
    if (selectBoxReference.current && optionToShow.length > 0) {
      const { offsetHeight, offsetWidth } = selectBoxReference.current;
      if (window.innerHeight > offsetHeight + 300) {
        setStyle({
          top: `${inputLabel ? offsetHeight + 30 : offsetHeight}px`,
          width: `${offsetWidth}px`,
        });
      } else {
        setStyle({
          bottom: `${offsetHeight}px`,
          width: `${offsetWidth}px`,
          borderBottomColor: '#6c757d',
          borderTopColor: '#e3e8f1',
          borderRadius: '8px 8px 0 0',
        });
      }
      setOpen('open');
    }
  };

  useEffect(() => {
    setValueToShow(selectedValue);
  }, [selectedValue]);

  useEffect(() => {
    if (valueToShow !== '') {
      const regex = new RegExp(`${valueToShow}`, 'i'); // Case-insensitive search
      const matchedOption = options.filter((eachOption: Option) =>
        regex.test(`${eachOption.value}`),
      );
      setOptionToShow(matchedOption);
      selectBoxReference.current?.click();
    }
  }, [valueToShow]);

  useEffect(() => {
    setOptionToShow(options);
  }, []);

  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="auto-complete-select-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div
        className={`auto-complete-select-container ${error && 'error'} ${open}`}
        onClick={openDropDown}
        role="presentation"
        ref={selectBoxReference}>
        {svgIcon ? <div className="front-icon">{svgIcon}</div> : <div />}
        <div className="auto-complete-input-field">
          <input
            type="text"
            placeholder={placeholder}
            value={valueToShow}
            onChange={(event) => handleValueChange(event.target.value)}
          />
        </div>
        <span className="custom-icon">
          <ArrowIcon />
        </span>
      </div>
      {error && <p className="custom-input-error-message">{errorMessage}</p>}
      <div className={`dropdown-items-list ${open}`} ref={reference} style={style}>
        {optionToShow?.map((option) => (
          <div
            className="items"
            key={option?.value}
            onClick={() => handleValueChange(option.value)}
            role="presentation">
            {option?.label}
          </div>
        ))}
      </div>
    </div>
  );
}

AutoCompleteSelectField.defaultProps = {
  inputLabel: '',
  placeholder: '',
  valueRequired: false,
  error: false,
  errorMessage: '',
  svgIcon: null,
  readOnly: false,
};

export default AutoCompleteSelectField;
