import React, { useState } from 'react';

import { ReactComponent as Delete } from '../../../assets/icons/delete.svg';
import profile from '../../../assets/images/profiles/profile.jpg';
import { ChargingStationInterFace } from '../../../interface/charging-station.interface';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';

interface ReasonDetailInterface {
  reason: string;
}

interface DeleteAccountInterface { stationDetails: ChargingStationInterFace; closeModal: () => void; conformSubmit: (value: ChargingStationInterFace) => void }
/**
 * Reject Station function component
 */
function RejectStation({ stationDetails, closeModal, conformSubmit }: DeleteAccountInterface) {
  const [reasonDetails, setReasonDetails] = useState<ReasonDetailInterface>({
    reason: '',
  });

  /**
   * Input filed handle change function
   */
  const handleInput = (name: string, value: string | number) => setReasonDetails({
    ...reasonDetails, 
    [name]: value,
  });

  return (
    <div className="add-account-form">
      <div className="view-body">
        <div className="profile-details">
          <div className="profile-image">
            <img src={profile} alt="profile" />
          </div>
          <div className="profile-user">
            <div className="profile-title-1">{stationDetails?.name}</div>
            {/* <div className="profile-title-2">{stationDetails?.email}</div> */}
          </div>
        </div>
        <div className="form-field">
          <CustomInput
            type="text"
            placeholder="Enter the reason"
            value={reasonDetails.reason}
            name="reason"
            onChangeValue={handleInput}
            inputLabel="Enter the valid reason"
          />
        </div>
        <div className="form-field table-bottom-button-list form-bottom-button">
          <div className="table-button-item">
            <MyButton
              label="Cancel"
              buttonType="back"
              onClickFunc={closeModal}
            />
          </div>
          <div className="table-button-item">
            <MyButton
              label="Reject"
              buttonType="error"
              svgBackIcon={<Delete />}
              onClickFunc={() => conformSubmit({
                ...stationDetails,
                id: stationDetails?.id,
                ...reasonDetails,
              })}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RejectStation;
