import React from 'react';

import CustomDatePicker from './CustomDatePicker';

interface CustomDateRangeProperties {
  inputLabel?: string;
  value1: string;
  value2: string;
  name1: string;
  name2: string;
  onChangeValue: (name: string, value: string) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
}

/**
 * Custom Date Range function component
 */
function CustomDateRange({
  inputLabel,
  name1,
  name2,
  value1,
  value2,
  onChangeValue,
  valueRequired,
  readOnly,
}: CustomDateRangeProperties) {
  /**
   * Date Range field handle function
   */
  const handleValueChange = (name: string, changeValue: string) =>
    onChangeValue(name, changeValue);

  return (
    <div className="custom-input-date-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-date-input">
        <CustomDatePicker
          name={name1}
          placeholder="From"
          value={value1}
          readOnly={readOnly}
          onChangeValue={handleValueChange}
        />
        <CustomDatePicker
          name={name2}
          placeholder="To"
          value={value2}
          readOnly={readOnly}
          onChangeValue={handleValueChange}
        />
      </div>
    </div>
  );
}

CustomDateRange.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
};

export default CustomDateRange;
