import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import { ReactComponent as CheckBoxActiveIcon } from '../../assets/icons/check-box-active-icon.svg';
import { ReactComponent as CheckBoxIcon } from '../../assets/icons/check-box-icon.svg';
import { ReactComponent as SizeChangeIcon } from '../../assets/icons/table-field-allow-icon.svg';

interface TableFieldAllowInterFace {
  viewFields: { label: string; accessorKey: string; allow: boolean }[];
  hideAndShowFields: (value: string) => void;
}

/**
 * Table Field Allow function component
 * @returns
 */
function TableFieldAllow({ viewFields, hideAndShowFields }: TableFieldAllowInterFace) {
  const label: string = '';
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState({});

  /**
   * Open the dropdown modal
   * @param event
   */
  const openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    let rect;
    const target = event.target as HTMLButtonElement;
    if (target.className === 'primary-outline-button') {
      rect = target.getBoundingClientRect();
    } else {
      const closestButton = target.closest('.primary-outline-button') as HTMLButtonElement;
      rect = closestButton.getBoundingClientRect();
    }
    setStyle({
      top: rect.top + rect.height,
      left: rect.left - 140,
    });
  };
  /**
   * Handle the close th drop down function
   * @param event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };
  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);
  return (
    <div className="table-field-allow-warp">
      <div className="button-wrap">
        <button type="button" className="primary-outline-button" onClick={openModal}>
          <span className="icon">
            <SizeChangeIcon />
          </span>
          {label && <span>{label}</span>}
        </button>
      </div>
      {open &&
        ReactDOM.createPortal(
          <div className="table-field-allow-container" style={style} ref={reference}>
            <div className="table-field-allow-header">
              <div className="allow-all-field-button">
                {
                  viewFields.filter((field) => field.allow).length > 0 ? (
                    <div className="field-item" onClick={() => hideAndShowFields('all-hide')} role="presentation">Hide All</div>
                  ) : (
                    <div className="field-item" onClick={() => hideAndShowFields('all-show')} role="presentation">Show All</div>
                  )
                }
              </div>
            </div>
            <div className="allow-field-list">
              {viewFields?.length > 0 && viewFields.map((field: { label: string; accessorKey: string; allow: boolean }) => (
                <div className="field-item" onClick={() => hideAndShowFields(field.accessorKey)} role="presentation">
                  {field.allow ? <CheckBoxActiveIcon /> : <CheckBoxIcon />} <span>{field.label}</span>
                </div>
              ))}
            </div>
          </div>,
          document.body,
        )}
    </div>
  );
}

export default TableFieldAllow;
