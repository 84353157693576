import { ReactComponent as ActivityIcon } from '../../../assets/icons/activety-icon.svg';
// import { ReactComponent as CreditCard } from '../../../assets/icons/credit-card.svg';
import { ReactComponent as CurrencyIcon } from '../../../assets/icons/currency-icon.svg';
import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more-icon.svg';
import { EndConsumerCardDetails } from '../../../interface/end-consumer.interface';
import DropDown from '../../../shared-components/drop-down/DropDown';

/**
 * User Card Body page function component
 */
function ConsumerCardBody({
  userDetails,
  activeLog,
  menuList,
  activeLogActive,
}: EndConsumerCardDetails) {
  return (
    <div className="card-body">
      <div className="card-header">
        <div className="card-header-item-title">
          <div className="title">{userDetails.name}</div>
          <div className="sub-title">{userDetails.userCustomId}</div>
        </div>
        <div className="card-header-item-tag">
          {userDetails.isActive ? (
            <div className="tag-success-label">
              <DotCircle /> Active
            </div>
          ) : (
            <div className="tag-danger-label">
              <DotCircle /> Inactive
            </div>
          )}
        </div>
        <div className="card-header-item-icon">
          {menuList.filter((value) => value.allow).length > 0 && <DropDown list={menuList} icon={<MoreIcon />} id={userDetails.id} />}
        </div>
      </div>
      <div className="card-body-content">
        {/* <div className="user-details">
          <div className="title">Consumer Type</div>
          <div className="value">{userDetails.consumerType}</div>
        </div> */}
        <div className="user-details">
          <div className="title">Date</div>
          <div className="value">{userDetails.createdAt}</div>
        </div>
        <div className="user-details">
          <div className="title">Email</div>
          <div className="value">{userDetails.email}</div>
        </div>
        <div className="user-details">
          <div className="title">Phone Number</div>
          <div className="value">{userDetails.phoneNumber}</div>
        </div>
        {/* <div className="user-details">
          <div className="title">Payment Method</div>
          <div className="value">
            <CreditCard /> {userDetails.paymentMethod}
          </div>
        </div> */}
        <div className="user-details">
          <div className="title">Wallet Balance</div>
          <div className="value">
            <CurrencyIcon /> {parseFloat(userDetails.wallet).toFixed(2)}
          </div>
        </div>
      </div>
      {activeLogActive && (
        <div className="card-body-footer">
          <div className="button-wrap">
            <button
              type="button"
              className="back-button on-hover"
              onClick={(event: React.MouseEvent) => activeLog(userDetails.id, event)}>
              <span>
                <ActivityIcon />
              </span>
              <span>Activity Log</span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ConsumerCardBody;
