import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as ArrowIcon } from '../../assets/icons/down-arrow.svg';

type Option = {
  value: string | number;
  label: string;
};

interface SelectFieldProperties {
  inputLabel?: string;
  options: Option[]; // Array of objects with value and label
  placeholder?: string;
  selectedValue: string | number;
  name: string;
  onChangeValue: (name: string, value: any) => void;
  valueRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
  svgIcon?: JSX.Element;
}

/**
 * Select Box filed function component
 */
function SelectField({
  inputLabel,
  options,
  name,
  placeholder,
  selectedValue,
  onChangeValue,
  error,
  errorMessage,
  valueRequired,
  svgIcon,
}: SelectFieldProperties) {
  const reference = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<string>('close');
  const [style, setStyle] = useState<any>({});
  /**
   * Handle the value change function
   */
  // const handleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const handleValueChange = (changeValue: any) => {
    onChangeValue(name, changeValue);
    setOpen('close');
  };

  /**
   * Handle the close th drop down function
   * @param event
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (reference.current && !reference.current.contains(event.target as Node)) {
      setOpen('close');
    }
  };

  /**
   * Get the dropdown position
   */
  const openDropDown = (event: React.MouseEvent<HTMLDivElement>) => {
    const { offsetHeight, offsetWidth } = event.currentTarget;
    if (window.innerHeight > event.clientY + 300) {
      setStyle({
        top: `${inputLabel ? offsetHeight + 30 : offsetHeight}px`,
        width: `${offsetWidth}px`,
      });
    } else {
      setStyle({
        bottom: `${offsetHeight}px`,
        width: `${offsetWidth}px`,
        borderBottomColor: '#6c757d',
        borderTopColor: '#e3e8f1',
        borderRadius: '8px 8px 0 0',
      });
    }
    setOpen('open');
  };

  useEffect((): void => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className="custom-select-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div
        className={`custom-select-container ${error && 'error'} ${open}`}
        onClick={openDropDown}
        role="presentation">
        {svgIcon && <div className="frond-icon">{svgIcon}</div>}
        <div className="view-value">
          {options.find((option) => option.value === selectedValue)?.label || placeholder}
        </div>
        {/* <select value={selectedValue} onChange={handleValueChange}>
          <optgroup>
            {placeholder && (
              <option value="" disabled selected>
                {placeholder}
              </option>
            )}
            {options?.map((option) => (
              <option key={option?.value} value={option?.value}>
                {option?.label}
              </option>
            ))}
          </optgroup>
        </select> */}
        <span className="custom-icon">
          <ArrowIcon />
        </span>
      </div>
      {error && <p className="text-danger">{errorMessage}</p>}
      <div className={`dropdown-items-list ${open}`} ref={reference} style={style}>
        {options?.map((option) => (
          <div
            className="items"
            key={option?.value}
            onClick={() => handleValueChange(option.value)}
            role="presentation">
            {option?.label}
          </div>
        ))}
      </div>
    </div>
  );
}

SelectField.defaultProps = {
  inputLabel: '',
  placeholder: '',
  valueRequired: false,
  error: false,
  errorMessage: '',
  svgIcon: null,
};

export default SelectField;
