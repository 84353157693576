/* eslint-disable no-param-reassign */
import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  APIConsumerResponseInterface,
  EndConsumerInitialProperties,
  EndConsumerInterFace,
  FilterEndConsumerInterFace,
  RequestEndConsumerInterFace,
} from '../../interface/end-consumer.interface';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { removeEmptyKeys } from '../../shared-functions/DateTime';
import endConsumerApi from '../../utils/api/end-consumer';

import { setNotificationMessage } from './NotificationManagementSlice';

// a thunk to fetch users
export const fetchAllEndConsumers = createAsyncThunk<
  APIConsumerResponseInterface,
  RequestEndConsumerInterFace
>(
  'end-consumer-management/fetchAllEndConsumers',
  async (data: RequestEndConsumerInterFace, { dispatch }) => {
    const response = await endConsumerApi
      .fetchConsumers(removeEmptyKeys(data))
      .then((response1) => response1)
      .catch((error) =>
        dispatch(
          setNotificationMessage({
            type: 'error',
            message: error?.response?.data?.message,
            code: error?.response?.data?.code,
            status: true,
          }),
        ),
      );
    return {
      ...response.data,
      results: response.data.results.map((consumerData: EndConsumerInterFace) => ({
        ...consumerData,
        createdAt: ConvertUTCtoDate(consumerData.createdAt),
        name: `${consumerData.firstName} ${consumerData.lastName}`,
        id: consumerData._id,
        wallet: Number(consumerData?.wallet).toFixed(2),
      })),
    };
  },
);

// Initial state
const initialState: EndConsumerInitialProperties = {
  consumers: [],
  limit: DefaultApiParameters.limit,
  page: DefaultApiParameters.page,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  filter: {},
};

// Create the slice
export const endConsumerSlice = createSlice({
  name: 'end-consumer-management',
  initialState,
  reducers: {
    /**
     * Set Account filter data function
     * @param state
     * @param action
     */
    setConsumerFilterData: (
      state: EndConsumerInitialProperties,
      action: PayloadAction<FilterEndConsumerInterFace>,
    ) => {
      state.filter = action.payload;
    },
  },
  /**
   * Use the extra reducers function
   * @param builder
   */
  extraReducers: (builder: ActionReducerMapBuilder<EndConsumerInitialProperties>) => {
    builder
      .addCase(fetchAllEndConsumers.pending, (state: EndConsumerInitialProperties) => {
        state.loading = true;
      })
      .addCase(
        fetchAllEndConsumers.fulfilled,
        (
          state: EndConsumerInitialProperties,
          action: PayloadAction<APIConsumerResponseInterface>,
        ) => {
          state.loading = false;
          state.consumers = [...action.payload.results];
          state.limit = action.payload.limit;
          state.page = action.payload.page;
          state.totalPages = action.payload.totalPages;
          state.totalResults = action.payload.totalResults;
        },
      )
      .addCase(fetchAllEndConsumers.rejected, (state: EndConsumerInitialProperties) => {
        state.loading = false;
        // Handle error if needed
      });
  },
});

// Export the actions
export const { setConsumerFilterData } = endConsumerSlice.actions;
// Export the reducer
export default endConsumerSlice.reducer;
