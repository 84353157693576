import React, { useEffect } from 'react';

import { ReactComponent as SuccessIcon } from '../../assets/icons/success-tick.svg';

interface SuccessNotificationProperties {
  title: string;
  description: string;
  notificationCloseTimer?: number | null;
  onNotificationClose?: () => void;
}

/**
 * Success notification popup
 */
function SuccessNotification(properties: SuccessNotificationProperties) {
  const { title, description, notificationCloseTimer, onNotificationClose } = properties;

  useEffect(() => {
    if (notificationCloseTimer && onNotificationClose) {
      const timer = setTimeout(() => {
        onNotificationClose();
      }, 3000);

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }

    return undefined; // Explicitly return undefined when notificationCloseTimer is false
  }, [notificationCloseTimer]);

  return (
    <div className="success-notification-wrap">
      <div className="success-notification-container">
        <div className="success-icon">
          <SuccessIcon />
        </div>
        <div className="success-content">
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
}

SuccessNotification.defaultProps = {
  notificationCloseTimer: null,
  onNotificationClose: null,
};

export default SuccessNotification;
