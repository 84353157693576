const phoneNumberValidator = /^\d{9,15}$/;
const emailValidator =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const passwordValidator =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?~-])[A-Za-z\d!@#$%^&*()_+[\]{};':"\\|,.<>/?~-]{12,50}$/;

/**
 * Regex that check only text and num
 * @param minLength
 * @param maxLength
 * @returns boolean
 */
const textAndNumberRegex = (minLength: number, maxLength: number) => {
  const regexPattern = `^[A-Za-z0-9]{${minLength},${maxLength}}$`;
  return new RegExp(regexPattern, 'u');
};

/**
 * Function that check only text and num
 * @param minLength
 * @param maxLength
 * @param value
 * @returns boolean
 */
const textAndNumberValidation = (minLength: number, maxLength: number, value: string) => {
  const TEXT_NUMBER_REGEX = textAndNumberRegex(minLength, maxLength);
  return TEXT_NUMBER_REGEX.test(value);
};

/**
 * Email Validation
 * @param value
 * @returns
 */
const emailValidation = (value: string) => emailValidator.test(value);

/**
 * Phone number validation
 * @param value
 * @returns
 */
const phoneNumberValidation = (value: string) => phoneNumberValidator.test(value);

/**
 * Password Validation (Minimum 12 characters, should include one uppercase letter, one lowercase letter and one special character)
 * @param value
 * @returns
 */
const passwordValidation = (value: string) => passwordValidator.test(value);

/**
 * check text for length validation
 * @param minLength
 * @param maxLength
 * @returns
 */
const createStringRegex = (minLength: number, maxLength: number) => {
  const regexPattern = `^[\\p{L}\\d][\\p{L}\\p{N}._ @-]{${minLength - 1},${maxLength - 1}}$`;
  return new RegExp(regexPattern, 'u');
};

/**
 * Check the text and all symbols
 * @param maxLength
 * @returns
 */
const createTextAreaRegex = (maxLength: number = 500) => {
  const regexPattern = `^[\\p{L}\\p{N} .,!?;:'"()\\[\\]{}_/-]{1,${maxLength}}$`;
  return new RegExp(regexPattern, 'u');
};

/**
 * Name validation use min and max length function
 * @param minLength
 * @param maxLength
 * @param name
 * @returns
 */
const stringValidation = (minLength: number, maxLength: number, name: string = '') => {
  const NAME_REGEX = createStringRegex(minLength, maxLength);
  return NAME_REGEX.test(name);
};

/**
 * Check the description text validation
 * @param maxLength
 * @param description
 * @returns
 */
const longTextValidation = (maxLength: number, description: string = '') => {
  const DESCRIPTION_REGEX = createTextAreaRegex(maxLength);
  return DESCRIPTION_REGEX.test(description);
};

/**
 * Only number validation function
 * @param min
 * @param max
 * @param number
 * @returns
 */
const integerValidation = (min: number, max: number, number: number = 0) => {
  const value = Number(number);
  const numberValidator = new RegExp(`^[0-9]\\d{${min - 1},${max - 1}}$`);
  return numberValidator.test(String(value));
};

/**
 * Check file size validation
 * @param files
 * @param sizeMB
 * @returns
 */
const checkFileSizeValidation = (files: File[], sizeMB: number, maxCount: number, videoSize: number = 100) => {
  let output = true;
  if (files.length > maxCount || files.length === 0) {
    return false;
  }
  const MAX_FILE_SIZE = sizeMB * 1024 * 1024; // sizeMB MB
  files.forEach((file) => {
    if (file.size >= MAX_FILE_SIZE && file.type !== 'video/mp4') {
      output = false;
    } else if (file.size >= videoSize * 1024 * 1024 && file.type === 'video/mp4') {
      output = false;
    } else {
      output = true;
    }
  });
  return output;
};

/**
 * Check edit file size validation
 * @param files
 * @param sizeMB
 * @returns
 */
const checkEditFileSizeValidation = (files: File[], sizeMB: number, maxCount: number) => {
  let output = true;
  if (files.length > maxCount) {
    return false;
  }
  const MAX_FILE_SIZE = sizeMB * 1024 * 1024; // sizeMB MB
  files.forEach((file) => {
    if (file.size > MAX_FILE_SIZE) {
      output = false;
    }
  });
  return output;
};

/**
 * Handle the 12 hours validation
 */
const time12HoursValidation = (time: string) => {
  return /^(0[1-9]|1[0-2]):[0-5][0-9]\s?(AM|PM)$/.test(time);
};

export {
  textAndNumberValidation,
  emailValidation,
  phoneNumberValidation,
  passwordValidation,
  stringValidation,
  longTextValidation,
  integerValidation,
  checkFileSizeValidation,
  time12HoursValidation,
  checkEditFileSizeValidation,
};
