import React, { useEffect, useState } from 'react';

import DefaultApiParameters from '../../enum/DefaultApiParameters';
import {
  PaymentFilterInterFace,
  PaymentsInterFace,
} from '../../interface/payment-management.interface';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setNotificationMessage } from '../../redux/slices/NotificationManagementSlice';
import { fetchAllPayments, setPaymentFilterData } from '../../redux/slices/PaymentManagementSlice';
import CustomTable from '../../shared-components/custom-table/CustomTable';
import ExportButton from '../../shared-components/export-button/ExportButton';
import ModalBox from '../../shared-components/modal-box/ModalBox';
import PageTitle from '../../shared-components/page-title/PageTitle';
import ConvertUTCtoDate from '../../shared-functions/ConvertUTCtoDate';
import { exportCSV, exportPDF } from '../../shared-functions/ExportFile';
import paymentsApi from '../../utils/api/payments';

import FilterPayment from './components/filter-account';

/**
 * Payments Management function Component
 */
function PaymentManagement() {
  const dispatch = useAppDispatch();
  const { payments, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.payments,
  );
  const column = [
    {
      label: 'Date',
      accessorKey: 'date' as keyof PaymentsInterFace,
    },
    {
      label: 'User Name',
      accessorKey: 'userName' as keyof PaymentsInterFace,
    },
    {
      label: 'Phone Number',
      accessorKey: 'phoneNumber' as keyof PaymentsInterFace,
    },
    // {
    //   label: 'Type',
    //   accessorKey: 'type' as keyof PaymentsInterFace,
    // },
    {
      label: 'Amount',
      accessorKey: 'amount' as keyof PaymentsInterFace,
    },
    {
      label: 'Transaction Id',
      accessorKey: 'chargingTransactionId' as keyof PaymentsInterFace,
    },
    {
      label: 'Payment Status',
      accessorKey: 'paymentStatus' as keyof PaymentsInterFace,
      /**
       * Data custom function
       * @param currentRow
       */
      customData: (currentRow: PaymentsInterFace) =>
        currentRow.status === 'Success' ? (
          <div className="text-success">Success</div>
        ) : (
          <div className="text-danger">Failed</div>
        ),
    },
    // {
    //   label: 'Refund Status',
    //   accessorKey: 'status' as keyof PaymentsInterFace,
    // },
  ];

  const [openStatus, setOpenStatus] = useState(false);
  const [modalView, setModalView] = useState({
    title: '',
    header: false,
    content: <div />,
    rightCorner: '',
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Fetch the payment details in redus
   */
  const fetchPayments = (data: any) => {
    if (!loading) {
      dispatch(fetchAllPayments(data));
    }
  };
  /**
   * Modal box close function
   */
  const closeModal = () => {
    setOpenStatus(false);
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: any) => {
    if (value.sortField) {
      dispatch(
        setPaymentFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchPayments({ ...filter, ...value });
  };

  /**
   * Handle clear filter data function
   * @param value
   */
  const handleClearFilter = () => {
    dispatch(setPaymentFilterData({}));
    fetchPayments({
      page: DefaultApiParameters.page,
      limit,
    });
  };

  /**
   * Handle the filter submit function
   */
  const handleSubmitFilter = (filterData: PaymentFilterInterFace) => {
    dispatch(setPaymentFilterData(filterData));
    fetchPayments({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };

  /**
   * Payment filter view function
   */
  const paymentFilterFunction = () => {
    setOpenStatus(true);
    setModalView({
      title: '',
      header: false,
      content: (
        <FilterPayment
          closeModal={closeModal}
          handleSubmitFilter={handleSubmitFilter}
          oldValue={filter}
        />
      ),
      rightCorner: 'filter-modal',
    });
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    paymentsApi
      .fetchPaymentsExport({ ...filter })
      .then((response) => {
        // Convert data to CSV format
        const headers = [
          'Date',
          'User Name',
          'Phone Number',
          'Amount',
          'Transaction Id',
          'Payment Status',
        ];
        const rows = response.data.map((row: PaymentsInterFace) => [
          row.createdAt ? ConvertUTCtoDate(row.createdAt) : '',
          row.userName || '',
          row.phoneNumber || '',
          row.amount || '0',
          row.chargingTransactionId || '',
          row.status || 'Failed',
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'payments-list.csv');
          } else {
            exportPDF(headers, rows, 'payments-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (payments.length === 0) {
      fetchPayments({
        limit,
        page,
      });
    }
  }, []);

  return (
    <div className="payment-management-wrap">
      <ModalBox
        open={openStatus}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setOpenStatus(false)}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <PageTitle title="Payments" />
      <div className="table-content">
        <CustomTable
          header={column}
          data={payments}
          limit={limit}
          page={page}
          totalPages={totalPages}
          totalResults={totalResults}
          loading={loading}
          serialNo
          pageLimitChange={pageNateLimitChange}
          height={354}
          filterFunction={paymentFilterFunction}
          filterClearFunction={handleClearFilter}
          sizeChangeButtonRequired
          footerLeft={
            <div className="table-bottom-button-list">
              <div className="table-button-item">
                <ExportButton
                  label="Export Data"
                  buttonList={[
                    {
                      key: 'pdf',
                      allow: true,
                      /**
                       * Export pdf function
                       * @returns
                       */
                      onClickFunction: () => exportDataPdfOrCSV('pdf'),
                    },
                    {
                      key: 'csv',
                      allow: true,
                      /**
                       * Export csv function
                       * @returns
                       */
                      onClickFunction: () => exportDataPdfOrCSV('csv'),
                    },
                  ]}
                  disabled={payments.length === 0}
                  loader={exportLoader}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default PaymentManagement;
