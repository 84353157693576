import { ReactComponent as CheckBoxActiveIcon } from '../../assets/icons/check-box-active-icon.svg';
import { ReactComponent as CheckBoxIcon } from '../../assets/icons/check-box-icon.svg';

interface CustomInputProperties {
  inputLabel?: string;
  value: (string | number)[];
  name: string;
  list: { label: string; value: string | number}[];
  onChangeValue: (name: string, value: (string | number)[]) => void;
  valueRequired?: boolean;
  error?: boolean;
  errorMessage?: string;
}

/**
 * Check box buttons function component
 */
function CheckBoxButtons({
  inputLabel,
  name,
  value,
  list,
  onChangeValue,
  error,
  valueRequired,
  errorMessage,
}: CustomInputProperties) {
  /**
   * check box button handle function
   */
  const handleValueChange = (changeValue: string | number) => {
    const newValue = value.includes(changeValue)
      ? value.filter(v => v !== changeValue)
      : [...value, changeValue];
    onChangeValue(name, newValue);
  };

  return (
    <div className="check-box-button-wrap">
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className="custom-check-box">
        <div className="check-box-list">
          {
            list.map((checkbox) => (
              <div className="check-box-item" onClick={() => handleValueChange(checkbox.value)} role="presentation">
                <div className="check-box-icon">
                  {
                    value.includes(checkbox.value) ? <CheckBoxActiveIcon /> : <CheckBoxIcon />
                  }
                </div>
                <div className="check-box-label">
                  {checkbox.label}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      {error && <p className="text-danger">{errorMessage}</p>}
    </div>
  );
}

CheckBoxButtons.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  error: false,
  errorMessage: '',
};

export default CheckBoxButtons;
