import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Sidebar icons
import { ReactComponent as AccountActiveIcon } from '../../assets/icons/sidebar/sidebar-account-active.svg';
import { ReactComponent as AccountIcon } from '../../assets/icons/sidebar/sidebar-account.svg';
import { ReactComponent as ConsumerActiveIcon } from '../../assets/icons/sidebar/sidebar-end-consumer-active.svg';
import { ReactComponent as ConsumerIcon } from '../../assets/icons/sidebar/sidebar-end-consumer.svg';
import { ReactComponent as HomeActiveIcon } from '../../assets/icons/sidebar/sidebar-home-active.svg';
import { ReactComponent as HomeIcon } from '../../assets/icons/sidebar/sidebar-home.svg';
import { ReactComponent as PaymentActiveIcon } from '../../assets/icons/sidebar/sidebar-payment-active.svg';
import { ReactComponent as PaymentIcon } from '../../assets/icons/sidebar/sidebar-payment.svg';
import { ReactComponent as PrizingActiveIcon } from '../../assets/icons/sidebar/sidebar-prizing-active.svg';
import { ReactComponent as PrizingIcon } from '../../assets/icons/sidebar/sidebar-prizing.svg';
import { ReactComponent as ReportActiveIcon } from '../../assets/icons/sidebar/sidebar-report-active.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/sidebar/sidebar-report.svg';
import { ReactComponent as RevenueActiveIcon } from '../../assets/icons/sidebar/sidebar-revenue-active.svg';
import { ReactComponent as RevenueIcon } from '../../assets/icons/sidebar/sidebar-revenue.svg';
import { ReactComponent as StationActiveIcon } from '../../assets/icons/sidebar/sidebar-station-active.svg';
import { ReactComponent as StationIcon } from '../../assets/icons/sidebar/sidebar-station.svg';
import { ReactComponent as UserActiveIcon } from '../../assets/icons/sidebar/sidebar-user-active.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/sidebar/sidebar-user.svg';
import { ReactComponent as ToggleClose } from '../../assets/icons/toggle-close.svg';
import { ReactComponent as ToggleOpen } from '../../assets/icons/toggle-open.svg';

/**
 * Side bar function component
 */
function Sidebar() {
  const sidebarReference = useRef<HTMLDivElement>(null);
  const [expandSidebar, setExpandSidebar] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarMenu, setSideBarMenu] = useState([
    {
      id: 1,
      label: 'Dashboard',
      url: '/ev-admin/dashboard',
      svgIcon: <HomeIcon />,
      activeSvgIcon: <HomeActiveIcon />,
      allow: true,
    },
    {
      id: 2,
      label: 'Accounts Management',
      url: '/ev-admin/account',
      svgIcon: <AccountIcon />,
      activeSvgIcon: <AccountActiveIcon />,
      allow: true,
    },
    {
      id: 3,
      label: 'Charging Station Management',
      url: '/ev-admin/charging-station/approved',
      svgIcon: <StationIcon />,
      activeSvgIcon: <StationActiveIcon />,
      allow: true,
    },
    {
      id: 4,
      label: 'Reports',
      url: '/ev-admin/reports',
      svgIcon: <ReportIcon />,
      activeSvgIcon: <ReportActiveIcon />,
      allow: true,
    },
    {
      id: 5,
      label: 'User & Role Management',
      url: '/ev-admin/user-management/user',
      svgIcon: <UserIcon />,
      activeSvgIcon: <UserActiveIcon />,
      allow: true,
    },
    {
      id: 6,
      label: 'End Consumer ',
      url: '/ev-admin/end-consumer',
      svgIcon: <ConsumerIcon />,
      activeSvgIcon: <ConsumerActiveIcon />,
      allow: true,
    },
    {
      id: 7,
      label: 'Payments',
      url: '/ev-admin/payments',
      svgIcon: <PaymentIcon />,
      activeSvgIcon: <PaymentActiveIcon />,
      allow: true,
    },
    {
      id: 8,
      label: 'Revenue',
      url: '/ev-admin/revenue/sharing',
      svgIcon: <RevenueIcon />,
      activeSvgIcon: <RevenueActiveIcon />,
      allow: true,
    },
    {
      id: 9,
      label: 'Dynamic Pricing',
      url: '/ev-admin/dynamic-pricing/approved',
      svgIcon: <PrizingIcon />,
      activeSvgIcon: <PrizingActiveIcon />,
      allow: true,
    },
  ]);

  /**
   * Handle the click menu function
   * @param url
   */
  const handleMenuClick = (url: string) => {
    setSideBarMenu((previous) =>
      previous.map((menu) => ({
        ...menu,
        isActive: menu.url === url,
      })),
    );
    navigate(url);
  };

  useEffect(() => {
    /**
     * Close popup
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarReference.current && !sidebarReference.current.contains(event.target as Node)) {
        setExpandSidebar(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="sidebar-wrap">
      <div
        className={`sidebar-container ${expandSidebar ? 'expand' : 'close'}`}
        ref={sidebarReference}>
        <div
          className="toggle-button"
          role="presentation"
          onClick={() => setExpandSidebar(!expandSidebar)}>
          <span
            role="presentation"
            onClick={() => setExpandSidebar(true)}
            className={`toggle-icon ${expandSidebar ? 'disable' : 'active'}`}>
            <ToggleOpen />
          </span>
          <span
            role="presentation"
            onClick={() => setExpandSidebar(false)}
            className={`toggle-icon ${expandSidebar ? 'active' : 'disable'}`}>
            <ToggleClose />
          </span>
        </div>
        <div className="sidebar-menus">
          {sidebarMenu?.map(
            (menu) =>
              menu.allow && (
                <div
                  className={`sidebar-menu ${expandSidebar ? 'expand' : 'close'} ${location.pathname === menu.url ? 'active' : 'in-active'}`}
                  key={menu?.id}
                  role="presentation"
                  onClick={() => handleMenuClick(menu.url)}>
                  <div
                    className={`menu-wrap ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                    <div
                      className={`menu-icon-container ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                      <span>
                        {location.pathname === menu.url ? menu.activeSvgIcon : menu.svgIcon}
                      </span>
                    </div>
                    <span
                      className={`menu-label ${expandSidebar ? 'expand' : 'close'} ${location.pathname === menu.url ? 'active' : 'in-active'}`}>
                      {menu.label}
                    </span>
                  </div>
                </div>
              ),
          )}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
