import React, { useEffect, useState } from 'react';

import { ReactComponent as EyeIcon } from '../../../assets/icons/eye-2-icon.svg';
// import { ReactComponent as DotCircle } from '../../../assets/icons/dot-circle.svg';
import { ReactComponent as DocumentIcon } from '../../../assets/icons/tables/document-file.svg';
import { ReactComponent as Location } from '../../../assets/icons/tables/location.svg';
import { brandIcons } from '../../../config/branding';
import DefaultApiParameters from '../../../enum/DefaultApiParameters';
import {
  ChargingStationInterFace,
  FilterChargingStationInterFace,
  RequestChargingStationInterface,
} from '../../../interface/charging-station.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
  fetchAllStation,
  setApprovedStationFilterData,
} from '../../../redux/slices/ApprovedStationManagementSlice';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import ConvertUTCtoHHMM from '../../../shared-functions/ConvertUTCtoHHMM';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import FormatUtcString from '../../../shared-functions/FormatUtcString';
import chargingStationApi from '../../../utils/api/charging-station';

import FilterChargingStation from './filter-charging-station';

/**
 * Approved Station list function component
 */
function ApprovedList({
  viewFunction,
}: {
  viewFunction: (value: ChargingStationInterFace) => void;
}) {
  const dispatch = useAppDispatch();
  const { stations, limit, page, totalPages, totalResults, loading, filter } = useAppSelector(
    (state) => state.approvedStation,
  );
  const [modalView, setModalView] = useState({
    status: false,
    title: '',
    header: true,
    content: <div />,
    rightCorner: '',
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);
  const column = [
    {
      label: 'Station Name',
      accessorKey: 'name' as keyof ChargingStationInterFace,
      sortAllow: true,
    },
    {
      label: 'Land Document',
      accessorKey: 'landOwnerShipDocument' as keyof ChargingStationInterFace,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (station: ChargingStationInterFace) =>
        station?.landOwnerShipDocument?.length ? (
          <div className="document-in-station-table">
            <div className="document-container">
              <span>
                <DocumentIcon />
              </span>
              <p className="table-row-each-data">{station?.landOwnerShipDocument?.[0]?.fileName}</p>
            </div>
            {station?.landOwnerShipDocument?.length > 1 && (
              <div className="document-count-wrap">
                <p className="table-row-each-data">+{station.landOwnerShipDocument.length - 1}</p>
              </div>
            )}
          </div>
        ) : null,
    },
    {
      label: 'Eb Reports',
      accessorKey: 'ebReport' as keyof ChargingStationInterFace,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (station: ChargingStationInterFace) =>
        station?.ebReport?.length ? (
          <div className="document-in-station-table">
            <div className="document-container">
              <span>
                <DocumentIcon />
              </span>
              <p className="table-row-each-data">{station?.ebReport?.[0]?.fileName}</p>
            </div>
            {station?.ebReport?.length > 1 && (
              <div className="document-count-wrap">
                <p className="table-row-each-data">+{station.ebReport.length - 1}</p>
              </div>
            )}
          </div>
        ) : null,
    },
    {
      label: 'Electricity type',
      accessorKey: 'electricityContractType' as keyof ChargingStationInterFace,
      sortAllow: true,
    },
    {
      label: 'Operating Hrs',
      accessorKey: 'opensAt' as keyof ChargingStationInterFace,
      sortAllow: true,
      /**
       * Time view in table
       * @param station
       * @param index
       */
      customData: (station: ChargingStationInterFace) =>
        station.opensAt && station.closesAt ? (
          <div className="time-in-station-table">
            <p className="table-row-each-data">{`${station.opensAt} - ${station.closesAt}`}</p>
          </div>
        ) : null,
    },
    {
      label: 'Station Location',
      accessorKey: 'address' as keyof ChargingStationInterFace,
      sortAllow: true,
      /**
       * custom data function
       * @param currentRow
       */
      customData: (currentRow: ChargingStationInterFace) =>
        currentRow.address ? (
          <div className="tag-default-label">
            <Location /> <span>{currentRow.address}</span>
          </div>
        ) : (
          <div />
        ),
    },
    {
      label: 'Media',
      accessorKey: 'medias' as keyof ChargingStationInterFace,
      /**
       * Media view in table
       * @param station
       * @param index
       */
      customData: (station: ChargingStationInterFace) =>
        station.medias ? (
          <div className="medias-in-station-table">
            {(() => {
              let selectedFilesView;
              if (station.medias && station.medias?.length <= 4) {
                selectedFilesView = (
                  <div className="selected-files-view-wrap">
                    {station.medias?.map((media) => (
                      <div className="single-selected-wrap">
                        {(() => {
                          let fileTypeView;
                          if (media?.fileType?.includes('image')) {
                            fileTypeView = (
                              <img src={media.signedUrl} alt="" className="selected-image" />
                            );
                          } else if (media?.fileType?.includes('video')) {
                            fileTypeView = (
                              <video controls={false} className="selected-video" preload="none">
                                <source src={media.signedUrl} type="video/mp4" />
                                <track
                                  src="path/to/captions.vtt"
                                  kind="captions"
                                  label="English captions"
                                  default
                                />
                                Your browser does not support the video tag.
                              </video>
                            );
                          }
                          return fileTypeView;
                        })()}
                      </div>
                    ))}
                  </div>
                );
              } else if (station.medias && station.medias?.length > 4) {
                selectedFilesView = (
                  <div className="selected-files-view-wrap">
                    {station.medias.slice(0, 3).map((media) => (
                      <div className="single-selected-wrap">
                        {(() => {
                          let fileTypeView;
                          if (media?.fileType?.includes('image')) {
                            fileTypeView = (
                              <img src={media.signedUrl} alt="" className="selected-image" />
                            );
                          } else if (media?.fileType?.includes('video')) {
                            fileTypeView = (
                              <video controls={false} className="selected-video" preload="none">
                                <source src={media.signedUrl} type="video/mp4" />
                                <track
                                  src="path/to/captions.vtt"
                                  kind="captions"
                                  label="English captions"
                                  default
                                />
                                Your browser does not support the video tag.
                              </video>
                            );
                          }
                          return fileTypeView;
                        })()}
                      </div>
                    ))}
                    <div className="single-selected-wrap">
                      <p className="number-of-files">+{station.medias.length - 3}</p>
                    </div>
                  </div>
                );
              }
              return selectedFilesView;
            })()}
          </div>
        ) : null,
    },
  ];

  /**
   * Handle the fetch the data in api function
   * @param data
   */
  const fetchData = (data: RequestChargingStationInterface) => {
    if (!loading) {
      dispatch(fetchAllStation(data));
    }
  };

  /**
   * Close modal function
   */
  const closeModal = () => {
    setModalView({
      status: false,
      title: '',
      header: false,
      content: <div />,
      rightCorner: '',
    });
  };

  /**
   * View the charging station details
   */
  const viewChargingStation = (stationId: String) => {
    const stationDetails = stations.find(
      (value: ChargingStationInterFace) => value.id === stationId,
    );
    if (stationDetails && Object.keys(stationDetails).length > 0) {
      viewFunction(stationDetails);
    }
  };

  /**
   * Handle the filter submit function
   */
  const handleSubmitFilter = (filterData: FilterChargingStationInterFace) => {
    dispatch(setApprovedStationFilterData(filterData));
    fetchData({
      ...filterData,
      page: DefaultApiParameters.page,
      limit,
      submissionStatus: 'approved',
    });
    setModalView((previous) => ({
      ...previous,
      status: false,
    }));
  };

  /**
   * Handle the filter clear function
   */
  const handleClearFilter = () => {
    dispatch(setApprovedStationFilterData({}));
    fetchData({
      page: DefaultApiParameters.page,
      limit,
      submissionStatus: 'approved',
    });
  };

  /**
   * Open the filter modal function
   */
  const openFilter = () => {
    setModalView({
      status: true,
      title: '',
      header: false,
      content: (
        <FilterChargingStation
          closeModal={closeModal}
          handleSubmitFilter={handleSubmitFilter}
          oldValue={filter}
        />
      ),
      rightCorner: '',
    });
  };

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestChargingStationInterface) => {
    if (value.sortField) {
      dispatch(
        setApprovedStationFilterData({
          ...filter,
          sortField: value.sortField,
          sortBy: value.sortBy,
        }),
      );
    }
    fetchData({ ...filter, ...value, submissionStatus: 'approved' });
  };

  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    chargingStationApi
      .exportChargingStations({ ...filter, submissionStatus: 'approved' })
      .then((response) => {
        // Convert data to CSV format
        const headers = [
          'Station Name',
          'Land Document',
          'Eb Reports',
          'Electricity Type',
          'Opening Hrs',
          'Station Location',
        ];
        const rows = response.data.map((row: ChargingStationInterFace) => [
          row.name,
          row.electricityContractType,
          row.landOwnerShipDocument?.[0]?.fileName,
          row.ebReport?.[0]?.fileName,
          `${row?.opensAt && FormatUtcString(ConvertUTCtoHHMM(row?.opensAt))} - ${row?.closesAt && FormatUtcString(ConvertUTCtoHHMM(row?.closesAt))}`,
          `${row.address}`,
        ]);
        if (type === 'csv') {
          exportCSV(headers, rows, 'charging-station-approved.csv');
        } else {
          exportPDF(headers, rows, 'charging-station-approved.pdf');
        }
        setExportLoader(false);
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (stations.length === 0) {
      fetchData({
        ...filter,
        page,
        limit,
        submissionStatus: 'approved',
      });
    }
  }, []);

  return (
    <>
      <ModalBox
        open={modalView.status}
        title={modalView.title}
        content={modalView.content}
        closeModal={closeModal}
        header={modalView.header}
        rightCorner={modalView.rightCorner}
      />
      <CustomTable
        header={column}
        data={stations}
        serialNo
        filter={filter}
        filterClearFunction={handleClearFilter}
        filterFunction={openFilter}
        actionButton
        limit={limit}
        page={page}
        loading={loading}
        totalPages={totalPages}
        totalResults={totalResults}
        pageLimitChange={pageNateLimitChange}
        sizeChangeButtonRequired
        height={window.innerHeight >= 700 ? 390 : 375}
        actionButtonList={[
          {
            icon: <EyeIcon />,
            function: viewChargingStation,
            color: 'view-color',
            allow: true,
          },
        ]}
        footerLeft={
          <div className="table-bottom-button-list">
            <div className="table-button-item">
              <ExportButton
                label="Export Data"
                buttonList={[
                  {
                    key: 'pdf',
                    allow: true,
                    /**
                     * Export pdf function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('pdf'),
                  },
                  {
                    key: 'csv',
                    allow: true,
                    /**
                     * Export csv function
                     * @returns
                     */
                    onClickFunction: () => exportDataPdfOrCSV('csv'),
                  },
                ]}
                disabled={stations.length === 0}
                loader={exportLoader}
              />
            </div>
            <div className="table-button-item">
              <div className="user-count">
                <div className="icon">
                  <brandIcons.Facility />
                </div>
                <div className="value">{totalResults} Facilities</div>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}

export default ApprovedList;
