import getToken from '../../helpers';

/**
 * Get default headers details function
 * @returns
 */
const defaultHeaders = () => {
  const accessToken = getToken();
  if (accessToken) {
    return { Authorization: `Bearer ${accessToken}` };
  }
  return null;
};

export default defaultHeaders;
