import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import loginBanner from '../../assets/images/login/login-banner.png';
// import sample from '../../assets/videos/background.mp4';

import { ReactComponent as LoginBanner } from '../../../assets/icons/login-banner.svg';
import MyButton from '../../../shared-components/button/Button';
import CustomInput from '../../../shared-components/custom-input/CustomInput';
import CustomPhoneInput from '../../../shared-components/custom-input/CustomPhoneInput';
import RadioButton from '../../../shared-components/radio-button/RadioButton';
import { setLocalStorageItem } from '../../../shared-functions/LocalStorage';
import { emailValidation, phoneNumberValidation } from '../../../shared-functions/Validation';
import authApi from '../../../utils/api/auth';

/**
 * Login page function component
 */
function ForgetPassword() {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<{
    emailOrPhoneNumber: string;
    type: string | number;
    countryCode: string;
  }>({
    emailOrPhoneNumber: '',
    type: 'email',
    countryCode: '1',
  });
  const [disabled, setDisabled] = useState(false);
  const [submitOpen, setSubmitOpen] = useState(false);

  /**
   * Input field change function
   * @param name
   * @param changeValue
   */
  const handleCredentialChange = (name: string, changeValue: string | number) => {
    setCredentials({
      ...credentials,
      [name]: changeValue,
    });
  };

  /**
   * handle submit function
   */
  const submitFunction = () => {
    setSubmitOpen(true);
    if (
      credentials.countryCode !== '' &&
      (phoneNumberValidation(credentials.emailOrPhoneNumber) ||
        emailValidation(credentials.emailOrPhoneNumber))
    ) {
      setDisabled(true);
      const request = {
        emailOrPhoneNumber: credentials.emailOrPhoneNumber,
        countryCode: credentials.countryCode,
      }
      authApi
        .forgotPassword(request)
        .then(() => {
          setDisabled(false);
          setLocalStorageItem('passwordReset', credentials);
          navigate('../otp-verification');
        })
        .catch(() => {
          setDisabled(false);
          setLocalStorageItem('passwordReset', credentials);
          navigate('../otp-verification');
        });
    }
  };

  return (
    <div className="login-wrap">
      {/* <video className='videoTag' autoPlay loop muted>
        <source src={sample} type='video/mp4' />
      </video> */}
      <div className="login-banner-container">
        {/* <img src={loginBanner} alt="banner" className="login-banner" /> */}
        <LoginBanner />
      </div>
      <div className="login-form-container">
        <div className="login-form-with-border">
          <div className="forget-password-form">
            <h1>Forget Password </h1>
            <RadioButton
              name="type"
              value={credentials.type}
              list={[
                {
                  label: 'Email',
                  value: 'email',
                },
                {
                  label: 'Phone Number',
                  value: 'phone',
                },
              ]}
              onChangeValue={handleCredentialChange}
            />
            {credentials.type === 'email' ? (
              <CustomInput
                inputLabel="Email"
                type="email"
                placeholder="Enter the email"
                name="emailOrPhoneNumber"
                value={credentials.emailOrPhoneNumber}
                onChangeValue={handleCredentialChange}
                error={submitOpen && !emailValidation(credentials.emailOrPhoneNumber)}
                errorMessage="Email is Invalid"
              />
            ) : (
              <CustomPhoneInput
                inputLabel="Phone Number"
                type="number"
                placeholder="Enter the phone number"
                name="emailOrPhoneNumber"
                value={credentials.emailOrPhoneNumber}
                value1={credentials.countryCode}
                onChangeValue={handleCredentialChange}
                error={submitOpen && !phoneNumberValidation(credentials.emailOrPhoneNumber)}
                errorMessage="Phone number is Invalid"
              />
            )}
            <MyButton
              label="Get OTP"
              buttonType="submit"
              onClickFunc={submitFunction}
              disabled={disabled}
            />
          </div>
          {/* <div className="gradient-border">
            <div className="white-bg-color" />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
