import React, { useEffect, useRef, useState } from 'react';

import { ReactComponent as EyeIcon } from '../../assets/icons/eye-icon.svg';
import { ReactComponent as FileUploadIcon } from '../../assets/icons/file-input.svg';
import { MultiMediaData } from '../../interface/charging-station.interface';
import ModalBox from '../modal-box/ModalBox';

import GalleryView from './components/GalleryView';

interface GalleryViewFileProperties {
  url: string;
  signedUrl: string;
  fileName: string;
  fileType: string;
  originalIndex: number;
  type: 'newFile' | 'existingFile';
}

interface CustomMediaUploadProperties {
  inputLabel?: string;
  acceptedFileType: string;
  placeholder: string;
  selectedFiles: File[] | null;
  name: string;
  onChangeValue: (name: string, file: File[]) => void;
  valueRequired?: boolean;
  readOnly?: boolean;
  unselectHandler?: (
    name: string,
    originalIndex: number,
    url: string,
    type: 'newFile' | 'existingFile',
  ) => void;
  existingName?: string;
  existingFiles?: MultiMediaData[] | null;
  error?: boolean;
  errorMessage?: string;
}

/**
 * Custom media upload with View
 */
function CustomMediaUpload({
  inputLabel,
  acceptedFileType,
  name,
  placeholder,
  selectedFiles,
  onChangeValue,
  valueRequired,
  readOnly,
  unselectHandler,
  existingName,
  existingFiles,
  error,
  errorMessage,
}: CustomMediaUploadProperties) {
  const inputReference = useRef<HTMLInputElement | null>(null);
  const [galleryView, setGalleryView] = useState<'open' | 'close'>('close');
  const [galleryViewData, setGalleryViewData] = useState<GalleryViewFileProperties[]>([]);
  /**
   * handle add new file
   */
  const handleSelectFile = (selectedFileList: Array<File>) => {
    if (selectedFileList?.length) {
      onChangeValue(name, [...(selectedFiles || []), ...selectedFileList]);
    }
  };

  /**
   * Handle remove file
   * @param fileToRemove
   */
  const handleRemoveSelectedFile = (
    originalIndex: number,
    url: string,
    type: 'newFile' | 'existingFile',
  ) => {
    if (unselectHandler && type === 'newFile') {
      unselectHandler(name, originalIndex, url, type);
    } else if (unselectHandler && type === 'existingFile' && existingName) {
      unselectHandler(existingName, originalIndex, url, type);
    } else {
      const afterRemovedSelectedFile =
        selectedFiles?.filter((_, index) => index !== originalIndex) || [];
      onChangeValue(name, afterRemovedSelectedFile);
    }
  };

  useEffect(() => {
    let filesForGalleryView: GalleryViewFileProperties[] = [];
    if (selectedFiles) {
      const selectedFilesForGallery: GalleryViewFileProperties[] = selectedFiles?.map(
        (eachFile, index) => ({
          url: URL.createObjectURL(eachFile),
          signedUrl: URL.createObjectURL(eachFile),
          fileName: eachFile.name,
          fileType: eachFile.type,
          originalIndex: index,
          type: 'newFile',
        }),
      );
      filesForGalleryView = selectedFilesForGallery;
    }
    if (existingFiles) {
      const existingFilesForGallery: GalleryViewFileProperties[] = existingFiles?.map(
        (eachFile, index) => ({
          url: eachFile.url,
          signedUrl: eachFile.signedUrl,
          fileName: eachFile.fileName,
          fileType: eachFile.fileType,
          originalIndex: index,
          type: 'existingFile',
        }),
      );
      filesForGalleryView = [...filesForGalleryView, ...existingFilesForGallery];
    }

    setGalleryViewData(filesForGalleryView);
  }, [selectedFiles, existingFiles]);

  return (
    <div className="custom-file-input-wrap">
      <ModalBox
        open={galleryView === 'open'}
        title="Media"
        content={
          <GalleryView
            selectedFiles={galleryViewData}
            handleRemoveSelectedFile={handleRemoveSelectedFile}
            acceptedFileType={acceptedFileType}
            handleSelectFile={handleSelectFile}
            readOnly={readOnly}
          />
        }
        closeModal={() => setGalleryView('close')}
        header
        rightCorner=""
      />
      {inputLabel && (
        <p className="input-label">
          {inputLabel}
          {valueRequired && <span>*</span>}
        </p>
      )}
      <div className={`custom-file-input-container ${error && 'error'}`}>
        <div className="selected-file-wrap">
          {(() => {
            let selectedFilesView;
            if (galleryViewData?.length === 0) {
              selectedFilesView = <p className="placeholder">{placeholder}</p>;
            } else if (galleryViewData?.length <= 4) {
              selectedFilesView = (
                <div className="selected-files-view-wrap">
                  {galleryViewData?.map((selectFile) => (
                    <div className="single-selected-wrap">
                      {(() => {
                        let fileTypeView;
                        if (selectFile?.fileType?.includes('image')) {
                          fileTypeView = (
                            <img src={selectFile.signedUrl} alt="" className="selected-image" />
                          );
                        } else if (selectFile?.fileType?.includes('video')) {
                          fileTypeView = (
                            <video controls={false} className="selected-video">
                              <source src={selectFile.signedUrl} type="video/mp4" />
                              <track
                                src="path/to/captions.vtt"
                                kind="captions"
                                label="English captions"
                                default
                              />
                              Your browser does not support the video tag.
                            </video>
                          );
                        }
                        return fileTypeView;
                      })()}
                    </div>
                  ))}
                </div>
              );
            } else if (galleryViewData?.length > 4) {
              selectedFilesView = (
                <div className="selected-files-view-wrap">
                  {galleryViewData.slice(0, 3).map((selectFile) => (
                    <div className="single-selected-wrap">
                      {(() => {
                        let fileTypeView;
                        if (selectFile?.fileType?.includes('image')) {
                          fileTypeView = (
                            <img src={selectFile.signedUrl} alt="" className="selected-image" />
                          );
                        } else if (selectFile?.fileType?.includes('video')) {
                          fileTypeView = (
                            <video controls={false} className="selected-video">
                              <source src={selectFile.signedUrl} type="video/mp4" />
                              <track
                                src="path/to/captions.vtt"
                                kind="captions"
                                label="English captions"
                                default
                              />
                              Your browser does not support the video tag.
                            </video>
                          );
                        }
                        return fileTypeView;
                      })()}
                    </div>
                  ))}
                  <div className="single-selected-wrap">
                    <p
                      className="number-of-files"
                      role="presentation"
                      onClick={() => setGalleryView('open')}>
                      +{galleryViewData.length - 3}
                    </p>
                  </div>
                </div>
              );
            }
            return selectedFilesView;
          })()}
        </div>
        <input
          ref={inputReference}
          type="file"
          accept={acceptedFileType}
          multiple
          style={{ display: 'none' } as React.CSSProperties}
          onChange={(event) => {
            const files = event?.target?.files;
            if (files) {
              handleSelectFile(Array.from(files));
            }
          }}
        />
        <div className="file-input-icon-wrap">
          {galleryViewData?.length === 0 ? (
            <span
              className={`file-input-icon ${readOnly ? 'disabled' : ''} `}
              role="presentation"
              onClick={() => {
                if (!readOnly) {
                  inputReference.current?.click();
                }
              }}>
              <FileUploadIcon />
            </span>
          ) : (
            <span
              className="file-input-icon"
              role="presentation"
              onClick={() => setGalleryView('open')}>
              <EyeIcon />
            </span>
          )}
        </div>
      </div>
      {error && <p className="custom-input-error-message">{errorMessage}</p>}
    </div>
  );
}

CustomMediaUpload.defaultProps = {
  inputLabel: '',
  valueRequired: false,
  readOnly: false,
  unselectHandler: null,
  existingName: '',
  existingFiles: [],
  error: false,
  errorMessage: '',
};

export default CustomMediaUpload;
