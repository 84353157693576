import React, { useEffect, useState } from 'react';

import { ReactComponent as ApproveIcon } from '../../../assets/icons/action-approve.svg';
import { ReactComponent as RejectIcon } from '../../../assets/icons/action-reject.svg';
import { ReactComponent as CurrencyIcon } from '../../../assets/icons/currency-icon.svg';
import {
  DynamicPricingProperties,
  RequestPricingInterface,
} from '../../../interface/dynamic-pricing.interface';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNotificationMessage } from '../../../redux/slices/NotificationManagementSlice';
import { fetchPendingPricing } from '../../../redux/slices/PendingPricingSlice';
import CustomTable from '../../../shared-components/custom-table/CustomTable';
import ExportButton from '../../../shared-components/export-button/ExportButton';
import ModalBox from '../../../shared-components/modal-box/ModalBox';
import SuccessNotification from '../../../shared-components/success-notification/SuccessNotificaion';
import ConvertUTCtoDate from '../../../shared-functions/ConvertUTCtoDate';
import { exportCSV, exportPDF } from '../../../shared-functions/ExportFile';
import dynamicPricing from '../../../utils/api/dynamic-pricing';

import RejectReason from './RejectReason';

interface ModalInterface {
  title: string;
  header: boolean;
  content: JSX.Element;
  notification: boolean;
}

/**
 * Approved dynamic pricing table
 */
function PendingPricing() {
  const dispatch = useAppDispatch();
  const { pricing, loading, page, limit, totalPages, totalResults } = useAppSelector(
    (state) => state.pendingPricing,
  );

  const [modalStatus, setModalStatus] = useState<'open' | 'close'>('close');
  const [modalView, setModalView] = useState<ModalInterface>({
    title: '',
    header: false,
    content: <div />,
    notification: false,
  });
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  /**
   * Handle the fetch the data in api function
   * @param data
   */
  const fetchPricing = (data: RequestPricingInterface) => {
    if (!loading) {
      dispatch(fetchPendingPricing(data));
    }
  };

  /**
   * Function to update the status of pricing
   * @param id
   */
  const handleApprovePricing = (id: string) => {
    dynamicPricing
      .updateDynamicPricing(id, { status: 'approved' })
      .then(() => {
        setModalStatus('open');
        setModalView({
          title: '',
          header: false,
          content: (
            <SuccessNotification
              title="Dynamic pricing approved successfully"
              description=""
              notificationCloseTimer={3000}
              onNotificationClose={() => {
                fetchPricing({
                  limit,
                  page,
                });
                setModalStatus('open');
              }}
            />
          ),
          notification: true,
        });
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Reject pricing API function
   */
  const handleRejectPricingAPI = (id: string, reason: string | number) => {
    dynamicPricing
      .updateDynamicPricing(id, { status: 'rejected', reason })
      .then(() => {
        setModalStatus('open');
        setModalView({
          title: '',
          header: false,
          content: (
            <SuccessNotification
              title="Dynamic pricing rejected"
              description=""
              notificationCloseTimer={3000}
              onNotificationClose={() => {
                fetchPricing({
                  limit,
                  page,
                });
                setModalStatus('open');
              }}
            />
          ),
          notification: true,
        });
      })
      .catch((error) => {
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  /**
   * Function to update the status of pricing
   * @param id
   */
  const handleRejectPricing = (id: string) => {
    setModalStatus('open');
    setModalView({
      title: '',
      header: false,
      content: (
        <RejectReason
          stationId={id}
          onSubmit={handleRejectPricingAPI}
          onClose={() => setModalStatus('close')}
        />
      ),
      notification: false,
    });
  };

  /**
   * Renders the custom data for the actions column
   *
   * @param {DynamicPricingProperties} pricingData - The pricingData data
   * @returns {JSX.Element} The rendered actions cell
   */
  const renderActions = (pricingData: DynamicPricingProperties) => (
    <div className="action-button-group">
      <div
        className="action-button-item view-color"
        onClick={() => handleApprovePricing(pricingData._id)}
        role="presentation">
        <ApproveIcon />
      </div>
      <div
        className="action-button-item view-color"
        onClick={() => handleRejectPricing(pricingData._id)}
        role="presentation">
        <RejectIcon />
      </div>
    </div>
  );

  const column = [
    {
      label: 'Date',
      accessorKey: 'createdAt' as keyof DynamicPricingProperties,
      sortAllow: true,
    },
    {
      label: 'Station Name',
      accessorKey: 'name' as keyof DynamicPricingProperties,
      sortAllow: true,
    },
    {
      label: 'Electricity Price',
      accessorKey: 'electricityCostByUtilityProvider' as keyof DynamicPricingProperties,
      sortAllow: true,
    },
    {
      label: '',
      customLabel: (
        <div className="dynamic-pricing-custom-label">
          <span>
            <CurrencyIcon />
          </span>
          <span>Base Rate</span>
        </div>
      ),
      accessorKey: 'baseRate' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (rowData: DynamicPricingProperties) => <p>{rowData.base}/-</p>,
      sortAllow: true,
    },
    {
      label: '',
      customLabel: (
        <div className="dynamic-pricing-custom-label">
          <span>
            <CurrencyIcon />
          </span>
          <span>Peak Rate</span>
        </div>
      ),
      accessorKey: 'peakRate' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (rowData: DynamicPricingProperties) => <p>{rowData.peak}/-</p>,
      sortAllow: true,
    },
    {
      label: '',
      customLabel: (
        <div className="dynamic-pricing-custom-label">
          <span>
            <CurrencyIcon />
          </span>
          <span>Off Peak Rate</span>
        </div>
      ),
      accessorKey: 'offPeakRate' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: (rowData: DynamicPricingProperties) => <p>{rowData.offpeak}/-</p>,
      sortAllow: true,
    },
    {
      label: 'Action',
      accessorKey: '' as keyof DynamicPricingProperties,
      /**
       * Document view in table
       * @param station
       * @param index
       */
      customData: renderActions,
      sortAllow: true,
      columnWidth: 60,
    },
  ];

  /**
   * Table pagination function
   * @param value
   */
  const pageNateLimitChange = (value: RequestPricingInterface) => {
    fetchPricing({ ...value });
  };
  /**
   * Export data function
   */
  const exportDataPdfOrCSV = (type: string) => {
    setExportLoader(true);
    dynamicPricing
      .fetchDynamicPricingExport({ status: 'pending' })
      .then((response) => {
        // Convert data to CSV format
        const headers = [
          'Date',
          'Station Name',
          'Electricity Price',
          'Peak Rate',
          'Peak Rate',
          'Off Peak Rate',
        ];
        const rows = response.data.map((row: DynamicPricingProperties) => [
          ConvertUTCtoDate(row.createdAt),
          row.name,
          row.electricityCostByUtilityProvider,
          row.base,
          row.peak,
          row.offpeak,
        ]);
        setExportLoader(false);
        if (rows.length > 0) {
          if (type === 'csv') {
            exportCSV(headers, rows, 'pending-dynamic-pricing-list.csv');
          } else {
            exportPDF(headers, rows, 'pending-dynamic-pricing-list.pdf');
          }
        }
      })
      .catch((error) => {
        setExportLoader(false);
        dispatch(
          setNotificationMessage({
            message: error?.response?.data?.message,
            status: true,
            type: 'error',
            code: error?.response?.data?.code,
          }),
        );
      });
  };

  useEffect(() => {
    if (pricing.length === 0) {
      fetchPricing({ page, limit });
    }
  }, []);

  return (
    <>
      <ModalBox
        open={modalStatus === 'open'}
        title={modalView.title}
        content={modalView.content}
        closeModal={() => setModalStatus('close')}
        header={modalView.header}
        notification={modalView.notification}
        rightCorner=""
      />
      <div className="dynamic-pricing-table">
        <CustomTable
          header={column}
          data={pricing}
          serialNo
          filterAllow={false}
          filter={{}}
          filterClearFunction={() => {}}
          filterFunction={() => {}}
          limit={limit}
          page={page}
          loading={loading}
          totalPages={totalPages}
          totalResults={totalResults}
          pageLimitChange={pageNateLimitChange}
          sizeChangeButtonRequired
          height={window.innerHeight >= 700 ? 390 : 375}
          footerLeft={
            <div className="table-bottom-button-list">
              <div className="table-button-item">
                <ExportButton
                  label="Export Data"
                  buttonList={[
                    {
                      key: 'pdf',
                      allow: true,
                      /**
                       * Export pdf function
                       * @returns
                       */
                      onClickFunction: () => exportDataPdfOrCSV('pdf'),
                    },
                    {
                      key: 'csv',
                      allow: true,
                      /**
                       * Export csv function
                       * @returns
                       */
                      onClickFunction: () => exportDataPdfOrCSV('csv'),
                    },
                  ]}
                  disabled={pricing.length === 0}
                  loader={exportLoader}
                />
              </div>
            </div>
          }
        />
      </div>
    </>
  );
}

export default PendingPricing;
