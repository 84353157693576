import MyButton from '../../../shared-components/button/Button';

/**
 * Confirm Box function component
 */
function DeleteUser({
  title,
  subTitle,
  closeModal,
  conformSubmit = () => {},
  submitButton,
}: {
  title: String;
  subTitle: String;
  closeModal: () => void;
  conformSubmit?: () => void;
  submitButton?: JSX.Element;
}) {
  return (
    <div className="modal-box-body">
      <div className="conform-box-body">
        <div className="title">{title}</div>
        <div className="sub-title">{subTitle}</div>
      </div>
      <div className="form-field table-bottom-button-list form-bottom-button">
        <div className="table-button-item">
          <MyButton label="Cancel" buttonType="back" onClickFunc={closeModal} />
        </div>
        <div className="table-button-item">
          {submitButton || (
            <MyButton label="Ok" buttonType="submit" onClickFunc={() => conformSubmit()} />
          )}
        </div>
      </div>
    </div>
  );
}

DeleteUser.defaultProps = {
  submitButton: null,
  /**
   * Conform submit function
   */
  conformSubmit: () => {},
};

export default DeleteUser;
